import React, { useState, useCallback } from "react";
import { Modal, TextField, Stack } from "@shopify/polaris";
import { useHistory } from "react-router-dom";
import { useProfile } from "../utils/adminFrontend";
import { useXporterApi } from "../../ApiProvider";
import { useReportTemplate } from "../../TemplateContext";
import { generateObject } from "../TemplateSaveBar";

function DuplicateModal({ open, toggleOpen }) {
  const [profile] = useProfile();
  const [currentTemplate, templateDispatch] = useReportTemplate();
  const history = useHistory();
  const xporterAPI = useXporterApi();

  const [name, setName] = useState(`${currentTemplate.name} - Copy`);
  const [description, setDescription] = useState(currentTemplate.description);

  const [loading, setLoading] = useState(false);

  const duplicateTemplate = useCallback(() => {
    setLoading(!loading);
    const template = generateObject({ ...currentTemplate, name, description }, { profile });

    xporterAPI
      .saveTemplate(null, template)
      .then(({ id }) => {
        history.replace(`/templates/${id}`);
        templateDispatch({ type: "load", value: { template, id, profile } });
      })
      .catch((payload) => {
        templateDispatch({ type: "appendErrors", value: [payload.error] });
        console.error("Error handling Submitting request", payload);
      })
      .finally(() => {
        setLoading(loading);
        toggleOpen();
      });
  }, [loading, currentTemplate, name, description, xporterAPI, history, templateDispatch, profile, toggleOpen]);

  return (
    <Modal
      open={open}
      toggleOpen={toggleOpen}
      large
      title="Duplicate Template"
      primaryAction={{
        onAction: duplicateTemplate,
        loading,
        content: "Duplicate",
      }}
      onClose={toggleOpen}
      secondaryActions={[{ destructive: true, onAction: toggleOpen, content: "Cancel" }]}
    >
      <Modal.Section>
        <Stack vertical>
          <TextField
            id="duplicate-template-name"
            label="Template Name"
            value={name}
            onChange={setName}
            placeholder="Choose a descriptive name for your template"
          />
          <TextField
            id="duplicate-template-description"
            label="Description"
            value={description}
            placeholder="Optional"
            onChange={setDescription}
            multiline={6}
          />
        </Stack>
      </Modal.Section>
    </Modal>
  );
}

export default DuplicateModal;
