import React, { useState, useEffect, useMemo } from "react";
import { Card, ActionList, Stack, Button, Collapsible, Heading } from "@shopify/polaris";
import { useXporterApi } from "../ApiProvider";
import useRunTemplate from "./useRunTemplate";

function MiniReports({ head, starter, custom }) {
  const xporterApi = useXporterApi();
  const runTemplate = useRunTemplate();

  const [templates, setTemplates] = useState(null);
  const [activeGroup, setActiveGroup] = useState(null);

  useEffect(() => {
    if (starter) {
      xporterApi.getDefaultTemplates().then(setTemplates);
    } else if (custom) {
      xporterApi.getTemplates().then(setTemplates);
    }
  }, [xporterApi, starter, custom]);

  const templateGroups = useMemo(
    () =>
      custom || templates == null
        ? null
        : [...new Set(templates.map(({ NAME: name }) => (name.includes("/") ? name.split("/")[0] : null)))],
    [templates, custom]
  );

  const groupedTemplates = useMemo(
    () =>
      templateGroups === null
        ? [{ name: null, items: templates }]
        : templateGroups.map((groupName) => ({
            name: groupName,
            items: templates.filter(({ NAME: templateName }) =>
              groupName === null ? !templateName.includes("/") : templateName.startsWith(`${groupName}/`)
            ),
          })),
    [templates, templateGroups]
  );

  return (
    head &&
    templates != null && (
      <Card>
        {head && head({ count: templates?.length || 0 })}
        {templates?.length > 0 &&
          groupedTemplates.map(
            ({ name: groupName, items }) =>
              items && (
                <Card.Section key={groupName} flush>
                  <Stack vertical>
                    {groupName && (
                      <Card.Header
                        title={
                          <Button
                            disclosure={activeGroup !== groupName}
                            plain
                            monochrome
                            removeUnderline
                            onClick={() => setActiveGroup(groupName)}
                          >
                            <Heading>{groupName}</Heading>
                          </Button>
                        }
                      />
                    )}
                    <Collapsible open={activeGroup === groupName}>
                      <ActionList
                        items={items.map((template) => {
                          const displayName = groupName === null ? template.NAME : template.NAME.substr(groupName.length + 1);
                          return {
                            content: displayName,
                            onAction: () => runTemplate({ template }),
                          };
                        })}
                      />
                    </Collapsible>
                  </Stack>
                </Card.Section>
              )
          )}
      </Card>
    )
  );
}

export default MiniReports;
