/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useRef } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

function DraggableResourceList({ items, onReorder, onBeforeDrag }) {
  const unlockRef = useRef(null);

  // used to lock the DOM before a drag starts
  // should return a function to unlock everything once the drag completes
  const onBeforeCapture = useCallback(() => {
    if (onBeforeDrag) {
      unlockRef.current = onBeforeDrag();
    }
  }, [onBeforeDrag]);

  const onDragEnd = useCallback(
    (result) => {
      if (unlockRef.current) {
        unlockRef.current();
        unlockRef.current = null;
      }

      // dropped outside the list
      if (!result.destination) {
        return;
      }

      onReorder({ from: result.source.index, to: result.destination.index });
    },
    [onReorder]
  );

  return (
    <DragDropContext onDragEnd={onDragEnd} onBeforeCapture={onBeforeCapture}>
      <Droppable droppableId="list">
        {(droppableProvided) => (
          <div ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
            {items.map(({ id, item }, index) => (
              <Draggable draggableId={id} index={index} key={id}>
                {(draggableProvided) => (
                  <div
                    ref={draggableProvided.innerRef}
                    {...draggableProvided.draggableProps}
                    {...draggableProvided.dragHandleProps}
                  >
                    {item}
                  </div>
                )}
              </Draggable>
            ))}
            {droppableProvided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}

export default DraggableResourceList;
