import React, { useContext, createContext, useMemo, useCallback } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { useIsAuthenticated } from "./utils";

const RouteContext = createContext({ validRoutes: [], displayRoutes: [] });

function RouteManagerInternals({ routes, children }) {
  const isAuthenticated = useIsAuthenticated();

  const accessOk = useCallback(({ access }) => isAuthenticated || (!isAuthenticated && access === "anonymous") || access === "all", [isAuthenticated]);

  const validRoutes = useMemo(
    () =>
      routes.filter(accessOk).map(({ items = [], ...rest }) => ({
        ...rest,
        items: items.filter(accessOk),
      })),
    [accessOk, routes],
  );

  const displayRoutes = useMemo(
    () =>
      validRoutes
        .filter(({ path, name }) => !/:/.test(path) && name)
        .map(({ items = [], path, ...rest }) => ({
          ...rest,
          items: items.filter(({ path: subPath }) => !/:/.test(subPath)),
          path: path || items.find((item) => item.path)?.path,
        })),
    [validRoutes],
  );

  return <RouteContext.Provider value={{ validRoutes, displayRoutes }}>{children}</RouteContext.Provider>;
}

export function RouteManager({ routes, children }) {
  return (
    <BrowserRouter>
      <RouteManagerInternals routes={routes}>{children}</RouteManagerInternals>
    </BrowserRouter>
  );
}

export function useValidRoutes() {
  return useContext(RouteContext).validRoutes;
}

export function useDisplayRoutes() {
  return useContext(RouteContext).displayRoutes;
}

export function ContentForRoute() {
  const validRoutes = useValidRoutes();

  return (
    <Switch>
      {validRoutes.flatMap(({ exact = false, path, render, items = [] }) => [
        path && <Route exact={exact} path={path} render={render} key={path} />,
        ...items.filter(({ path: subPath }) => subPath != null).map(({ exact: subExact = false, path: subPath, render: subRender }) => <Route exact={subExact} path={subPath} render={subRender} key={subPath} />),
      ])}
    </Switch>
  );
}

export default RouteManager;
