import React from "react";
import { Card, Spinner, Stack, Page, EmptyState } from "@shopify/polaris";
import { useProfile } from "../utils";

function canRead(user, permission) {
  return user.permissions !== 0 && (user.permissions === null || user.permissions[permission]);
}

function NeedPermissionPage() {
  return (
    <Page>
      <Card sectioned>
        <EmptyState heading="You don't have permission to access this page.">
          <p>Please contact the Shopify account owner for more details.</p>
        </EmptyState>
      </Card>
    </Page>
  );
}

export function PermissionGuard({ permission, children, loading }) {
  const [profile] = useProfile();
  if (profile && !canRead(profile.user, permission)) return <NeedPermissionPage />;
  if (!profile || loading)
    return (
      <Page>
        <Stack alignment="center" distribution="center">
          <Spinner size="large" />
        </Stack>
      </Page>
    );
  if (typeof children === "function") return children();
  return children;
}

export default null;
