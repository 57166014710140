/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useCallback, useEffect, useMemo } from "react";
import { Card, TextStyle, ResourceList, ResourceItem, Stack, Collapsible, Heading, Icon, Button } from "@shopify/polaris";
import {
  Column1Major,
  CustomersMajor,
  OrdersMajor,
  ProductsMajor,
  InventoryMajor,
  BlogMajor,
  CheckoutMajor,
  CalendarMajor,
  AppsMajor,
  BalanceMajor,
} from "@shopify/polaris-icons";
import { useCreateModal, useRedirect } from "./utils/adminFrontend";
import { useXporterApi } from "../ApiProvider";
import useRunTemplate from "./useRunTemplate";

const DEFAULT_ICON = Column1Major;
const GROUP_NAME_ICONS_MAP = {
  Order: OrdersMajor,
  Product: ProductsMajor,
  Customer: CustomersMajor,
  Payout: BalanceMajor,
  Inventory: InventoryMajor,
  Blogs: BlogMajor,
  Checkout: CheckoutMajor,
  Events: CalendarMajor,
  Integrations: AppsMajor,
};

/*
const PopoverActionListButton = ({ actions, children, ...props }) => {
  const [active, setActive] = useState(false);
  const toggleActive = useCallback(() => setActive(!active), [active]);

  return (
    <Popover
      activator={
        <Button {...props} onClick={toggleActive}>
          {children}
        </Button>
      }
      active={active}
      onClose={toggleActive}
    >
      <ActionList items={actions} />
    </Popover>
  );
};
// TO USE THIS THING DO THIS STUFF
 <PopoverActionListButton
  icon={HorizontalDotsMinor}
  actions={[
    {
      content: "Delete",
      destructive: true,
      onAction: () => {
        createModal({
          content: "This will delete this report permanently. All files generated from this report will remain.",
          primaryAction: {
            destructive: true,
            content: "Delete Report",
            onAction: () =>
              xporterApi
                .deleteTemplate(template.ID)
                .catch((ex) => console.warn("xporterApi.deleteTemplate() rejected with", ex))
                .finally(refreshItems),
          },
        });
      },
    },
  ]}
/>;
*/

export function LargeReportList({
  groupName,
  icon,
  heading,
  custom,
  starter,
  items,
  refreshItems,
  defaultOpen,
  alwaysOpen,
  quick,
}) {
  const runTemplate = useRunTemplate();
  const createModal = useCreateModal();
  const xporterApi = useXporterApi();
  const redirect = useRedirect();

  const [active, setActive] = useState(defaultOpen || alwaysOpen); // FIXME: heading change
  const toggleActive = useCallback(() => setActive(!active), [active]);
  return (
    <Card>
      {!alwaysOpen && (
        <Card.Section flush>
          <ResourceList
            items={[{}]}
            renderItem={() => (
              <ResourceItem id={1} onClick={toggleActive}>
                <Stack wrap={false}>
                  <Stack.Item>
                    <Icon color="base" source={icon || DEFAULT_ICON} />
                  </Stack.Item>
                  <Stack.Item fill>
                    <Heading>{heading}</Heading>
                  </Stack.Item>
                </Stack>
              </ResourceItem>
            )}
          />
        </Card.Section>
      )}

      <Card.Section flush>
        <Collapsible open={active}>
          <ResourceList
            items={items.map((template) => {
              const displayName = groupName == null ? template.NAME : template.NAME.substr(groupName.length + 1);
              return {
                displayName,
                template,
              };
            })}
            renderItem={({ displayName, template }) => (
              <ResourceItem
                id={template.ID}
                onClick={
                  quick
                    ? () =>
                        runTemplate({
                          template,
                          onRun() {
                            redirect("/");
                          },
                        })
                    : null
                }
              >
                <Stack wrap={false}>
                  <Stack.Item fill>
                    <Stack vertical spacing="extraTight">
                      <h3>
                        <TextStyle variation="strong">{displayName}</TextStyle>
                      </h3>
                      <p>{template.DESCRIPTION}</p>
                    </Stack>
                  </Stack.Item>
                  {!quick && (
                    <Stack.Item>
                      <Stack wrap={false}>
                        <Button primary onClick={() => runTemplate({ template })}>
                          Run
                        </Button>
                        <Button url={starter ? `/templates/${template.ID}?starter=1` : `/templates/${template.ID}`}>
                          Customize
                        </Button>
                        {custom ? (
                          <Button
                            destructive
                            outline
                            onClick={() => {
                              createModal({
                                content:
                                  "This will delete this report permanently. All files generated from this report will remain.",
                                primaryAction: {
                                  destructive: true,
                                  content: "Delete Report",
                                  onAction: () =>
                                    xporterApi
                                      .deleteTemplate(template.ID)
                                      .catch((ex) => console.warn("xporterApi.deleteTemplate() rejected with", ex))
                                      .finally(refreshItems),
                                },
                              });
                            }}
                          >
                            Delete
                          </Button>
                        ) : null}
                      </Stack>
                    </Stack.Item>
                  )}
                </Stack>
              </ResourceItem>
            )}
          />
        </Collapsible>
      </Card.Section>
    </Card>
  );
}

export function LargeReportListGroups({ hasCustomTemplates, quick }) {
  const xporterApi = useXporterApi();

  const [templates, setTemplates] = useState([]);
  const [templateGroups, setTemplateGroups] = useState([]);

  useEffect(() => {
    xporterApi.getDefaultTemplates().then((newTemplates) => {
      setTemplates(newTemplates);
      const localTemplateGroups = [
        ...new Set(newTemplates.map(({ NAME: name }) => (name.includes("/") ? name.split("/")[0] : null))),
      ];
      setTemplateGroups(localTemplateGroups);
    });
  }, [xporterApi]);

  const groupedTemplates = useMemo(
    () =>
      templateGroups.map((groupName) => ({
        name: groupName,
        items: templates.filter(({ NAME: templateName }) =>
          groupName === null ? !templateName.includes("/") : templateName.startsWith(`${groupName}/`)
        ),
      })),
    [templates, templateGroups]
  );

  return (
    <Stack vertical>
      {groupedTemplates.map(({ name: groupName, items }, index) => (
        <LargeReportList
          key={groupName}
          icon={GROUP_NAME_ICONS_MAP[groupName]}
          heading={groupName}
          groupName={groupName}
          items={items}
          defaultOpen={index === 0 && !hasCustomTemplates}
          quick={quick}
          starter
        />
      ))}
    </Stack>
  );
}

export default null;
