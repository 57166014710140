import React from "react";
import { Page, Card, Heading, TextStyle, Stack, Badge } from "@shopify/polaris";

export default function FaqPage() {
  return (
    <Page fullWidth title="FAQ" breadcrumbs={[{ content: "Overview", url: "/" }]}>
      <Stack vertical>
        <Badge>
          <a href="#question-1">Q. My report is full of whitespace! How do I make everything appear on the same row?</a>
        </Badge>
        <Badge>
          <a href="#question-2">Q. My report just doesn&apos;t look the way I want it to. How can I get general help?</a>
        </Badge>
        <Badge>
          <a href="#question-3">Q. How many reports can I generate with my monthly plan?</a>
        </Badge>
        <Badge>
          <a href="#question-4">Q. My report should only be a couple lines, but Xporter is telling me it&apos;s very large? Why?</a>
        </Badge>
        <Badge>
          <a href="#question-5">
            Q. When you say &quot;Small Datasets&quot; or &quot;Large Datasets&quot;, or talk about the size of a dataset what
            exactly do you mean?
          </a>
        </Badge>
        <Badge>
          <a href="#question-6">Q. How long will reports be stored for?</a>
        </Badge>
        <Badge>
          <a href="#question-7">Q. I want to export a particular kind of data, but you don&apos;t have it as an option!</a>
        </Badge>
        <Badge>
          <a href="#question-8">Q. Why do some of my reports get put into a queue, whereas others generate immediately?</a>
        </Badge>
        <Badge>
          <a href="#question-9">Q. How/when will I be billed?</a>
        </Badge>
        <Badge>
          <a href="#question-10">
            Q. I&apos;m using Product Customizer, and I was pointed here from their documentation. How do I see my extra attributes
            in a report?
          </a>
        </Badge>
        <Badge>
          <a href="#question-11">
            Q. I want to do some complicated date math to filter out orders/products. Is this possible with Xporter?
          </a>
        </Badge>
        <Badge>
          <a href="#question-12">
            Q. I&apos;m exporting discount information for my line items and the numbers are slightly off. What gives?
          </a>
        </Badge>
        <Badge>
          <a href="#question-13">
            Q. I&apos;m using Excel, and some of values in my CSV should start with a 0, but they don&apos;t; they cut it off. How
            do I fix this?
          </a>
        </Badge>
        <Badge>
          <a href="#question-14">
            Q. I&apos;m using Excel, and when I open the CSV I downloaded, any non-english, or formatting characters, like bullet
            points, are all messed up! What&apos;s going on?
          </a>
        </Badge>
      </Stack>
      <br />
      <div id="question-1">
        <Card title="Q. My report is full of whitespace! How do I make everything appear on the same row?" sectioned>
          <p>A. Take a look into customizing your report using &quot;Main Item&quot; and &quot;Joins&quot;.</p>
        </Card>
      </div>
      <br />
      <div id="question-2">
        <Card title="Q. My report just doesn't look the way I want it to. How can I get general help?" sectioned>
          <p>
            A. Send us an email at <a href="mailto:exports@moddapps.com">exports@moddapps.com</a>! We&apos;d love to answer all your
            questions; we&apos;ll even set up your first report for you, if you like.
          </p>
        </Card>
      </div>
      <br />
      <div id="question-3">
        <Card title="Q. How many reports can I generate with my monthly plan?" sectioned>
          <p>
            A. Currently, there is no limit on the amount of reports you can generate, although you can only ever have at most four
            reports generating at the same time. The amount of templates you can generate reports from, however, does vary by plan;
            Basic has 1, Essentials has 5, and Professional has an unlimited number.
          </p>
        </Card>
      </div>
      <br />
      <div id="question-4">
        <Card title="Q. My report should only be a couple lines, but Xporter is telling me it's very large? Why?" sectioned>
          <p>
            A. Reports are analyzed based on the types of information they need, and the filters specified in the Report Filters
            card. Shopify will only allow us to filter by certain criteria on their end, so sometimes, in order to filter things
            properly, we need to get all of your data, not just the bit that you need.
          </p>
          <p>
            For example, if you specify that you&apos;d like to see all orders that contain the item &quot;Omnitool&quot; in the
            filtering section, you have 20,000 orders in your store, but you&apos;ve only sold 8 orders that fulfill this criteria,
            you&apos;d expect your report to be relatively quick to generate, given that we only have to generate a report
            containing those 8 orders. However, in order to properly process this request, because Shopify does not provide us with
            a way for us to communicate this criteria to them, we actually have to make requests for all 20,000 orders in your
            store. This is why certain reports are conterintuitively large. Look for the &quot;Recommended&quot; beside the various
            filtering criteria for conditions that we can pass to Shopify to significantly slim-down and speed up the generation of
            your report.
          </p>
        </Card>
      </div>
      <br />
      <div id="question-5">
        <Card
          title='Q. When you say "Small Datasets" or "Large Datasets", or talk about the size of a dataset what exactly do you mean?'
          sectioned
        >
          <Card.Section>
            <p>
              A. When we say dataset, we&apos;re talking about all the information we need to export your report in the format
              you&apos;ve specified. The word size, in this context, however, is hard to define. By clicking a single checkbox you
              can vary the size of your dataset hugely. Essentially it boils down to this. A small dataset is classified as anything
              under 10000 units. A large dataset is anything between 10000 and 100000 units. A unit is a measurement of work that
              the server has to do to fill in your report. Three separate elements of your report contribute to the final unit
              count.
            </p>
          </Card.Section>
          <Card.Section>
            <Heading element="h4">Main Item Count</Heading>
            <p>
              The first, is how many main items are you exporting? When you hit the export button, we do a quick count of all
              elements in this report&apos;s conditional range (So, if you&apos;re exporting open Orders between Septmeber 1st, and
              September 31st, we count all the open orders in Sepetember). This gives us an idea of how much data you&apos;re
              looking to export.
            </p>
          </Card.Section>

          <Card.Section>
            <Heading element="h4">Call Count</Heading>

            <p>
              Next, is your total call count. This represents how many times we have to talk to Shopify to get all the data for your
              report. In Shopify, we can usually grab 250 items every time we talk to Shopify. However, some items, such as
              metafields and transactions require us to talk to Shopify 2+ times per item. This is why adding in metafields, or any
              fields involving transactions can cause your report to baloon from a small dataset to a very large dataset.
            </p>
          </Card.Section>

          <Card.Section>
            <Heading element="h4">Waiting Periods</Heading>

            <p>
              Lastly, Shopify has a rule that we can only talk to them 500 times in 5 minutes. So if you need to make over 500
              calls, we calculate how many periods we&apos;re going to have to wait 5 minutes before the report is complete, and
              then add a score to your report based on that.
            </p>
            <br />
            <p>
              So, finally, our formula which gives us a rough approximation of how large your report is going to be is as follows;
              each section is weighted roughly by how much time it takes our server to process it.
            </p>
            <br />
            <TextStyle variation="code">TOTAL_ITEMS * 1 + TOTAL_CALLS * 100 + WAITING_PERIODS * 500 = TOTAL_COST.</TextStyle>
            <br />
            <br />
            <p>
              So, if you&apos;re a small shop, with a small amount of orders a month, and all you want is a simple export of your
              orders, and all line items in them, on a monthly basis, your report size will be small. So let&apos;s say, we have 30
              orders we want to export, with no metafields, or transaction information. This would result in a report size of 30 * 1
              + 2 * 100 = 230. This is well within the budget of our basic plan.
            </p>
            <br />
            <p>
              However, let&apos;s say you&apos;re a bustling small business, you want the transaction information on your orders,
              and you have 800 orders to export in a month. As you can see below, the calculation suddently becomes very big,
              because we have to do alot of extra work.
            </p>
            <br />
            <TextStyle variation="code">800 * 1 + 803 * 100 + 1 * 500 = 81600.</TextStyle>
            <br />
            <br />
            <p>
              This is what we&apos;d classify as a large dataset. This is out of the range of our basic plan, but doable under a
              essentials plan.
            </p>
            <br />
            <p>
              However, now let&apos;s say you&apos;re a larger enterprise and you&apos;d like a yearly report on all the 45000
              orders you&apos;ve done this year, with transaction information. The calculation would look something like.
            </p>
            <br />
            <TextStyle variation="code">45000 * 1 + 45001 * 100 + 90*500 = 4590100.</TextStyle>
            <br />
            <br />
            <p>
              This is a very large number, and will take our server quite some time to process, perhaps up to a full day, even if
              only because we need to spend time waiting for Shopify to give us the go ahead to get more data. This amount of
              processing is only available to our professional plan customers.
            </p>
          </Card.Section>
        </Card>
      </div>
      <br />
      <div id="question-6">
        <Card title="Q. How long will reports be stored for?" sectioned>
          <p>
            A. Reports will be stored for up to 90 days on our servers for your convenience. This will depend on your subscription
            plan.
          </p>
        </Card>
      </div>
      <br />
      <div id="question-7">
        <Card title="Q. I want to export a particular kind of data, but you don't have it as an option!" sectioned>
          <p>
            A. Please contact us at <a href="mailto:exports@moddapps.com">exports@moddapps.com</a>, and we&apos;d be happy to
            discuss including your export request in the next version of Xporter.
          </p>
        </Card>
      </div>
      <br />
      <div id="question-8">
        <Card title="Q. Why do some of my reports get put into a queue, whereas others generate immediately?" sectioned>
          <p>
            A. Some reports, depending on the fields present and the size of the dataset require more time to process. Those above a
            certain threshold, get placed into a queue, so that you don&apos;t timeout and hit a white screen when trying to make a
            report.
          </p>
        </Card>
      </div>
      <br />
      <div id="question-9">
        <Card title="Q. How/when will I be billed?" sectioned>
          <p>
            A. You&apos;ll be billed monthly as part of your regular Shopify invoice. The charge for Xporter will appear under the
            heading Xporter by Modd Apps, followed by the name of your plan that you&apos;ve chosen.
          </p>
        </Card>
      </div>
      <br />
      <div id="question-10">
        <Card
          title="Q. I'm using Product Customizer, and I was pointed here from their documentation. How do I see my extra attributes in a report?"
          sectioned
        >
          <p>
            A. Product customizer uses what&apos;s known as &quot;line item properties&quot; to encode extra information into
            people&apos;s orders. Xporter can access this data in a number of ways.
          </p>
          <br />
          <p>
            The first way is to simply check the &quot;Name&quot; and &quot;Value&quot; checkboxes under Properties &gt; Line Items
            &gt; Orders. This will put all line item properties associated with a report into your spreadsheet; one property per
            line, with name and value next to each other in terms of columns.
          </p>
          <br />
          <p>
            If you must have all properties on the same line as your line item, you have two options. The first option, requires our
            Essentials Plan, and this is to use &quot;Joins&quot;, to join each property together with a delimiter (so an order with
            three separate properties will end up in a single cell, as so: &quot;prop1,prop2,prop3&quot;).
          </p>
          <br />
          <p>
            The second option, if you&apos;d like to have each separate property in a particular column requires our Professional
            Plan, and the use of &quot;Liquid Fields&quot;. Simply add a &quot;Line Item Liquid Field&quot; to the line items
            section, and put:
          </p>
          <br />
          <TextStyle variation="code">
            {"{{"} line_item.properties | pluck: &apos;name&apos;, &apos;&lt;PROPRTY NAME&gt;&apos;, &apos;value&apos; {"}}"}
          </TextStyle>
          <br />
          <br />
          <p>Where &lt;PROPRTY NAME&gt; is the name of the property from Product Customizer.</p>
          <br />
          <p>
            If you are having any trouble getting your fields showing up in your report, please don&apos;t hesitate to drop us a
            line at <a href="mailto:exports@moddapps.com">exports@moddapps.com</a>, and we&apos;d be happy to help you set up your
            report.
          </p>
        </Card>
      </div>
      <br />
      <div id="question-11">
        <Card
          title="Q. I want to do some complicated date math to filter out orders/products. Is this possible with Xporter?"
          sectioned
        >
          <p>
            Yes, using Xporter&apos;s liquid processor and the date_math filter. The date_math filter can be used to add quantities
            of time to a date/time field.
          </p>

          <p>
            An example of this, let&apos;s say you wanted to filter out all line items that were purchased at least three months
            ago, at the time the report is generated. To do this, you must choose to filter orders by &quot;Liquid&quot;, and then
            enter the following:
          </p>

          <p>order.created_at &lt; now | date_math: -3, &apos;months&apos;</p>

          <p>This will filter out all orders created before 3 months ago.</p>
        </Card>
      </div>
      <br />
      <div id="question-12">
        <Card title="Q. I'm exporting refund information for my line items and the numbers are slightly off. What gives?" sectioned>
          <p>
            A. Unfortunately, at this time, Shopify simply doesn&apos;t provide apps with information about line item level refunds.
            So we guess. We take the total amount discounted, or the total amount refunded, and we amortize it over the line items
            and their actual amounts paid. So the amounts when added up over the line items of an order will work out to the total
            discount/refund, but the individual line item totals may or may not be accurate. We do tricks like back-calculating
            taxes to determine the actual price of an item, but there&apos;s only so much we can do when Shopify simply doesn&apos;t
            give us the information.
          </p>

          <p>
            We&apos;re hoping that Shopify will start to provide this information soon, but despite lobbying for over a year, it
            doesn&apos;t look like there&apos;s much movement on this front. The best thing you can do to help move this along is
            email apps@shopify.com, and mention this issue to them. The more public pressure, the higher the chance that this will
            get fixed!
          </p>
        </Card>
      </div>
      <br />
      <div id="question-13">
        <Card
          title="Q. I'm using Excel, and some of values in my CSV should start with a 0, but they don't; they cut it off. How do I fix this?"
          sectioned
        >
          <p>
            A. Excel will automatically cut off the beginning of cells that start with 0, unless you specify &quot;Excel
            Spreadsheet&quot;, as your format. Please see this answer about renaming the CSV to a TXT file:{" "}
            <a href="http://stackoverflow.com/questions/20908973/stop-Excel-from-converting-a-string-to-a-number">
              http://stackoverflow.com/questions/20908973/stop-Excel-from-converting-a-string-to-a-number
            </a>
          </p>
        </Card>
      </div>
      <br />
      <div id="question-14">
        <Card
          title="Q. I'm using Excel, and when I open the CSV I downloaded, any non-english, or formatting characters, like bullet points, are all messed up! What's going on?"
          sectioned
        >
          <p>
            A. Oddly enough, Excel doesn&apos;t read CSVs properly out of the box. You actually can&apos;t just open a CSV with
            Excel and expect it to work properly; you have to &quot;import&quot; it; though other office suites, such as LibreOffice
            will open it up just fine.
          </p>

          <p>
            In order to fix this, please see this link here:{" "}
            <a href="http://stackoverflow.com/questions/6002256/is-it-possible-to-force-excel-recognize-utf-8-csv-files-automatically">
              http://stackoverflow.com/questions/6002256/is-it-possible-to-force-Excel-recognize-utf-8-csv-files-automatically
            </a>
            . Basically, there are 6 steps you should follow to get the document into Excel properly:
          </p>

          <ol>
            <li>Open Excel</li>
            <li>Import the data using Data --&gt; Import External Data --&gt; Import Data</li>
            <li>Select the file type of &quot;csv&quot; and browse to your file</li>
            <li>
              In the import wizard change the File_Origin to &quot;65001 UTF&quot; (or choose correct language character identifier)
            </li>
            <li>Change the Delimiter to comma</li>
            <li>Select where to import to and Finish</li>
          </ol>

          <p>And that should do it.</p>
        </Card>
      </div>
    </Page>
  );
}
