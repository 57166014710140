import React, { useState, useEffect, useCallback, useRef, useMemo } from "react";
import { Stack } from "@shopify/polaris";
import { useProfile } from "./utils/adminFrontend";
import { today } from "../dateUtil";

function numberOrNull(str) {
  return str != null ? Number(str) : str;
}

function DateField({ value, onChange, label, disabled, error }) {
  const [profile] = useProfile();
  const defaultDate = useMemo(() => today(profile), [profile]);
  const valueOrDefault = value || defaultDate;

  const [date, setDate] = useState(value ? value.toFormat("yyyy-MM-dd") : "");
  const [time, setTime] = useState(value ? value.toFormat("HH:mm:ss") : "");
  const [dateTime, setDateTime] = useState(value ? value.toFormat("yyyy-MM-dd'T'HH:mm:ss") : "");
  const lastChange = useRef(value);

  const isInvalid = (value == null || error) && !disabled;

  const isDesktopSafari = useMemo(() => /^(?:(?!chrome|android|mobile).)*safari/i.test(navigator.userAgent) && (!navigator.maxTouchPoints || navigator.maxTouchPoints === 1), []);

  const localOnChange = useCallback(
    (newValue) => {
      lastChange.current = newValue;
      onChange(newValue);
    },
    [onChange]
  );

  useEffect(() => {
    if (lastChange.current !== value && !(lastChange.current && value && lastChange.current.equals(value))) {
      if (value) {
        setDate(value.toFormat("yyyy-MM-dd"));
        setTime(value.toFormat("HH:mm:ss"));
        setDateTime(value.toFormat("yyyy-MM-dd'T'HH:mm:ss"));
      } else {
        setDate("");
        setTime("");
        setDateTime("");
      }
    }
  }, [value]);

  const onDateChange = useCallback(
    (e) => {
      const str = e.target.value;
      setDate(str);

      const match = /(\d+)-(\d+)-(\d+)/.exec(str);
      if (match) {
        const [, yearString, monthString, dayString] = match;
        const year = numberOrNull(yearString);
        const month = numberOrNull(monthString);
        const day = numberOrNull(dayString);

        const newValue = valueOrDefault.set({ year, month, day });

        console.log("onDateChange", str, newValue.toISO());
        localOnChange(newValue);
      } else {
        console.log("onDateChange", str, null);
        localOnChange(null);
      }
    },
    [localOnChange, valueOrDefault]
  );

  const onTimeChange = useCallback(
    (e) => {
      const str = e.target.value;
      setTime(str);

      const match = /(\d+):(\d+)(?::(\d+))?/.exec(str);
      if (match) {
        const [, hourString, minuteString, secondString] = match;

        const hour = numberOrNull(hourString);
        const minute = numberOrNull(minuteString);
        const second = numberOrNull(secondString || "00");

        const newValue = valueOrDefault.set({ hour, minute, second });

        console.log("onTimeChange", str, newValue.toISO());
        localOnChange(newValue);
      } else {
        console.log("onTimeChange", str, null);
        localOnChange(null);
      }
    },
    [localOnChange, valueOrDefault]
  );

  const onDateTimeLocalChange = useCallback(
    (e) => {
      const str = e.target.value;
      setDateTime(str);

      const match = /(\d+)-(\d+)-(\d+)T(\d+):(\d+)(?::(\d+))?/.exec(str);
      if (match) {
        const [, yearString, monthString, dayString, hourString, minuteString, secondString] = match;
        const year = numberOrNull(yearString);
        const month = numberOrNull(monthString);
        const day = numberOrNull(dayString);
        const hour = numberOrNull(hourString);
        const minute = numberOrNull(minuteString);
        const second = numberOrNull(secondString || "00");

        const newValue = valueOrDefault.set({ year, month, day, hour, minute, second });

        console.log("onDateTimeChange", str, newValue.toISO());
        localOnChange(newValue);
      } else {
        console.log("onDateTimeChange", str, null);
        localOnChange(null);
      }
    },
    [localOnChange, valueOrDefault]
  );

  if (isDesktopSafari) {
    return (
      <Stack spacing="extraTight" wrap={false} alignment="trailing">
        <>
          {label ? (
            <div className="Polaris-Labelled__LabelWrapper">
              <div className="Polaris-Label">
                <div className="Polaris-Label__Text">{label}</div>
              </div>
            </div>
          ) : null}
          <div className="Polaris-Connected">
            <div className="Polaris-Connected__Item Polaris-Connected__Item--primary">
              <div className={`Polaris-TextField  ${isInvalid ? "Polaris-TextField--error" : ""} ${disabled ? "Polaris-TextField--disabled" : ""}`}>
                <input className="Polaris-TextField__Input" type="date" step={1} value={date} onChange={onDateChange} disabled={disabled} />
                <div className="Polaris-TextField__Backdrop" />
              </div>
            </div>
            <div className="Polaris-Connected__Item Polaris-Connected__Item--primary">
              <div className={`Polaris-TextField ${isInvalid ? "Polaris-TextField--error" : ""} ${disabled ? "Polaris-TextField--disabled" : ""}"`}>
                <input className="Polaris-TextField__Input" type="time" step={1} value={time} onChange={onTimeChange} disabled={disabled} />
                <div className="Polaris-TextField__Backdrop" />
              </div>
            </div>
          </div>
        </>
      </Stack>
    );
  }

  return (
    <Stack spacing="extraTight" wrap={false} alignment="trailing">
      <>
        {label ? (
          <div className="Polaris-Labelled__LabelWrapper">
            <div className="Polaris-Label">
              <div className="Polaris-Label__Text">{label}</div>
            </div>
          </div>
        ) : null}
        <div className="Polaris-Connected">
          <div className="Polaris-Connected__Item Polaris-Connected__Item--primary">
            <div className={`Polaris-TextField ${isInvalid ? "Polaris-TextField--error" : ""} ${disabled ? "Polaris-TextField--disabled" : ""}`}>
              <input className="Polaris-TextField__Input" type="datetime-local" step={1} value={dateTime} onChange={onDateTimeLocalChange} disabled={disabled} />
              <div className="Polaris-TextField__Backdrop" />
            </div>
          </div>
        </div>
      </>
    </Stack>
  );
}

export default DateField;
