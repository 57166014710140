/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useCallback, useContext } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Modal,
  Select,
  TextField,
  Stack,
  OptionList,
  Collapsible,
  Label,
  Caption,
  DisplayText,
  TextStyle,
} from "@shopify/polaris";
import { MobileBackArrowMajor } from "@shopify/polaris-icons";
import { SubscriptionContext, useProfile } from "../utils/adminFrontend";
import { FILE_FORMATS, PROMOTED_OBJECTS } from "./values";
import { useReportTemplate } from "../../TemplateContext";
import { allTemplatesSorter } from "../../templateReducer";
import { useXporterApi } from "../../ApiProvider";

function TemplateBaseDetailsModal({ open, toggleOpen }) {
  const [currentTemplate, dispatch, { id: templateId }] = useReportTemplate();
  const subscription = useContext(SubscriptionContext);
  const [profile] = useProfile();
  const history = useHistory();

  const [name, setName] = useState(currentTemplate.name);
  const [description, setDescription] = useState(currentTemplate.description);
  const [reportFormat, setReportFormat] = useState(currentTemplate.reportFormat);
  const [mainItem, setMainItem] = useState(currentTemplate.reportEval ? "custom" : currentTemplate.mainItem);
  const [confirmBlankOrExistingTemplate, setConfirmBlank] = useState(Boolean(templateId));
  const [templateIdBase, setTemplateIdBase] = useState(null); // used for grabing default template

  const [maxTemplates, setMaxTemplates] = useState(0);
  const [starterTemplates, setStarterTemplates] = useState({});
  const xporterAPI = useXporterApi();

  useEffect(() => {
    if (!open) {
      setName(currentTemplate.name);
      setDescription(currentTemplate.description);
      setReportFormat(currentTemplate.reportFormat);
      if (!currentTemplate.reportEval) {
        setMainItem(currentTemplate.mainItem);
      }
    }
  }, [currentTemplate, open]);

  useEffect(() => {
    xporterAPI
      .getAllTemplates()
      .then((response) => {
        const templates = allTemplatesSorter(response, "", "");
        const count = Object.entries(templates.shop).reduce((prev, [, array]) => prev + array.length, 0);
        setMaxTemplates(count);
        setStarterTemplates(templates?.default);
      })
      .catch((response) => {
        console.error(response);
      });
  }, [xporterAPI]);

  useEffect(() => {
    if (
      subscription?.activePlan &&
      typeof templateId !== "string" &&
      Object.prototype.hasOwnProperty.call(subscription?.activePlan, "features") &&
      maxTemplates + 1 > subscription.activePlan.features["max-templates"].value
    ) {
      history.push("/template_library");
    }
  }, [history, maxTemplates, subscription, templateId]);

  const closeCallback = useCallback(
    () =>
      currentTemplate.mainItem === "" && currentTemplate.name === ""
        ? toggleOpen({ redirect: true })
        : toggleOpen({ redirect: false }),
    [currentTemplate.mainItem, currentTemplate.name, toggleOpen]
  );

  const saveCallback = () => {
    if (templateIdBase) {
      xporterAPI
        .getTemplate(templateIdBase)
        .catch((response) => {
          console.error(response);
        })
        .then((response) => {
          dispatch({ type: "load", value: { template: response, profile } });
        })
        .finally(() => {
          setTemplateIdBase(null);
          if (name !== currentTemplate.name) dispatch({ type: "merge", value: { name } });
          if (description !== currentTemplate.description) dispatch({ type: "merge", value: { description } });
          if (open) toggleOpen({ redirect: false });
        });
    } else {
      if (!currentTemplate.reportEval) {
        dispatch({ type: "merge", value: { mainItem } });
      }
      dispatch({ type: "merge", value: { name, description, reportFormat } });
      if (open) toggleOpen({ redirect: false });
    }
  };

  const backButton = useCallback(() => {
    if (confirmBlankOrExistingTemplate) {
      setConfirmBlank(false);
      return;
    }
    if (mainItem) {
      setMainItem(null);
    }
  }, [mainItem, confirmBlankOrExistingTemplate]);

  const topLevelItemOptions = [
    ...PROMOTED_OBJECTS.map(({ id, name: promotedName, description: promotedDescription }) => ({
      value: id,
      id: `template-wizard-report-type-${id}`,
      label: (
        <Stack vertical spacing="tight">
          <Label>{promotedName}</Label>
          <Caption>{promotedDescription}</Caption>
        </Stack>
      ),
    })),
  ];

  if (profile.shop.shopify_plan.match("shopify_plus")) {
    topLevelItemOptions.push({
      id: `template-wizard-report-type-user`,
      value: "user",
      label: (
        <Stack vertical spacing="tight">
          <Label>User</Label>
        </Stack>
      ),
    });
  }

  return (
    <Modal
      open={open}
      onClose={closeCallback}
      title={
        <Stack alignment="center">
          {currentTemplate.mainItem === "" && (
            <Button onClick={backButton} icon={MobileBackArrowMajor} disabled={!mainItem && !name} />
          )}
          <DisplayText size="small">{mainItem ? "Report Template Details" : "New Template"}</DisplayText>
        </Stack>
      }
      large
      fullWidth
      primaryAction={
        mainItem && name
          ? {
              id: "template-wizard-name-details-save",
              content: "Save",
              onAction: saveCallback,
            }
          : null
      }
      secondaryActions={
        currentTemplate.mainItem === "" && currentTemplate.name === ""
          ? [
              {
                content: "Discard",
                onAction: closeCallback,
                destructive: true,
              },
            ]
          : [
              {
                content: "Cancel",
                onAction: closeCallback,
                destructive: true,
              },
            ]
      }
    >
      <Collapsible open={!mainItem} transition={{ duration: "500ms", timingFunction: "ease-in-out" }}>
        <Modal.Section>
          <TextStyle>
            Select the type of data to report, structured like the Shopify admin panel. Reach out to support if you&apos;re not
            sure!
          </TextStyle>
          <OptionList selected={[mainItem]} onChange={(data) => setMainItem(data[0])} options={topLevelItemOptions} />
        </Modal.Section>
      </Collapsible>

      <Collapsible
        open={mainItem && !confirmBlankOrExistingTemplate}
        transition={{ duration: "500ms", timingFunction: "ease-in-out" }}
      >
        <Modal.Section>
          <TextStyle>
            You can choose a starter template that are pre-configured with certain formats ready to be tweaked to your exact needs.
          </TextStyle>
          <Stack vertical>
            <OptionList
              selected={[mainItem]}
              onChange={([id]) => {
                setTemplateIdBase(id);
                setConfirmBlank(true);
                const template = Object.entries(starterTemplates)
                  .flatMap(([, templates]) => templates)
                  .find(({ ID: templateItemId }) => templateItemId === id);
                if (template && template.NAME) {
                  setName(template.NAME);
                } else {
                  setName(null);
                }
              }}
              options={[
                {
                  id: "template-wizard-blank-template",
                  value: null,
                  label: (
                    <Stack vertical spacing="tight">
                      <Label>
                        <b>Blank Template</b>
                      </Label>
                      <Caption>Make your own template from scratch.</Caption>
                    </Stack>
                  ),
                },
                ...Object.entries(starterTemplates)
                  .filter(([folder]) => folder.match(new RegExp(mainItem, "i")))
                  .flatMap(([, templates]) => templates)
                  .map(({ ID: templateItemId, NAME: templateName, DESCRIPTION: templateDescription }) => ({
                    value: templateItemId,
                    label: (
                      <Stack vertical spacing="tight">
                        <Label>{templateName}</Label>
                        <Caption>{templateDescription}</Caption>
                      </Stack>
                    ),
                  })),
              ]}
            />
          </Stack>
        </Modal.Section>
      </Collapsible>
      <Collapsible
        open={mainItem && confirmBlankOrExistingTemplate}
        transition={{ duration: "200ms", timingFunction: "ease-in-out" }}
      >
        <Modal.Section>
          <Stack>
            <Stack.Item fill>
              <TextField
                id="template-wizard-template-name"
                label="Template Name"
                value={name}
                onChange={setName}
                placeholder="Choose a descriptive name for your template"
              />
            </Stack.Item>
            {!templateIdBase && (
              <Stack.Item>
                <Select
                  label="File format"
                  options={FILE_FORMATS}
                  onChange={(data) => {
                    setReportFormat({ ...reportFormat, type: data });
                  }}
                  value={reportFormat.type}
                />
              </Stack.Item>
            )}
          </Stack>
          <TextField
            id="template-wizard-template-description"
            label="Description"
            value={description}
            placeholder="Optional"
            onChange={setDescription}
          />
        </Modal.Section>
      </Collapsible>
    </Modal>
  );
}

export default TemplateBaseDetailsModal;
