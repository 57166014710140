import React, { createContext, useContext, useReducer } from "react";

const LocalFieldListContext = createContext(null);

const INITIAL_STATE = { query: "" };

const fieldListReducer = (lastState, action) => ({ ...lastState, ...action });

function FieldListContext({ children }) {
  const [content, dispatch] = useReducer(fieldListReducer, INITIAL_STATE);
  return <LocalFieldListContext.Provider value={[content, dispatch]}>{children}</LocalFieldListContext.Provider>;
}

export default FieldListContext;

export const useFieldListContext = () => useContext(LocalFieldListContext);
