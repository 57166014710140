import React, { useEffect, useState, useCallback } from "react";

import { Card, DisplayText, Button, Stack, Select, TextField, Modal, Link, Toast } from "@shopify/polaris";
import { useProfile } from "./utils/adminFrontend";
import { useXporterApi } from "../ApiProvider";
import { today, yesterday } from "../dateUtil";
import DateField from "./DateField";

export default function TemplateList({ type, fileManager }) {
  const xporterApi = useXporterApi();
  const [profile] = useProfile();

  const [templates, setTemplates] = useState([]);
  const [loadingTemplates, setLoadingTemplates] = useState(true);

  const [selectedTemplate, setSelectedTemplate] = useState("-1");
  const [customFieldValue, setCustomFieldValue] = useState("");
  const [nameFieldValue, setNameFieldValue] = useState("");

  const [startDate, setStartDate] = useState(() => yesterday(profile));
  const [startDateError, setStartDateError] = useState(false);

  const [endDate, setEndDate] = useState(() => today(profile).minus({ second: 1 }));
  const [endDateError, setEndDateError] = useState(false);

  const [modalErrorText, setModalErrorText] = useState("");
  const [errorModalActive, setErrorModalActive] = useState(false);

  const toggleErrorModal = useCallback((message, state) => {
    setModalErrorText(message);
    setErrorModalActive(state);
  }, []);

  const [activeToast, setActiveToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [errorToast, setErrorToast] = useState(false);

  const toggleToast = useCallback(({ toggle, message, error = false }) => {
    setActiveToast(toggle);
    setErrorToast(error);
    if (message) setToastMessage(message);
  }, []);

  let showStart = false;
  let showEnd = false;

  useEffect(() => {
    let promise = null;
    if (type === "shop") promise = xporterApi.getTemplates();
    else if (type === "default") promise = xporterApi.getDefaultTemplates();
    else promise = xporterApi.getAllTemplates();

    if (loadingTemplates) {
      promise
        .then((response) => {
          if (Array.isArray(response)) setTemplates(response);
        })
        .finally(() => {
          setLoadingTemplates(false);
        });
    }
  }, [loadingTemplates, type, xporterApi]);

  const handleCustomFieldChange = useCallback((value) => setCustomFieldValue(value), []);

  useEffect(() => {
    if (!nameFieldValue && selectedTemplate !== "-1") {
      const template = templates.find((x) => x.ID.toString() === selectedTemplate);
      setNameFieldValue(`${template.NAME} - ${today(profile).toFormat("d_M_y")}`);
    }
  }, [selectedTemplate, nameFieldValue, templates, profile]);

  useEffect(() => {
    // if (moment(startDate).isBefore(moment(endDate))){
    //   setEndDateError('End date is before start date');
    // }
    setStartDateError(false);
    if (endDate < startDate) {
      setEndDateError("End date is before start date");
    } else {
      setEndDateError(false);
    }
  }, [startDate, endDate]);

  const tooManyQueueError = (object, objectCount, limit) => (
    <p>
      This report requires {objectCount} {object}s which is over the limit of {limit}. Please add recommended filters or reduce your
      date range. Feel free to{" "}
      <Link url="mailto: exports@moddapps.com" href="mailto: exports@moddapps.com" external>
        contact
      </Link>{" "}
      support if you have any questions.
    </p>
  );
  const noConnectionError = `We are unable to connect to the report server, please try again in 30 seconds.`;

  const whichError = (error) => {
    const maxCount = 12;
    if (error === "unable to connect to server") return noConnectionError;

    const digest = JSON.parse(error);
    const topLevelItemCount = digest[digest.main_item].count;
    if (topLevelItemCount > maxCount)
      return tooManyQueueError(digest.main_item.split("::").slice(-1)[0], topLevelItemCount, maxCount);

    return `Something went wrong.`;
  };

  const runReport = () => {
    const start = startDate;
    const end = endDate;
    let hasError = false;
    if (showStart && !start) {
      setStartDateError("Missing Start Date");
      hasError = true;
    }
    if (showEnd && !end) {
      setEndDateError("Missing End Date");
      hasError = true;
    }
    if (hasError) return null;
    console.log(`Submitting template to generate from ${start} to ${end}`);

    return xporterApi
      .generateReport(selectedTemplate, {
        name: nameFieldValue,
        start: start.toFormat("yyyy-MM-dd'T'HH:mm:ss"),
        end: end.toFormat("yyyy-MM-dd'T'HH:mm:ss"),
        custom: customFieldValue,
      })
      .then(
        () => {
          toggleToast({ toggle: true, message: "Queued to generate." });
          if (fileManager) fileManager.itemQueued();
        },
        (payload) => {
          if (payload.error) {
            console.error("Error handling Submitting request", payload);
            const message = whichError(payload.error);
            toggleErrorModal(message, true);
          }
        }
      )

      .finally(() => {
        // clear shit out
        setSelectedTemplate("-1");
        setCustomFieldValue("");
        setNameFieldValue("");

        // setStartDate(null);
        setStartDateError(false);

        // setEndDate(null);
        setEndDateError(false);
      });
  };

  const template = templates.find((x) => x.ID.toString() === selectedTemplate);
  if (template) {
    const temp = { ...template.CONDITIONS.computed_queries, ...template.CONDITIONS.queries };
    Object.values(temp).forEach((value) => {
      switch (value[0].operator) {
        case "Within Date Range":
          showStart = true;
          showEnd = true;
          break;
        case "Before Date":
          showEnd = true;
          break;
        case "After Date":
          showStart = true;
          break;
        default:
          break;
      }
    });
  }

  const options = [
    { label: "Select a Template", value: "-1" },
    ...templates.map((x) => ({ label: x.NAME, value: x.ID.toString() })),
  ];

  return (
    <Card title="Classic Report Generation">
      <Card.Section>
        <Stack>
          <DisplayText size="small">Generate a report named</DisplayText>
          <TextField value={nameFieldValue} onChange={setNameFieldValue} placeholder="Report Name" />
          <DisplayText size="small"> from template</DisplayText>
          <Select options={options} onChange={setSelectedTemplate} value={selectedTemplate} />

          {template && (
            <Stack>
              {template.CUSTOM && (
                <Stack>
                  <DisplayText size="small"> with value </DisplayText>
                  <TextField value={customFieldValue} onChange={handleCustomFieldChange} placeholder="Value" />
                </Stack>
              )}

              {showStart && (
                <Stack>
                  <DisplayText size="small">starting from</DisplayText>
                  <DateField value={startDate} onChange={setStartDate} error={startDateError} />
                </Stack>
              )}
              {showEnd && (
                <Stack>
                  <DisplayText size="small">until</DisplayText>
                  <DateField value={endDate} onChange={setEndDate} error={endDateError} />
                </Stack>
              )}
              {showStart && showEnd && <DisplayText size="small">inclusively.</DisplayText>}
              <Button
                primary
                onClick={runReport}
                size="slim"
                disabled={(showStart && startDate == null) || (showEnd && endDate == null)}
              >
                Run
              </Button>
            </Stack>
          )}
          <Modal
            open={errorModalActive}
            onClose={() => setErrorModalActive(false)}
            title="Something went wrong queuing your report"
            primaryAction={{
              content: "Close",
              onAction: () => setErrorModalActive(false),
            }}
          >
            <Modal.Section>
              <span>{modalErrorText}</span>
            </Modal.Section>
          </Modal>
          {activeToast && (
            <Toast
              content={toastMessage}
              duration={5000 + errorToast * 200000}
              error={errorToast}
              onDismiss={() => {
                toggleToast({ toggle: false });
              }}
            />
          )}
        </Stack>
      </Card.Section>
    </Card>
  );
}
