import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Button, Card, Heading, Icon, Link, Stack, TextField } from "@shopify/polaris";
import { AddMajor, FilterMajor, SearchMinor } from "@shopify/polaris-icons";
import { FieldListFull } from "./FieldList";
import FieldListContext, { useFieldListContext } from "./FieldListContext";
import { useReportTemplate } from "../../TemplateContext";
import DataBrowserModal, { useLoadDefaultSampleData } from "./DataBrowserModal";
import "./FieldList.scss";

const presentationName = (mainItem, shopifyData) => {
  if (!shopifyData) {
    return "nothing";
  }
  switch (mainItem) {
    case "order":
      return `order ${shopifyData.name}`;
    case "customer":
      return `customer ${shopifyData.first_name} ${shopifyData.last_name} <${shopifyData.email}>`;
    case "product":
      return `product ${shopifyData.title}`;
    default:
      return `${mainItem} ${shopifyData?.id}`;
  }
};

function FieldSeachField() {
  const [{ queryValue }, fieldListDispatch] = useFieldListContext();
  const setQueryValue = useCallback((value) => fieldListDispatch({ queryValue: value }), [fieldListDispatch]);
  const clearQueryValue = useCallback(() => setQueryValue(""), [setQueryValue]);

  return (
    <TextField
      placeholder="Search all fields"
      prefix={<Icon source={SearchMinor} color="base" />}
      value={queryValue}
      onChange={setQueryValue}
      clearButton
      onClearButtonClick={clearQueryValue}
    />
  );
}

export function AddFieldCard({ showSettingsModal }) {
  const [{ reportFields, reportFilters, mainItem }, dispatch, { queryFields, objectFields, shopifyData }] = useReportTemplate();

  const updateReportFields = useCallback((id, value) => dispatch({ type: "updateReportFields", value: { id, value } }), [dispatch]);
  const updateReportFilters = useCallback(
    (id, value) => dispatch({ type: "updateReportFilters", value: { id, value } }),
    [dispatch]
  );
  const reportFieldsIds = useMemo(() => reportFields.map((reportField) => reportField.id), [reportFields]);

  const includedTopLevelItems = useMemo(
    () =>
      [
        ...new Set(objectFields.filter((objectField) => reportFieldsIds.includes(objectField.id)).flatMap(({ cross }) => cross)),
      ].filter(Boolean),
    [objectFields, reportFieldsIds]
  );

  const [sampleDataPickerModalOpen, setSampleDataPickerModalOpen] = useState(false);
  const makeSampleDataPickerModalOpen = useCallback(() => setSampleDataPickerModalOpen(true), []);
  const clearSampleDataPickerModalOpen = useCallback(() => setSampleDataPickerModalOpen(false), []);

  // these two lines create a more stable value to memoize on for the line below
  const activeReportFieldIdString = useMemo(() => reportFields.map(({ id }) => id).join("\n"), [reportFields]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const activeReportFieldIds = useMemo(() => new Set(reportFields.map(({ id }) => id)), [activeReportFieldIdString]);

  // these two lines create a more stable value to memoize on for the line below
  const activeReportFilterFieldIdString = useMemo(() => reportFilters.map(({ id }) => id).join("\n"), [reportFilters]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const activeReportFilterFieldIds = useMemo(() => new Set(reportFilters.map(({ id }) => id)), [activeReportFilterFieldIdString]);

  const toggleActiveReportField = useCallback(
    (selection, state) => {
      if (state) {
        const objectField = objectFields.find(({ id }) => id === selection);
        updateReportFields(null, {
          id: selection,
          name: objectField ? [...objectField.displayPath, objectField.name].join("-") : selection,
        });
      } else {
        updateReportFields(selection, null);
      }
    },
    [objectFields, updateReportFields]
  );

  const toggleActiveFilterField = useCallback(
    (selection, state) => {
      if (state) {
        updateReportFilters(null, { id: selection, conditions: [{ id: +new Date() }] });
      } else {
        updateReportFilters(selection, null);
      }
    },
    [updateReportFilters]
  );

  const loadDefaultSampleData = useLoadDefaultSampleData();

  useEffect(() => {
    loadDefaultSampleData();
  }, [loadDefaultSampleData]);

  // filters out objects like transaction or risk that do not exist as mainObjects in the objectFields list
  // without filtering these out, you get broken object folders
  const filteredTopLevelItems = includedTopLevelItems
    .filter(
      (item) =>
        (objectFields.map((field)=>field.mainObject).includes?.(item))
    );
  // filter out metafield-value_type as it is depreciated
  const filteredObjectFields = objectFields.filter((item)=>(item.id.toLowerCase().indexOf("metafields-value_type") === -1));
  const filteredqueryFields = queryFields.filter((item)=>(item.id.toLowerCase().indexOf("metafields-value_type") === -1));

  return (
    <>
      <Card sectioned>
        <FieldListContext>
          <div id="add_fields" style={{ transform: "translateY(-10rem)" }} />
          <Stack vertical>
            <Heading>Shopify Field Browser</Heading>
            <Stack vertical spacing="tight">
              <Stack.Item>
                <Stack alignment="center" spacing="extraTight">
                  <Stack.Item>Select Shopify fields to add to your report as columns </Stack.Item>
                  <Stack.Item>
                    <Icon source={AddMajor} color="base" />
                  </Stack.Item>
                  <Stack.Item>or as filters</Stack.Item>
                  <Stack.Item>
                    <Icon source={FilterMajor} color="base" />
                  </Stack.Item>
                </Stack>
              </Stack.Item>
              {["order", "customer", "product"].includes(mainItem) ? (
                <Stack.Item>
                  Showing sample data from {presentationName(mainItem, shopifyData)}.{" "}
                  <Button plain onClick={makeSampleDataPickerModalOpen}>
                    Change Sample {mainItem.replace(/^./, (ch) => ch.toUpperCase())}
                  </Button>
                </Stack.Item>
              ) : null}
            </Stack>
            <FieldSeachField />
            <FieldListFull
              title={
                <>
                  <span style={{ display: "inline-flex" }}>Shopify Field Browser</span>
                  <span style={{ fontSize: "0.5em", float: "right" }}>
                    <Link
                      external
                      href="https://support.moddapps.com/hc/en-us/articles/4414574183827"
                      url="https://support.moddapps.com/hc/en-us/articles/4414574183827"
                    >
                      Learn More
                    </Link>
                  </span>
                </>
              }
              topLevelItem={mainItem}
              additionalRelationItems={filteredTopLevelItems}
              objectFields={filteredObjectFields}
              queryFields={filteredqueryFields}
              activeFields={activeReportFieldIds}
              activeFilterFields={activeReportFilterFieldIds}
              toggleActiveField={toggleActiveReportField}
              toggleActiveFilterField={toggleActiveFilterField}
              showSettingsModal={showSettingsModal}
            />
          </Stack>
        </FieldListContext>
      </Card>
      <DataBrowserModal open={sampleDataPickerModalOpen} clearOpen={clearSampleDataPickerModalOpen} />
    </>
  );
}

export default AddFieldCard;
