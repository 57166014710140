import React, { useCallback, useRef } from "react";
import { Card } from "@shopify/polaris";

import "./StickyCard.scss";

function StickCard({ render, header }) {
  const scrollContainerRef = useRef(null);

  const scrollToBottom = useCallback(() => {
    const el = scrollContainerRef.current;
    if (el) {
      el.scrollTo(el.scrollLeft, el.scrollHeight);
    }
  }, []);

  const fixedDivRef = useRef(null);
  const lockPosition = useCallback(() => {
    if (fixedDivRef.current) {
      const rect = fixedDivRef.current.getBoundingClientRect();
      fixedDivRef.current.style.position = "fixed";
      fixedDivRef.current.style.left = `${rect.left}px`;
      fixedDivRef.current.style.top = `${rect.top}px`;
      fixedDivRef.current.style.width = `${rect.width}px`;
      fixedDivRef.current.style.height = `${rect.height}px`;

      return () => {
        fixedDivRef.current.style.removeProperty("position");
        fixedDivRef.current.style.removeProperty("left");
        fixedDivRef.current.style.removeProperty("top");
        fixedDivRef.current.style.removeProperty("width");
        fixedDivRef.current.style.removeProperty("height");
      };
    }
    return null;
  }, []);

  return (
    <div ref={fixedDivRef}>
      <Card>
        <div className="sticky-card__height-container">
          {header ? <div className="sticky-card__header-container">{header}</div> : null}
          <div ref={scrollContainerRef} className="sticky-card__scroll-container">
            {render({ lockPosition, scrollToBottom })}
          </div>
        </div>
      </Card>
    </div>
  );
}

export default StickCard;
