import { useCallback } from "react";
import { useFetch } from "./utils";

const updateUrlParams = (url = "", params = {}) => {
  const urlObject = new URL(url, window.location);
  Object.entries(params).forEach(([key, value]) => {
    if (value instanceof Array) {
      value.forEach((element) => urlObject.searchParams.append(`${key}[]`, element));
    } else {
      urlObject.searchParams.append(key, value);
    }
  });
  return urlObject.toString();
};

export default () => {
  const authFetch = useFetch();
  return useCallback(
    (url, options = {}, ...rest) => {
      const urlOverride = typeof options.query === "object" ? updateUrlParams(url, options.query) : url;
      const contentTypeOverrides = options.json ? { "Content-Type": "application/json" } : {};
      const optionsOverrides = options.json ? { body: JSON.stringify(options.json), method: "POST" } : {};

      return authFetch(
        urlOverride,
        {
          ...optionsOverrides,
          ...options,
          headers: {
            ...contentTypeOverrides,
            ...options.headers,
          },
        },
        ...rest,
      );
    },
    [authFetch],
  );
};
