import React, { useCallback, useState, useEffect } from "react";
import { AppProvider as PolarisAppProvider, Frame, Navigation, TopBar } from "@shopify/polaris";
import enTranslations from "@shopify/polaris/locales/en.json";
import { Column1Major } from "@shopify/polaris-icons";
import { useLocation } from "react-router-dom";
import { useFetch, useProfile, useIsAuthenticated, useRedirect, Link } from "./utils";
import OverlayManager from "./contexts/OverlayManager";
import TrialBanner from "./components/TrialBanner";
import { SubscriptionProvider } from "./contexts/SubscriptionContext";
import zendesk from "./zendesk";
import { OpenReplayContextProvider } from "./contexts/OpenReplayContext";

import logo from "./logo.png";
import { useDisplayRoutes } from "./RouteManager";

const THEME = {
  colors: {
    topBar: {
      background: "#225062",
    },
  },
  logo: {
    width: 50,
    topBarSource: logo,
    url: "/",
    accessibilityLabel: "App Name Here", // window.app_data.app_name
  },
};

function ZendeskProvider() {
  const [profile, profileError] = useProfile();
  const authFetch = useFetch();
  const redirect = useRedirect();

  useEffect(() => {
    if (profile) {
      if (process.env.REACT_APP_ZENAME) {
        if (profile && !profile.user.watchtower) {
          zendesk({ appNameLong: process.env.REACT_APP_ZENAME, userProfile: profile, authFetch });
        }
      }
    }
  }, [authFetch, profile, profileError, redirect]);

  return null;
}

export default ({ embedded, shopOrigin, children }) => {
  const location = useLocation();
  const [profile] = useProfile();
  const isAuthenticated = useIsAuthenticated();
  const routes = useDisplayRoutes();

  const [userMenuActive, setUserMenuActive] = useState(true);
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
  const dismissMobileNavigation = useCallback(() => setMobileNavigationActive(false), []);

  const toggleUserMenuActive = useCallback(() => setUserMenuActive((active) => !active), []);

  const toggleMobileNavigationActive = useCallback(() => setMobileNavigationActive((active) => !active), []);

  if (!profile && isAuthenticated) {
    return null;
  }

  const fullName = profile && profile.user && typeof profile.user === "object" ? `${profile.user.first_name} ${profile.user.last_name}` : "Anonymous User";
  const initials = profile && profile.user && typeof profile.user === "object" ? `${String(profile.user.first_name)[0]}${String(profile.user.last_name)[0]}` : "A";

  return (
    <PolarisAppProvider i18n={enTranslations} theme={THEME} linkComponent={Link}>
      <OpenReplayContextProvider>
        <Frame
          topBar={
            embedded ? null : (
              <TopBar
                showNavigationToggle
                userMenu={isAuthenticated && <TopBar.UserMenu actions={[]} name={fullName} detail={shopOrigin} initials={initials} open={userMenuActive} onToggle={toggleUserMenuActive} />}
                /* searchResultsVisible={searchActive} */
                /* searchField={<TopBar.SearchField onChange={handleSearchFieldChange} value={searchValue} placeholder="Search" />} */
                /* searchResults={<ActionList items={[{ content: "Shopify help center" }, { content: "Community forums" }]} />} */
                /* onSearchResultsDismiss={handleSearchResultsDismiss} */
                onNavigationToggle={toggleMobileNavigationActive}
              />
            )
          }
          // This is for NON embedded views.
          navigation={
            embedded ? null : (
              <Navigation location={location.pathname}>
                <Navigation.Section
                  items={routes.map(({ name, exact, path, icon = Column1Major, items = [] }) => ({
                    label: name,
                    exactMatch: exact,
                    url: path || items?.find((i) => i.path)?.path || null,
                    icon,
                    subNavigationItems: items.map(({ name: subName, exact: subExact = false, path: subPath, url: subUrl, icon: subIcon = Column1Major }) => ({
                      label: subName,
                      exactMatch: subExact,
                      url: subUrl || subPath,
                      icon: subIcon,
                    })),
                  }))}
                />
              </Navigation>
            )
          }
          showMobileNavigation={mobileNavigationActive}
          onNavigationDismiss={dismissMobileNavigation}
        >
          <OverlayManager>
            <SubscriptionProvider>
              <div style={{ overflow: "hidden", maxWidth: "calc(100% - 0.2rem)" }}>
                <TrialBanner />
              </div>
              {children}
            </SubscriptionProvider>
          </OverlayManager>
        </Frame>
        <ZendeskProvider />
      </OpenReplayContextProvider>
    </PolarisAppProvider>
  );
};
