import "./TemplateLayoutGrid.scss";

import React, { useState, useCallback, useMemo } from "react";
import { Button, Stack, TextField, Popover, ChoiceList, Tooltip, ActionList, Icon } from "@shopify/polaris";
import { QuestionMarkMinor } from "@shopify/polaris-icons";
import { FeatureGate } from "../utils/adminFrontend";
import { AGGREGATE_VALUES } from "./values";
import ColumnModal from "./ColumnModal";
import LiquidField from "../LiquidField";
import { useReportTemplate } from "../../TemplateContext";
import LiquidRowOptions from "./LiquidRowOptions";
import ReportLayoutTable from "./ReportLayoutTable";

const arraySlice = (list, index, replacement = null) => {
  const newList = list.slice();
  if (replacement) {
    newList.splice(index, 1, replacement);
  } else {
    newList.splice(index, 1);
  }
  return newList;
};

function columnIndexToExcel(index) {
  return (index < 26 ? "" : columnIndexToExcel(Math.floor(index / 26 - 1))) + String.fromCharCode(65 + (index % 26));
}

function excelToColumnIndexRecurse(str) {
  const left = str.slice(0, -1);
  const right = str.slice(-1);
  return (left ? excelToColumnIndexRecurse(left) * 26 : 0) + right.toUpperCase().charCodeAt(0) - 64;
}

function excelToColumnIndex(str) {
  const stripped = String(str).trim().toUpperCase();
  return /^[a-z]+$/i.test(str) ? excelToColumnIndexRecurse(stripped) - 1 : null;
}

const genericRow = ({
  rowTitle,
  rowContextPath,
  reportFields,
  row,
  onChange,
  isLiquidRow = false,
  isLast = false,
  objectFields = [],
  indexInGroup,
  rowGroup,
  setRowGroup,
}) => ({
  id: row.id,
  title: rowTitle,
  contextPath: rowContextPath,
  items: reportFields.map((reportField) => {
    const content = row.contents.find((entry) => entry.id === reportField.id);
    const onCellChange = (newValue) => {
      const newRowContents = row.contents.filter((value) => value.id !== reportField.id);
      onChange({
        ...row,
        contents: newValue ? [...newRowContents, { ...content, id: reportField.id, value: newValue }] : newRowContents,
      });
    };

    const onFocus = (data) => {
      if (data === undefined) return;
      const { target } = data;
      const { rowIndex } = target.closest("tr");
      const { cellIndex } = target.closest("td");
      document.querySelectorAll(".selected-header").forEach((element) => element.classList.remove("selected-header"));

      document
        .querySelector("thead tr.report-layout-table__row")
        .children[cellIndex].querySelector(".report-layout-table__spreadsheet-heading")
        .classList.add("selected-header");
      document
        .querySelector(".report-layout-table__heading-area")
        .children[rowIndex].querySelector(".report-layout-table__spreadsheet-heading")
        .classList.add("selected-header");
    };

    const onBlur = () => {
      document.querySelectorAll(".selected-header").forEach((element) => element.classList.remove("selected-header"));
    };

    return {
      content: isLiquidRow ? (
        <LiquidField value={content?.value || ""} onChange={onCellChange} onFocus={onFocus} onBlur={onBlur} />
      ) : (
        <TextField value={content?.value || ""} onChange={onCellChange} onFocus={onFocus} onBlur={onBlur} />
      ),
    };
  }),
  contextMenu: ({ dismiss }) => (
    <Popover.Section>
      <Stack vertical>
        {isLiquidRow && (
          <LiquidRowOptions liquidRow={row} setLiquidRow={onChange} objectFields={objectFields} liquidRowContext={rowContextPath} />
        )}
        <div style={{ margin: "-1.6rem" }}>
          <ActionList
            items={[
              {
                content: "Remove",
                destructive: true,
                onAction: () => {
                  dismiss();
                  onChange(null);
                },
              },
            ]}
          />
        </div>
      </Stack>
    </Popover.Section>
  ),
  subObject: isLiquidRow,
  lastSubObject: isLast,
  indexInGroup,
  rowGroup,
  setRowGroup,
});

function AggregateSettingsButton({ field, index, list, setField, setGrouping }) {
  const aggregationIsActive = useMemo(
    () => list.reduce((last, f, i) => last || (i !== index && f.groupingType && f.groupingType !== "anchor"), false),
    [index, list]
  );

  const aggregateValuesWithRender = useMemo(
    () =>
      AGGREGATE_VALUES.map((row) => {
        const { value, hasOperand = false } = row;

        if (aggregationIsActive) {
          if (value === "") {
            return null;
          }
        } else if (value === "anchor") {
          return null;
        }

        if (hasOperand) {
          return {
            ...row,
            renderChildren: () => (
              <TextField
                label="Operand"
                labelHidden
                value={field.groupingOperand || ""}
                onChange={(newValue) => setField({ ...field, groupingOperand: newValue })}
                disabled={field.groupingType !== value}
              />
            ),
          };
        }

        return row;
      }).filter(Boolean),
    [aggregationIsActive, field, setField]
  );

  const localSetGrouping = useCallback(([value]) => setGrouping(field.id, value), [field.id, setGrouping]);

  return (
    <div className="template-layout-grid__aggregation">
      <ChoiceList
        title="Grouping"
        choices={aggregateValuesWithRender}
        selected={[field.groupingType || ""]}
        onChange={localSetGrouping}
      />
    </div>
  );
}

function FieldPath({ id, objectFields }) {
  const fieldRow = objectFields.find(({ id: innerId }) => innerId === id);
  const longPath = fieldRow ? [...fieldRow.displayPath, fieldRow.name].join(" > ") : id;

  return (
    <Tooltip
      content={
        <>
          Populated from <br />
          {longPath}
        </>
      }
    >
      <div className="template-layout-grid__cell-contents-area">
        <span className="template-layout-grid__cell-contents-text">{fieldRow?.name || "???"}</span>
        <Icon source={QuestionMarkMinor} color="subdued" />
      </div>
    </Tooltip>
  );
}

function ComputedField({ field, onChange, onFocus, onBlur }) {
  const key = field.liquid ? "liquid" : "text";

  const [isOpen, setIsOpen] = useState(false);
  const setOpen = useCallback(() => setIsOpen(true), []);
  const clearOpen = useCallback(() => setIsOpen(false), []);

  return (
    <>
      <ColumnModal reportField={field} open={isOpen} onClose={clearOpen} />
      <TextField
        value={field[key]}
        onChange={(newValue) => {
          const data = { ...field }; // spread field so it's not a reference
          data[key] = newValue;
          onChange(data);
        }}
        onFocus={(...props) => {
          setTimeout(setOpen, 20);
          onFocus?.(...props);
        }}
        onBlur={onBlur}
        error={!field[key]?.length}
        placeholder="Click here to edit"
      />
    </>
  );
}

function TemplateLayoutGrid() {
  const [
    { reportFields, trailingRowsSuperheader, trailingRowsHeader, trailingRowsFooter, reportLiquidRows, mainItem },
    dispatch,
    { objectFields },
  ] = useReportTemplate();

  const setReportFields = useCallback((value) => dispatch({ type: "merge", value: { reportFields: value } }), [dispatch]);
  const setTrailingRowsSuperheader = useCallback(
    (value) => dispatch({ type: "merge", value: { trailingRowsSuperheader: value } }),
    [dispatch]
  );
  const setTrailingRowsHeader = useCallback(
    (value) => dispatch({ type: "merge", value: { trailingRowsHeader: value } }),
    [dispatch]
  );
  const setTrailingRowsFooter = useCallback(
    (value) => dispatch({ type: "merge", value: { trailingRowsFooter: value } }),
    [dispatch]
  );
  const setReportLiquidRows = useCallback((value) => dispatch({ type: "merge", value: { reportLiquidRows: value } }), [dispatch]);
  const setGrouping = useCallback(
    (fieldId, groupingType) => dispatch({ type: "setReportFieldGrouping", value: { fieldId, groupingType } }),
    [dispatch]
  );

  const [moveColumnDestination, setMoveColumnDestination] = useState("");
  const moveColumnDestinationIndex = excelToColumnIndex(moveColumnDestination);
  const moveColumnDestinationIsValid = moveColumnDestinationIndex < reportFields.length;

  const moveColumnToDestination = useCallback(
    (sourceIndex, destinationIndex) => {
      const newReportFields = reportFields.slice();
      const [reportField] = newReportFields.splice(sourceIndex, 1);
      newReportFields.splice(destinationIndex, 0, reportField);
      setReportFields(newReportFields);
    },
    [reportFields, setReportFields]
  );

  const rows = useMemo(
    () => [
      /* Superheaders */
      ...trailingRowsSuperheader.map((row, index) =>
        genericRow({
          key: row.id,
          reportFields,
          rowTitle: "Extra Row",
          row,
          onChange(newRow) {
            setTrailingRowsSuperheader(arraySlice(trailingRowsSuperheader, index, newRow));
          },
          indexInGroup: index,
          rowGroup: trailingRowsSuperheader,
          setRowGroup: setTrailingRowsSuperheader,

          rowGroupsAbove: [],
          rowGroupsBelow: [trailingRowsSuperheader, trailingRowsHeader, reportLiquidRows, trailingRowsFooter],
        })
      ),

      /* ColumnNames */
      {
        id: "column-names",
        title: "Column Names",
        locked: true,
        items: reportFields.map((field, index) => ({
          content: (
            <TextField
              value={field.name}
              onChange={(newValue) => {
                setReportFields(arraySlice(reportFields, index, { ...field, name: newValue }));
              }}
              // autoFocus={autoFocusField}
              onFocus={({ target }) => {
                const { rowIndex } = target.closest("tr");
                const { cellIndex } = target.closest("td");
                document.querySelectorAll(".selected-header").forEach((element) => element.classList.remove("selected-header"));

                document
                  .querySelector("thead tr.report-layout-table__row")
                  .children[cellIndex].querySelector(".report-layout-table__spreadsheet-heading")
                  .classList.add("selected-header");
                document
                  .querySelector(".report-layout-table__heading-area")
                  .children[rowIndex].querySelector(".report-layout-table__spreadsheet-heading")
                  .classList.add("selected-header");
              }}
              onBlur={() => {
                document.querySelectorAll(".selected-header").forEach((element) => element.classList.remove("selected-header"));
              }}
            />
          ),
        })),

        rowGroupsAbove: [trailingRowsSuperheader],
        rowGroupsBelow: [trailingRowsHeader, reportLiquidRows, trailingRowsFooter],
      },

      /* Headers */
      ...trailingRowsHeader.map((row, index) =>
        genericRow({
          key: row.id,
          reportFields,
          rowTitle: "Extra Row",
          row,
          onChange(newRow) {
            setTrailingRowsHeader(arraySlice(trailingRowsHeader, index, newRow));
          },
          indexInGroup: index,
          rowGroup: trailingRowsHeader,
          setRowGroup: setTrailingRowsHeader,

          rowGroupsAbove: [trailingRowsSuperheader],
          rowGroupsBelow: [reportLiquidRows, trailingRowsFooter],
        })
      ),

      /* Data Rows */
      {
        id: "data-rows",
        title: "Cell Content",
        locked: true,
        items: reportFields.map((field, index) => ({
          content: (
            <div>
              <Stack wrap={false} spacing="extraTight">
                <Stack.Item fill>
                  {(field.text != null || field.liquid != null) && (
                    <ComputedField
                      field={field}
                      onChange={(newValue) => setReportFields(arraySlice(reportFields, index, newValue))}
                      onFocus={({ target }) => {
                        const { rowIndex } = target.closest("tr");
                        const { cellIndex } = target.closest("td");
                        document
                          .querySelectorAll(".selected-header")
                          .forEach((element) => element.classList.remove("selected-header"));

                        document
                          .querySelector("thead tr.report-layout-table__row")
                          .children[cellIndex].querySelector(".report-layout-table__spreadsheet-heading")
                          .classList.add("selected-header");
                        document
                          .querySelector(".report-layout-table__heading-area")
                          .children[rowIndex].querySelector(".report-layout-table__spreadsheet-heading")
                          .classList.add("selected-header");
                      }}
                      onBlur={() => {
                        document
                          .querySelectorAll(".selected-header")
                          .forEach((element) => element.classList.remove("selected-header"));
                      }}
                    />
                  )}
                  {field.text == null && field.liquid == null && <FieldPath id={field.id} objectFields={objectFields} />}
                </Stack.Item>
              </Stack>
            </div>
          ),
        })),

        rowGroupsAbove: [trailingRowsSuperheader, trailingRowsHeader],
        rowGroupsBelow: [reportLiquidRows, trailingRowsFooter],
      },

      /* LiquidRows */
      ...reportLiquidRows.map((row, index, list) => {
        const field = objectFields.find((objectField) => objectField.id === row.id.split("-").slice(0, -1).join("-"));

        let contextPath;
        if (field && field.name !== field.displayPath[0]) {
          contextPath = [...field.displayPath, field.name].join(" > ");
        } else if (field) {
          contextPath = field.name;
        } else {
          contextPath = row.id;
        }

        return genericRow({
          key: row.id,
          reportFields,
          objectFields,
          rowTitle: "Liquid Row",
          rowContextPath: contextPath,
          row,
          onChange(newRow) {
            setReportLiquidRows(arraySlice(reportLiquidRows, index, newRow));
          },
          isLiquidRow: true,
          isLast: index + 1 === list.length,
          indexInGroup: index,
          rowGroup: reportLiquidRows,
          setRowGroup: setReportLiquidRows,

          rowGroupsAbove: [trailingRowsSuperheader, trailingRowsHeader],
          rowGroupsBelow: [trailingRowsFooter],
        });
      }),

      /* Footers */
      ...trailingRowsFooter.map((row, index) =>
        genericRow({
          key: row.id,
          reportFields,
          rowTitle: "Extra Row",
          row,
          onChange(newRow) {
            setTrailingRowsFooter(arraySlice(trailingRowsFooter, index, newRow));
          },
          indexInGroup: index,
          rowGroup: trailingRowsFooter,
          setRowGroup: setTrailingRowsFooter,

          rowGroupsAbove: [trailingRowsSuperheader, trailingRowsHeader, reportLiquidRows],
          rowGroupsBelow: [],
        })
      ),
    ],
    [
      objectFields,
      reportFields,
      reportLiquidRows,
      setReportFields,
      setReportLiquidRows,
      setTrailingRowsFooter,
      setTrailingRowsHeader,
      setTrailingRowsSuperheader,
      trailingRowsFooter,
      trailingRowsHeader,
      trailingRowsSuperheader,
    ]
  );

  /* this is a beast but should handle all cases of moving rows around */
  const rowDragEnd = useCallback(
    ({ source, destination } = {}) => {
      if (source && destination) {
        // account for removing the dragged item
        const adjustedDestinationIndex = destination.index > source.index ? destination.index + 1 : destination.index;

        const sourceRow = rows[source.index];
        const aboveDestinationRow = rows[adjustedDestinationIndex - 1];
        const destinationRow = rows[adjustedDestinationIndex];

        const destinationMatches = sourceRow.rowGroup && sourceRow.rowGroup === destinationRow?.rowGroup;
        const aboveDestinationMatches = sourceRow.rowGroup && sourceRow.rowGroup === aboveDestinationRow?.rowGroup;

        console.log("aboveDestinationRow", aboveDestinationRow);
        console.log("destinationRow", destinationRow);
        console.log(
          "destination rowGroups",
          aboveDestinationRow?.rowGroup === destinationRow?.rowGroup,
          aboveDestinationRow?.rowGroup,
          destinationRow?.rowGroup
        );

        if (destinationMatches || aboveDestinationMatches) {
          // group is the same
          const sourceIndex = sourceRow.indexInGroup;
          const newRowGroup = sourceRow.rowGroup.slice();
          const [rowData] = newRowGroup.splice(sourceIndex, 1);

          if (destinationMatches) {
            // destination is the same group
            const destinationIndex = destinationRow.indexInGroup;
            const adjustedRowGroupDestinationIndex = destinationIndex > sourceIndex ? destinationIndex - 1 : destinationIndex;
            newRowGroup.splice(adjustedRowGroupDestinationIndex, 0, rowData);
            sourceRow.setRowGroup(newRowGroup);
          } else {
            // row above destination matches
            sourceRow.setRowGroup([...newRowGroup, rowData]);
          }
        } else if (
          destinationRow?.rowGroup &&
          (sourceRow.rowGroup === reportLiquidRows) === (destinationRow.rowGroup === reportLiquidRows)
        ) {
          // nothing matches, destination has a rowGroup
          const newSourceRowGroup = sourceRow.rowGroup.slice();
          const [rowData] = newSourceRowGroup.splice(sourceRow.indexInGroup, 1);

          const newDestinationRowGroup = destinationRow.rowGroup.slice();
          newDestinationRowGroup.splice(destination.indexInGroup, 0, rowData);
          sourceRow.setRowGroup(newSourceRowGroup);
          destinationRow.setRowGroup(newDestinationRowGroup);
        } else if (
          aboveDestinationRow?.rowGroup &&
          (sourceRow.rowGroup === reportLiquidRows) === (aboveDestinationRow.rowGroup === reportLiquidRows)
        ) {
          // nothing matches, row above destination has a rowGroup");
          const newSourceRowGroup = sourceRow.rowGroup.slice();
          const [rowData] = newSourceRowGroup.splice(sourceRow.indexInGroup, 1);

          const newDestinationRowGroup = [...aboveDestinationRow.rowGroup, rowData];
          sourceRow.setRowGroup(newSourceRowGroup);
          aboveDestinationRow.setRowGroup(newDestinationRowGroup);
        } else {
          console.warn("🛍 incompatible drag");
        }
      }
    },
    [reportLiquidRows, rows]
  );

  const wouldRowDropSucceed = useCallback(
    ({ source, destination } = {}) => {
      if (source && destination) {
        // account for removing the dragged item
        const adjustedDestinationIndex = destination.index > source.index ? destination.index + 1 : destination.index;

        const sourceRow = rows[source.index];
        const aboveDestinationRow = rows[adjustedDestinationIndex - 1];
        const destinationRow = rows[adjustedDestinationIndex];

        const destinationMatches = sourceRow.rowGroup && sourceRow.rowGroup === destinationRow?.rowGroup;
        const aboveDestinationMatches = sourceRow.rowGroup && sourceRow.rowGroup === aboveDestinationRow?.rowGroup;

        return (
          destinationMatches ||
          aboveDestinationMatches ||
          (destinationRow?.rowGroup &&
            (sourceRow.rowGroup === reportLiquidRows) === (destinationRow.rowGroup === reportLiquidRows)) ||
          (aboveDestinationRow?.rowGroup &&
            (sourceRow.rowGroup === reportLiquidRows) === (aboveDestinationRow.rowGroup === reportLiquidRows)) ||
          false // this false needs to be here otherwise we might return the value undefined
        );
      }
      return true;
    },
    [reportLiquidRows, rows]
  );

  const columnDragEnd = useCallback(
    ({ source, destination } = {}) => {
      if (source && destination) {
        moveColumnToDestination(source.index, destination.index);
      }
    },
    [moveColumnToDestination]
  );

  const aggregationValue = useMemo(() => new Map(AGGREGATE_VALUES.map((entry) => [entry.value, entry.label])), []);

  return !!mainItem && reportFields.length > 0 ? (
    <ReportLayoutTable
      columns={reportFields.map((field, index, list) => ({
        id: field.id,
        title: columnIndexToExcel(index),
        aggregation: aggregationValue.get(field.groupingType) || "",
        contextMenu: ({ dismiss }) => (
          <>
            <ActionList
              items={[
                {
                  content: "Remove",
                  destructive: true,
                  onAction: () => {
                    setReportFields(arraySlice(reportFields, index, null));
                    dismiss();
                  },
                },
                {
                  content: "Move Left",
                  disabled: index <= 0, // first?
                  onAction: () => {
                    moveColumnToDestination(index, index - 1);
                    dismiss();
                  },
                },
                {
                  content: "Move Right",
                  disabled: index >= list.length - 1, // last?
                  onAction: () => {
                    moveColumnToDestination(index, index + 1);
                    dismiss();
                  },
                },
              ]}
            />
            <div className="template-layout-grid__context-menu">
              <Stack>
                <Button
                  onClick={() => {
                    moveColumnToDestination(index, moveColumnDestinationIndex);
                    dismiss();
                  }}
                  disabled={!moveColumnDestinationIsValid}
                >
                  Move to
                </Button>
                <TextField value={moveColumnDestination} onChange={setMoveColumnDestination} />
              </Stack>
            </div>
            {/* Aggregate */}
            <FeatureGate featureName="sorting" featureValue>
              <AggregateSettingsButton
                field={field}
                index={index}
                list={list}
                setField={(newField) => setReportFields(arraySlice(reportFields, index, newField))}
                setGrouping={setGrouping}
              />
            </FeatureGate>
          </>
        ),
      }))}
      rows={rows}
      columnDragEnd={columnDragEnd}
      rowDragEnd={rowDragEnd}
      rowDragCheck={wouldRowDropSucceed}
    />
  ) : null;
}

export default TemplateLayoutGrid;
