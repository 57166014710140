import React, { createContext, useContext, useReducer } from "react";

import { templateReducer, INITIAL_STATE } from "./templateReducer";

const LocalTemplateContext = createContext(null);

function TemplateContext({ children }) {
  const [content, dispatch] = useReducer(templateReducer, INITIAL_STATE);
  return <LocalTemplateContext.Provider value={[content?.current, dispatch, content]}>{children}</LocalTemplateContext.Provider>;
}

export default TemplateContext;

export const useReportTemplate = () => useContext(LocalTemplateContext);
