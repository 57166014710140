import React, { useMemo, useCallback } from "react";
import { Stack, Subheading, ChoiceList } from "@shopify/polaris";

const TWEAKS = [
  {
    label: "End Automatic Generation after one report generates",
    value: "end_automatic",
    helpText: "This will turn off automatic generation on your template after one report successfully generates.",
  },
  {
    label: "Include line type column",
    value: "include_type",
    helpText: "This will add a column that lists the kind of row (order, line_item, fulfillment) that that row is.",
  },
  {
    label: "Zero order totals on children",
    value: "zero_order_totals",
    helpText: "This will make it such that a column will sum to it's total amount, and not print duplicate values.",
  },
  {
    label: "Duplicate Line Items",
    value: "duplicate_line_items",
    helpText:
      "This will duplicate the line item for each unit that is bought, so a line item with a quantity of 3 will become 3 rows in your report.",
  },
  {
    label: "Display Shipping Line",
    value: "include_shipping",
    helpText: "This will make the shipping a separate row in your report, as if it was a normal line item.",
  },
  {
    label: "Hide Column Names",
    value: "hide_column_names",
    helpText: "This will hide column names in your report, so your first row will be your first set of values.",
  },
];
const DEFAULT_TWEAK_SET = {
  include_type: null,
  end_automatic: null,
  zero_order_totals: null,
  hide_column_names: null,
  include_shipping: null,
  line_item_quantity: null,
};

const TWEAK_KEYS = TWEAKS.map((tweak) => tweak.value);

function ReportTweaks({ value, topLevelItem, onChange }) {
  const tweaks = TWEAKS.filter((x) => {
    if (!topLevelItem.match("order-") && ["duplicate_line_items", "include_shipping", "zero_order_totals"].includes(x.value)) {
      return null;
    }

    if (x.value === "duplicate_line_items" && !topLevelItem.match(/order-line_items/)) {
      return { x, disabled: true };
    }

    return x;
  });

  const selected = useMemo(
    () =>
      Object.entries(value)
        .filter(([, b]) => b)
        .map(([a]) => a),
    [value]
  );

  const onChoiceListChange = useCallback(
    (tweakArray) =>
      onChange({
        ...DEFAULT_TWEAK_SET,
        ...Object.fromEntries(TWEAK_KEYS.map((key) => [key, tweakArray.includes(key)])),
      }),
    [onChange]
  );

  return (
    <Stack vertical>
      <Subheading>One Click Report Tweaks</Subheading>
      <ChoiceList allowMultiple choices={tweaks} selected={selected} onChange={onChoiceListChange} />
    </Stack>
  );
}

export default ReportTweaks;
