/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, createContext, useState, useCallback } from "react";
import { Button, Badge, Stack, Banner, Tooltip, Popover, ChoiceList as PolarisChoiceList, Link } from "@shopify/polaris";
import { useHistory } from "react-router-dom";

import { SubscriptionContext } from "./SubscriptionContext";

const EMPTY_STATE = {
  show: false,
  featureName: null,
  featureValue: null,
  featureDisplay: null,
  planName: null,
  currentPlan: null,
  upgradePlan: null,
};

const FeatureContext = createContext(EMPTY_STATE);

const getFeatureData = ({ featureName, featureValue }, { plans, activePlan }) => {
  const feature = activePlan?.features[featureName];
  let featureDisplay = feature?.display;
  const show = feature && typeof feature?.value === "boolean" ? !feature?.value : feature?.value < featureValue;

  let planName = null;
  let filteredPlans = [];

  if (show) {
    if (typeof featureValue === "boolean") {
      filteredPlans = plans.filter((p) => p.features[featureName].value === featureValue);
    } else {
      filteredPlans = plans.filter((p) => p.features[featureName].value >= featureValue);
    }
    filteredPlans = filteredPlans.sort((a, b) => a.display_rank - b.display_rank);

    planName = filteredPlans[0]?.name;
    featureDisplay = filteredPlans[0]?.features[featureName].display;
  } else if (activePlan) {
    planName = activePlan.plan_name;
  }

  return {
    show,
    featureName,
    featureValue,
    featureDisplay,
    planName,
    upgradePlan: filteredPlans.unshift(),
    currentPlan: activePlan,
  };
};

export const useFeatureContext = (featureData) => {
  const ctx = useContext(FeatureContext);
  const subscriptionContext = useContext(SubscriptionContext);
  if (ctx) return ctx;

  return getFeatureData(featureData, subscriptionContext);
};

export const useFeatureContexts = (featureData) => {
  const subscriptionContext = useContext(SubscriptionContext);
  return featureData.map((entry) => getFeatureData(entry, subscriptionContext));
};

const MyBadge = ({ show, planName }) => {
  if (show) {
    return (
      <div style={{ opacity: "50%" }}>
        <Badge status="warning">{`${planName} required`}</Badge>
      </div>
    );
  }
  return <></>;
};

export const UpgradeBadge = () => {
  const featureState = useContext(FeatureContext);
  const [show, setShow] = useState(false);
  const togglePopover = useCallback(() => {
    setShow(!show);
  }, [show]);

  return !featureState.show ? (
    <></>
  ) : (
    <UpgradePopover
      activator={
        <Button plain onClick={togglePopover}>
          <MyBadge show={featureState.show} planName={featureState.planName} />
        </Button>
      }
      active={show}
    />
  );
};

export const UpgradeButton = ({ children, onClick, url, callback, showBadge = false, hide = false, ...props }) => {
  const featureState = useContext(FeatureContext);
  const [show, setShow] = useState(false);
  const togglePopover = useCallback(() => {
    setShow(!show);
  }, [show]);

  return hide && featureState.show ? (
    <></>
  ) : (
    <UpgradePopover
      activator={
        <Button {...props} url={featureState.show ? null : url} onClick={() => (featureState.show ? togglePopover() : onClick())}>
          {children}
          {showBadge && (
            <Tooltip preferredPosition="above" content={featureState.show ? "click here to learn more" : "included in your subscription"}>
              <MyBadge show={featureState.show} planName={featureState.planName} />
            </Tooltip>
          )}
        </Button>
      }
      active={show}
    />
  );
};

export const UpgradeBanner = ({ callback, bannerText, children, ...bannerArgs }) => {
  const featureState = useContext(FeatureContext);
  const url = `/account?highlightFeature=${featureState.featureName}&featureValue=${featureState.featureValue}`;
  return (
    <Banner
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...bannerArgs}
      action={{
        content: "Upgrade Plan",
        url,
      }}
    >
      {children}
    </Banner>
  );
};

export const UpgradePopover = ({ children, ...PopoverProps }) => {
  const featureState = useContext(FeatureContext);
  const history = useHistory();

  return (
    // <Tooltip preferredPosition="above" content={featureState.show ? "click here to learn more" : "included in your subscription"}>
    <Popover {...PopoverProps}>
      {featureState.show ? (
        <div style={{ textAlign: "center", padding: "2.2rem" }}>
          <p>
            {`This feature requires "${featureState.featureDisplay.key}", part of our ${featureState.planName} plan. `}
            <Button
              plain
              monochrome
              onClick={() => {
                history.push(`/account?highlightFeature=${featureState.featureName}&featureValue=${featureState.featureValue}`);
              }}
            >
              Click here
            </Button>
            {` to go to the accounts page to see what else is on ${featureState.planName} plan.`}
            <br />
            <br />
            If you have any changes on this page please save them before proceeding.
          </p>
        </div>
      ) : (
        children
      )}
    </Popover>
  );
};

export const UpgradeLink = ({ children, ...LinkProps }) => {
  const featureState = useContext(FeatureContext);
  return featureState.show ? (
    <Link {...LinkProps} url={`/account?highlightFeature=${featureState.featureName}&featureValue=${featureState.featureValue}`}>
      {children}
    </Link>
  ) : (
    <></>
  );
};

export const FeatureGateChoiceList = ({ ...ChoiceListProps }) => {
  const states = useFeatureContexts(ChoiceListProps.choices);

  const newChoices = states.map((state, i) => {
    const optionProps = ChoiceListProps.choices[i];
    const label = optionProps.label || optionProps.value;

    const newLabel = (
      <Stack>
        <>{label}</>
        <>
          <FeatureContext.Provider value={state}>
            <UpgradeBadge asPopover />
          </FeatureContext.Provider>
        </>
      </Stack>
    );

    return {
      ...optionProps,
      label: newLabel,
      disabled: state.show,
    };
  });

  return <PolarisChoiceList {...ChoiceListProps} choices={newChoices} />;
};

export const FeatureGate = ({ featureName, featureValue, render, children }) => {
  const state = getFeatureData({ featureName, featureValue }, useContext(SubscriptionContext));

  // render state or children but not both
  return <FeatureContext.Provider value={state}>{render ? render(state) : children}</FeatureContext.Provider>;
};

export default FeatureGate;
