/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Page, Layout, Link, Stack, Heading } from "@shopify/polaris";
import { useXporterApi } from "./ApiProvider";
import { LargeReportList, LargeReportListGroups } from "./components/ReportsList";

function QuickRunReportsPage() {
  const xporterApi = useXporterApi();
  const [templates, setTemplates] = useState([]);

  useEffect(() => {
    xporterApi.getTemplates().then(setTemplates);
  }, [xporterApi]);

  return (
    <Page fullWidth title="Run a Report" subtitle="Select a report from the list" breadcrumbs={[{ content: "Overview", url: "/" }]}>
      <Layout>
        <Layout.Section>
          <Stack vertical>
            <p>
              Not finding what you need? <Link url="/templates">Create a custom report</Link> or contact support, we&#39;d love to
              help.
            </p>
            <Heading>Custom Reports</Heading>
            {templates?.length > 0 && <LargeReportList alwaysOpen items={templates} quick />}
            <Heading>Starter Reports</Heading>
            <LargeReportListGroups title="Starter Reports" starter hasCustomTemplates={templates?.length > 0} quick>
              Starter Reports are reports we&apos;ve created to help get you started; edit one to customize it to your needs.
            </LargeReportListGroups>
          </Stack>
        </Layout.Section>
      </Layout>
    </Page>
  );
}

export default QuickRunReportsPage;
