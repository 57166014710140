/* eslint-disable no-bitwise */
import React, { useState, useEffect, useMemo, useCallback, useReducer, useRef, createContext, useContext } from "react";
import { useFetch } from "./components/utils/adminFrontend";

const XporterApiContext = createContext(null);

const DELETE = { method: "DELETE" };
const CACHE_RELOAD = { cache: "reload" };

const QUERY_LIST = Symbol("QUERY_LIST");
const FIELD_LIST = Symbol("FIELD_LIST");
const SHOP_TEMPLATES = Symbol("SHOP_TEMPLATES");
const DEFAULT_TEMPLATES = Symbol("DEFAULT_TEMPLATES");

const FILE_LISTING_REQUEST_PAGE_SIZE = 10;

const BASE_BACKOFF_TIME = 1000;
const MAX_BACKOFF_TIME = 120_000;
const BACKOFF_TIME_MULTIPLIER = 3;

const offsetLimitMask = (offset, limit) => ((1n << BigInt(limit)) - 1n) << BigInt(offset);

async function json(response) {
  if (response.status === 204) return Promise.resolve();

  if (!/^application\/json\b/i.test(response.headers.get("Content-Type"))) throw response;
  const payload = await response.json();
  if (!response.ok || Object.prototype.hasOwnProperty.call(payload, "error")) throw payload;
  return payload;
}

function post(data) {
  return {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
}

const xporterApiFactory = (fetch, { queueRefreshRestartRef, fileListingRefreshRestartRef }) => ({
  async getTemplateFields() {
    if (!this[FIELD_LIST]) {
      this[FIELD_LIST] = await fetch(`/api/v1/templates/fields`).then(json);
    }
    return this[FIELD_LIST];
  },
  async getTemplateQueries() {
    if (!this[QUERY_LIST]) {
      this[QUERY_LIST] = await fetch(`/api/v1/templates/queries`).then(json);
    }
    return this[QUERY_LIST];
  },

  async getTemplates() {
    if (!this[SHOP_TEMPLATES]) {
      this[SHOP_TEMPLATES] = await fetch("/api/v1/templates", CACHE_RELOAD).then(json);
    }
    return this[SHOP_TEMPLATES];
  },
  async getDefaultTemplates() {
    if (!this[DEFAULT_TEMPLATES]) {
      this[DEFAULT_TEMPLATES] = fetch("/api/v1/templates/default", CACHE_RELOAD).then(json);
    }
    return this[DEFAULT_TEMPLATES];
  },
  async getAllTemplates() {
    return Promise.all([this.getDefaultTemplates(), this.getTemplates()]).then(([s, d]) => [...s, ...d]);
  },
  async getTemplate(id) {
    return fetch(`/api/v1/templates/${encodeURIComponent(id)}`, CACHE_RELOAD).then(json);
  },
  async saveTemplate(id, template) {
    const path = id == null ? `/api/v1/templates` : `/api/v1/templates/${encodeURIComponent(id)}`;
    return fetch(path, post({ template })).then((response) => {
      this[SHOP_TEMPLATES] = null;
      return json(response);
    });
  },
  async deleteTemplate(id) {
    return fetch(`/api/v1/templates/${encodeURIComponent(id)}`, DELETE).then((response) => {
      this[SHOP_TEMPLATES] = null;
      return json(response);
    });
  },
  async duplicateTemplates({templateIds, targetShop}) {
    const path =  `/api/v1/templates/duplicate`;
    return fetch(path, post({ templateIds, targetShop })).then((response) => {
      this[SHOP_TEMPLATES] = null;
      return json(response);
    });
  },
  async testTemplateFtp(reportGeneration, reportFormat) {
    return fetch("/api/v1/templates/testftp", post({ reportGeneration, reportFormat })).then(json);
  },

  async generateReport(templateId, { name, start, end, custom }) {
    const promise = fetch(`/api/v1/templates/${templateId}/generate`, post({ name, start, end, custom })).then(json);
    promise
      .finally(() => {
        queueRefreshRestartRef.current?.();
        fileListingRefreshRestartRef.current?.();
      })
      .catch(() => {});
    return promise;
  },
  async getReports(offset = 0, limit = 10) {
    return fetch(`/api/v1/reports?limit=${limit}&offset=${offset}`, CACHE_RELOAD).then(json);
  },
  async deleteReports(ids) {
    const idsParam = ids.map(encodeURIComponent).join(",");
    const promise = fetch(`/api/v1/reports?ids=${idsParam}`, DELETE).then(json);
    promise.finally(() => {
      fileListingRefreshRestartRef.current?.();
    });
    return promise;
  },

  async getQueue() {
    return fetch(`/api/v1/queue`, CACHE_RELOAD).then(json);
  },
  async removeQueueItem(id) {
    const promise = fetch(`/api/v1/queue/${encodeURIComponent(id)}`, DELETE).then(json);
    promise
      .finally(() => {
        queueRefreshRestartRef.current?.();
        fileListingRefreshRestartRef.current?.();
      })
      .catch(() => {});
    return promise;
  },

  async validateLiquid(value) {
    const data = typeof value === "string" ? { liquid: value } : value;
    return fetch("/api/v1/templates/validateliquid", post(data)).then((response) => {
      if (!response.ok) {
        return response.json();
      }
      return null;
    });
  },
});

const fileListingReducer = ([last, lastLoaded], { merge, offset, clear, count = null, wipe = false }) => {
  let next = last.slice();
  let nextLoaded = clear ? 0n : lastLoaded;
  if (count !== null && count !== next.length) {
    next.length = count;
    next.fill(null);
    nextLoaded = 0n;
  }

  if (wipe) {
    next.fill(null);
    nextLoaded = 0n;
  }

  if (merge && offset > -1) {
    // lengthen the array if more space is needed for this merge (should not happen)
    if (next.length < offset) {
      next = [...next, ...new Array(offset - next.length).fill(null)];
    }

    next.splice(offset, merge.length, ...merge);
    nextLoaded |= ((1n << BigInt(merge.length)) - 1n) << BigInt(offset);
  }
  return [next, nextLoaded];
};

const fileListingUseMasksReducer = (last, { add, remove }) => {
  const next = last.slice();
  if (add) next.push(add);
  if (remove) {
    const index = next.indexOf(remove);
    if (index > -1) next.splice(index, 1);
  }
  return next;
};

export function ApiProvider({ children }) {
  const authFetch = useFetch();

  const [queue, setQueue] = useState([]);
  const [queueUseCount, dispatchQueueUseCount] = useReducer((last, action) => last + action, 0);

  const [[fileListing, fileListingLoadedMask], dispatchFileListing] = useReducer(fileListingReducer, [[], 0n]);

  const [fileListingUseMasks, dispatchFileListingUseMasks] = useReducer(fileListingUseMasksReducer, []);

  const fileListingUseMask = useMemo(() => fileListingUseMasks.reduce((a, b) => a | b, 0n), [fileListingUseMasks]);

  const [pageIsVisible, setPageIsVisible] = useState(true);

  useEffect(() => {
    const updatePageIsVisible = () => setPageIsVisible(document.visibilityState === "visible");
    document.addEventListener("visibilitychange", updatePageIsVisible);
    return () => document.removeEventListener("visibilitychange", updatePageIsVisible);
  }, []);

  const shouldBeUpdaingQueue = queueUseCount > 0 && pageIsVisible;
  const shouldBeUpdaingFileListing = fileListingUseMasks > 0 && pageIsVisible;

  const invalidateReportCache = useCallback(() => {
    dispatchFileListing({ clear: true });
  }, []);

  const emptyReportCache = useCallback(() => {
    dispatchFileListing({ clear: true, wipe: true });
  }, []);

  const queueRefreshRestartRef = useRef(null);
  const fileListingRefreshRestartRef = useRef(null);

  const xporterApi = useMemo(
    () => xporterApiFactory(authFetch, { queueRefreshRestartRef, fileListingRefreshRestartRef }),
    [authFetch]
  );

  const updateQueue = useCallback(
    () =>
      xporterApi
        .getQueue()
        .then(setQueue)
        .catch((ex) => console.error(ex)),
    [xporterApi]
  );

  const updatePage = useCallback(
    (currentOffset) => (response) => {
      dispatchFileListing({ merge: response.reports, offset: currentOffset, count: response.count });
    },
    []
  );

  useEffect(() => {
    if (fileListingUseMask === 0n) {
      // if there is nothing to request, stop early, do nothing
      return;
    }

    let currentOffset = 0;
    const currentCount = fileListing.length;
    const fileListingRemainingMask = fileListingUseMask & ~fileListingLoadedMask;

    do {
      const currentIterationMask = offsetLimitMask(currentOffset, FILE_LISTING_REQUEST_PAGE_SIZE);

      // if there are requested elements in the window we are requesting, request the window
      if ((currentIterationMask & fileListingRemainingMask) !== 0n) {
        xporterApi
          .getReports(currentOffset, FILE_LISTING_REQUEST_PAGE_SIZE)
          .then(updatePage(currentOffset))
          .catch((ex) => {
            console.warn("getReports in useXporterFileListing", ex);
          });
        return;
      }

      currentOffset += FILE_LISTING_REQUEST_PAGE_SIZE;
    } while (currentOffset < currentCount);
  }, [fileListing.length, fileListingLoadedMask, fileListingUseMask, updatePage, xporterApi]);

  const backoffRefreshInterval = (fn, restartRef = {}) => {
    let currentInterval = null;
    let currentTimeout = null;
    let currentBackoff = MAX_BACKOFF_TIME;

    function next() {
      currentTimeout = null;
      fn();

      if (currentBackoff < MAX_BACKOFF_TIME) {
        currentBackoff *= BACKOFF_TIME_MULTIPLIER;
        currentTimeout = setTimeout(next, currentBackoff);
      } else {
        currentInterval = setInterval(fn, MAX_BACKOFF_TIME);
      }
    }

    function stop() {
      if (currentInterval) clearInterval(currentInterval);
      if (currentTimeout) clearTimeout(currentTimeout);
    }

    next();

    // this is a ref
    // eslint-disable-next-line no-param-reassign
    restartRef.current = () => {
      currentBackoff = BASE_BACKOFF_TIME;
      stop();
      next();
    };

    return () => {
      stop();
      // this is a ref
      // eslint-disable-next-line no-param-reassign
      restartRef.current = null;
    };
  };

  useEffect(() => {
    if (shouldBeUpdaingQueue) {
      return backoffRefreshInterval(updateQueue, queueRefreshRestartRef);
    }
    return () => {};
  }, [xporterApi, shouldBeUpdaingQueue, updateQueue]);

  useEffect(() => {
    if (shouldBeUpdaingFileListing) {
      return backoffRefreshInterval(invalidateReportCache, fileListingRefreshRestartRef);
    }
    return () => {};
  }, [invalidateReportCache, shouldBeUpdaingFileListing]);

  useEffect(() => {
    dispatchFileListing({ clear: true });
  }, [queue.length]);

  const providerData = useMemo(
    () => ({
      api: xporterApi,
      queue,
      fileListing,
      dispatchQueueUseCount,
      dispatchFileListingUseMasks,
      emptyReportCache,
      updateQueue,
    }),
    [xporterApi, queue, fileListing, emptyReportCache, updateQueue]
  );

  useEffect(() => {
    window.XPORTER_API = xporterApi;
    return () => delete window.XPORTER_API;
  }, [xporterApi]);

  return <XporterApiContext.Provider value={providerData}>{children}</XporterApiContext.Provider>;
}

export const useXporterApi = () => useContext(XporterApiContext).api;
export const useXporterQueue = () => {
  const { queue, dispatchQueueUseCount } = useContext(XporterApiContext);
  useEffect(() => {
    dispatchQueueUseCount(1);
    return () => {
      dispatchQueueUseCount(-1);
    };
  }, [dispatchQueueUseCount]);
  return queue;
};

export const useXporterFileListing = (offset = 0, limit = FILE_LISTING_REQUEST_PAGE_SIZE) => {
  const { fileListing, dispatchFileListingUseMasks } = useContext(XporterApiContext);

  useEffect(() => {
    const mask = offsetLimitMask(offset, limit);
    dispatchFileListingUseMasks({ add: mask });
    return () => {
      dispatchFileListingUseMasks({ remove: mask });
    };
  }, [dispatchFileListingUseMasks, limit, offset]);

  const fileListingEntries = fileListing.slice(offset, offset + limit);
  return fileListingEntries;
};

export const useXporterFileListingCount = () => useContext(XporterApiContext).fileListing.length;

export const useFileListingRefresh = () => useContext(XporterApiContext).emptyReportCache;

export const useQueueRefresh = () => useContext(XporterApiContext).updateQueue;
