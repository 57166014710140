import React, { useCallback } from "react";
import { Stack, FormLayout, TextField, Select, ResourceList, Subheading, Checkbox } from "@shopify/polaris";
import { FeatureGate, UpgradeBadge } from "../utils/adminFrontend";

import { useReportTemplate } from "../../TemplateContext";

import { CURRENCY_FORMATS, DATE_FORMATS, ENCODINGS, ROW_FORMATS, QUOTE_BEHAVIORS, FILE_FORMATS } from "./values";

function ReportFormat({ hideReportStructure = false }) {
  const [{ reportFormat }, dispatch, { objectFields, reportFieldManyObjects }] = useReportTemplate();

  const {
    row_format: rowFormat,
    currency_format: currencyFormat,
    join_separators: joinSeparators = {},
    type,
    quote_behavior: quoteBehavior,
    date_format: dateFormat,
    delimiter_line: delimiterLine,
    join_parents: joinParents,
    delimiter_cell: delimiterCell,
    top_level_item: topLevelItem,
    encoding,
  } = reportFormat;

  const update = useCallback(
    (data) => {
      const onChange = (value) => dispatch({ type: "merge", value: { reportFormat: value } });
      onChange({ ...reportFormat, ...data });
    },
    [dispatch, reportFormat]
  );

  const setJoin = useCallback(
    (selectedJoinItem, joinType, joinSeparator) => {
      if (joinType === "separator") {
        update({
          ...reportFormat,
          join_parents: joinParents.filter((el) => el !== selectedJoinItem),
          join_separators: { ...joinSeparators, [selectedJoinItem]: joinSeparator },
        });
      } else if (joinType === "parent") {
        update({
          ...reportFormat,
          join_parents: [...joinParents.filter((el) => el !== selectedJoinItem), selectedJoinItem].sort(),
          join_separators: Object.fromEntries(Object.entries(joinSeparators).filter(([k]) => k !== selectedJoinItem)),
        });
      } else {
        update({
          ...reportFormat,
          join_parents: joinParents.filter((el) => el !== selectedJoinItem),
          join_separators: Object.fromEntries(Object.entries(joinSeparators).filter(([k]) => k !== selectedJoinItem)),
        });
      }
    },
    [joinParents, joinSeparators, reportFormat, update]
  );

  const formatItemID = (id) => {
    const field = objectFields.find((objectField) => objectField.id === id);
    return field ? [...field.displayPath, field.name].join(" > ") : id;
  };

  const joins = reportFieldManyObjects.slice(1).filter((item) => item !== topLevelItem);

  const formatParentString = useCallback(
    (id) => {
      const object = objectFields.find((field) => field.id === id);
      return object.displayPath[object.displayPath.length - 1];
    },
    [objectFields]
  );

  const renderJoinItem2 = useCallback(
    (item) => {
      const { id, name } = item;

      return (
        <div>
          <p>
            <b>{name}</b>
          </p>
          <Stack wrap={false} distribution="equalSpacing" vertical>
            <Stack.Item>
              <Checkbox
                label={`Display on new row after each ${formatParentString(id)} Row`}
                onChange={() => {
                  if (!joinParents.includes(id) && Object.prototype.hasOwnProperty.call(joinSeparators, id)) {
                    setJoin(id, "parent", null);
                  } else {
                    setJoin(id, "none", null);
                  }
                }}
                checked={!joinParents.includes(id) && !Object.prototype.hasOwnProperty.call(joinSeparators, id)}
              />
            </Stack.Item>
            <Stack.Item>
              <Stack>
                <Stack.Item>
                  <Checkbox
                    label={
                      Object.prototype.hasOwnProperty.call(joinSeparators, id)
                        ? "Merge multiple lines using"
                        : "Merge multiple lines"
                    }
                    onChange={() => {
                      if (Object.prototype.hasOwnProperty.call(joinSeparators, id)) {
                        setJoin(id, "parent", null);
                      } else {
                        setJoin(id, "separator", ",");
                      }
                    }}
                    checked={Object.prototype.hasOwnProperty.call(joinSeparators, id)}
                  />
                </Stack.Item>
                <div style={{ width: "5vw" }}>
                  <Stack.Item>
                    {Object.prototype.hasOwnProperty.call(joinSeparators, id) && (
                      <TextField
                        value={joinSeparators[id]}
                        onChange={(separator) =>
                          update({
                            ...reportFormat,
                            join_separators: { ...joinSeparators, [id]: separator },
                          })
                        }
                      />
                    )}
                  </Stack.Item>
                </div>
              </Stack>
            </Stack.Item>
          </Stack>
          <br />
        </div>
      );
    },
    [formatParentString, joinParents, joinSeparators, reportFormat, setJoin, update]
  );

  const joinSection = ({ show }) => (
    <>
      <Subheading>
        <Stack alignment="center">
          <Stack.Item>Joins</Stack.Item>
          <Stack.Item>
            <UpgradeBadge asPopover />
          </Stack.Item>
        </Stack>
      </Subheading>
      <p>Control how related items are connected to their parent.</p>
      <ResourceList
        emptyState={show ? <p>Can&apos;t use connections</p> : <p>No connections available</p>}
        items={
          show
            ? []
            : joins.map((object) => ({
                id: object,
                name: formatItemID(object),
              }))
        }
        renderItem={renderJoinItem2}
      />
    </>
  );

  return (
    <Stack vertical>
      {!hideReportStructure && (
        <>
          <Subheading>Report Structure</Subheading>
          <FormLayout>
            <FormLayout.Group>
              <Select
                label="Main rows are"
                options={[...reportFieldManyObjects].map((id) => ({ label: formatItemID(id), value: id }))}
                onChange={(data) => {
                  delete joinParents[data];
                  delete joinSeparators[data];
                  update({ top_level_item: data, join_parents: Object.keys(joinParents), join_separators: joinSeparators });
                }}
                value={topLevelItem && topLevelItem !== "-1" ? topLevelItem : ""}
              />
              {rowFormat === "flat" && (
                <Select
                  label="Row Format"
                  options={ROW_FORMATS}
                  onChange={(data) => {
                    update({ row_format: data });
                  }}
                  value={rowFormat}
                />
              )}
            </FormLayout.Group>
            {joins.length > 0 && (
              <FormLayout.Group>
                <FeatureGate featureName="join-separators" featureValue render={joinSection} />
              </FormLayout.Group>
            )}
          </FormLayout>
        </>
      )}
      <Subheading>File Options</Subheading>
      <p>These settings will effect the output file of the report.</p>
      <FormLayout>
        <TextField
          label="Date Format"
          value={dateFormat}
          disabled={DATE_FORMATS.find((x) => x.value === dateFormat)}
          onChange={(data) => {
            update({ date_format: data });
          }}
          connectedLeft={
            <Select
              labelHidden
              options={DATE_FORMATS}
              value={DATE_FORMATS.find((x) => x.value === dateFormat)?.value ?? "Custom"}
              onChange={(data) => {
                if (data === "Custom") {
                  update({ date_format: "" });
                } else {
                  update({ date_format: data });
                }
              }}
            />
          }
        />
        <Select
          label="Output format"
          options={FILE_FORMATS}
          onChange={(data) => {
            update({ type: data });
          }}
          value={type}
        />
        {(type === "csv" || type === "text") && (
          <TextField
            label="Row delimiter"
            value={delimiterLine}
            onChange={(data) => {
              update({ delimiter_line: data });
            }}
          />
        )}
        {(type === "csv" || type === "text") && (
          <TextField
            label="Cell delimiter"
            value={delimiterCell}
            onChange={(data) => {
              update({ delimiter_cell: data });
            }}
          />
        )}
        {(type === "csv" || type === "text") && (
          <Select
            label="Quote Cells"
            options={QUOTE_BEHAVIORS}
            onChange={(data) => {
              update({ quote_behavior: data });
            }}
            value={quoteBehavior}
          />
        )}

        <Select
          label="File Encoding"
          options={ENCODINGS}
          onChange={(data) => {
            update({ encoding: data });
          }}
          value={encoding}
        />
        <TextField
          label="Currency Format"
          value={currencyFormat}
          disabled={CURRENCY_FORMATS.find((x) => x.value === currencyFormat)}
          onChange={(data) => {
            update({ currency_format: data });
          }}
          connectedLeft={
            <Select
              options={CURRENCY_FORMATS}
              value={CURRENCY_FORMATS.find((x) => x.value === currencyFormat)?.value ?? "Custom"}
              onChange={(data) => {
                if (data === "Custom") {
                  update({ currency_format: "{{amount}}" });
                } else {
                  update({ currency_format: data });
                }
              }}
              labelHidden
            />
          }
        />
      </FormLayout>
    </Stack>
  );
}

export default ReportFormat;
