import React from "react";
import { Card, TextField, FormLayout, Stack } from "@shopify/polaris";
import { useReportTemplate } from "../../TemplateContext";

function Xml() {
  const [{ xmlNamesSingular: singular = {}, xmlNamesPlural: plural = {}, xmlDoctype = {} }, dispatch, { reportFieldAllObjects }] =
    useReportTemplate();

  const { type: xmlDocType = "", public_id: xmlPublicId = "", system_id: xmlSystemId = "" } = xmlDoctype || {};

  const doctypeUpdate = (data) => dispatch({ type: "merge", value: { xmlDoctype: { ...xmlDoctype, ...data } } });
  const singularUpdate = (data, id) => dispatch({ type: "merge", value: { xmlNamesSingular: { ...singular, [id]: data } } });
  const pluralUpdate = (data, id) => dispatch({ type: "merge", value: { xmlNamesPlural: { ...plural, [id]: data } } });

  return (
    <>
      {reportFieldAllObjects.length > 0 && (
        <Card.Section title="Node Names">
          <Stack vertical>
            {reportFieldAllObjects.map((item) => (
              <Stack.Item key={item.id}>
                <FormLayout>
                  <FormLayout.Group>
                    <TextField
                      label={`${item.id} Singular`}
                      value={singular[item.id]}
                      onChange={(singularName) => singularUpdate(singularName, item.id)}
                    />
                    {item.type !== "one" && (
                      <TextField
                        label={`${item.id} Plural`}
                        value={plural[item.id]}
                        onChange={(pluralName) => pluralUpdate(pluralName, item.id)}
                      />
                    )}
                  </FormLayout.Group>
                </FormLayout>
              </Stack.Item>
            ))}
          </Stack>
        </Card.Section>
      )}

      <Card.Section>
        <TextField
          label="Doc Type"
          value={xmlDocType}
          onChange={(data) => {
            doctypeUpdate({ type: data });
          }}
        />
        <TextField
          label="Public ID"
          value={xmlPublicId}
          onChange={(data) => {
            doctypeUpdate({ public_id: data });
          }}
        />
        <TextField
          label="System ID"
          value={xmlSystemId}
          onChange={(data) => {
            doctypeUpdate({ system_id: data });
          }}
        />
      </Card.Section>
    </>
  );
}

export default Xml;
