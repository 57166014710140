/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useCallback, useEffect, useRef } from "react";
import { Button } from "@shopify/polaris";

const AsyncButton = ({ onClick, children, disabled, loading, ...rest }) => {
  const [isPending, setIsPending] = useState(false);
  const hasUnmounted = useRef(false);
  const localOnClick = useCallback(() => {
    setIsPending(true);
    Promise.resolve(onClick()).finally(() => {
      if (!hasUnmounted.current) {
        setIsPending(false);
      }
    });
  }, [onClick]);

  useEffect(
    () => () => {
      hasUnmounted.current = true;
    },
    [],
  );

  return (
    <Button onClick={localOnClick} disabled={disabled || isPending} loading={loading || isPending} {...rest}>
      {children}
    </Button>
  );
};

export default AsyncButton;
