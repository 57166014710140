/* eslint-disable no-console, react/jsx-props-no-spreading */

import React, { useContext, useState, useCallback, useEffect } from "react";

import { DataTable, Button, Card, Modal, TextContainer, Spinner, Stack } from "@shopify/polaris";
import { useLocation, useHistory } from "react-router-dom";

import { DateTime } from "luxon";
import { SubscriptionContext } from "../contexts/SubscriptionContext";
import { useFetch, useProfile, useRedirect } from "../utils";

export default () => {
  const { activeSubscriptions, reload } = useContext(SubscriptionContext);
  const authFetch = useFetch();

  const history = useHistory();
  const location = useLocation();
  const redirect = useRedirect();
  const [profile] = useProfile();

  const [activeModal, setActiveModal] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [loadingTable, setLoadingTable] = useState(true);
  const [selectedSubscription, setSelectedSubscription] = useState(null);

  const handleActive = useCallback(() => setActiveModal(!activeModal), [activeModal]);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    if (query.get("confirm")) {
      setLoadingTable(true);
      authFetch(`/api/charges/${query.get("confirm")}/confirm`)
        .then((r) => r.json())
        .then(() => {
          console.log("Confirmed charge.");
          reload();
          history.replace(`/`, history.location.state);
        })
        .catch(() => {})
        .finally(() => {
          setLoadingTable(false);
        });
    }
  }, [authFetch, history, location.search, reload]);

  const confirmCancelModal = (subscription) => {
    setSelectedSubscription(subscription);
    handleActive();
  };

  const handleConfirmCancel = (subscription) => {
    setLoadingButton(true);
    setLoadingTable(true);
    authFetch(`api/subscriptions/${subscription.id}/unsubscribe`, { headers: { "Content-Type": "application/json" } })
      .then(async (response) => {
        const unsubscribeData = await response.json();
        // check for error response
        if (!response.ok) {
          console.log("Error handling uninstall request");
          // get error message from body or default to response status
          const error = (unsubscribeData && unsubscribeData.message) || response.status;
          throw error;
        }
        console.log("Uninstall finished successfully");
      })
      .catch((error) => {
        console.error("Error handling uninstall request", error);
      })
      .finally(() => {
        setSelectedSubscription(null);
        setLoadingButton(false);
        reload();
        setLoadingTable(false);
        handleActive();
      });
  };

  const handleConfirmPending = (subscription) => {
    console.log(`Redirecting to charge confirmation url for subscription ${subscription.id}`);
    redirect(subscription.confirmation_url);
  };

  const activeSubscriptionRows = (activeSubscription) =>
    activeSubscription &&
    activeSubscription.length > 0 &&
    activeSubscription.map((subscription) => [
      subscription.name,
      subscription.state,
      `$${subscription.cost}`,
      subscription.annual ? "Annually" : "Monthly",
      DateTime.fromISO(subscription.date_subscribed).toFormat("fff"),
      subscription.trial_days,
      subscription.trial_end_date,
      subscription.date_unsubscribed ? subscription.date_unsubscribed : "N/A",
      <Stack wrap={false} distribution="trailing">
        {!subscription.active && (
          <Button onClick={() => handleConfirmPending(subscription)} primary disabled={!profile.user.account_owner}>
            Activate{!profile.user.account_owner ? " (Requires Owner)" : ""}
          </Button>
        )}
        {profile && profile.user && profile.user.support && (
          <Button destructive onClick={() => confirmCancelModal(subscription)}>
            Cancel
          </Button>
        )}
      </Stack>,
    ]);

  useEffect(() => {
    if (activeSubscriptions !== null) {
      setLoadingTable(false);
    }
  }, [activeSubscriptions]);

  return activeSubscriptions && activeSubscriptions.length > 0 ? (
    <Card title="Subscription History" actions={[{ onAction: reload, content: "Reload" }]}>
      <Modal
        title="Cancel Subscription?"
        open={activeModal}
        onClose={handleActive}
        primaryAction={{
          content: "Cancel",
          onAction: () => {
            handleConfirmCancel(selectedSubscription);
          },
          loading: loadingButton,
          destructive: true,
        }}
        secondaryActions={[
          {
            content: "Close",
            onAction: handleActive,
          },
        ]}
      >
        <Modal.Section>
          <TextContainer>
            <p>Are you sure you want to cancel your subscription? This action can’t be undone.</p>
          </TextContainer>
        </Modal.Section>
      </Modal>
      {loadingTable ? (
        <Spinner accessibilityLabel="Loading Subscriptions" size="large" />
      ) : (
        <DataTable verticalAlign="middle" columnContentTypes={["text", "text", "numeric", "text", "text", "text", "text", "text"]} headings={["Name", "Status", "Amount", "Billing", "Created", "Trial Days", "Trial Ends", "Cancelled", ""]} rows={activeSubscriptionRows(activeSubscriptions)} />
      )}
    </Card>
  ) : (
    <></>
  );
};
