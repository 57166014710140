import React, { useCallback, useEffect, useRef } from "react";
import Editor from "@monaco-editor/react";
import { Modal, Banner, Button, Stack, ButtonGroup } from "@shopify/polaris";

import {
  languageConfiguration,
  // languageDefinition,
} from "./utils/moddLiquid";

export const addLiquidLanguage = (monaco) => {
  // monaco.languages.setMonarchTokensProvider("liquid", languageDefinition);
  monaco.languages.setLanguageConfiguration("liquid", languageConfiguration);
};

export const compressLiquid = (content) => content.replace(/\n|\t/gm, "");

export const beautifyLiquid = (content) => {
  let stack = 0;

  const splitFields = content
    .replace(/{%/gm, `\n{%`)
    .split("\n")
    .filter((x) => !x.match(/^\s*$/));
  const newField = splitFields
    .map((line) => {
      if (line.match("els") && stack > 0) return "\t".repeat(stack - 1) + line;

      if (line.match(/{%.*(endcase|endfor|endif|endunless|endcomment|endraw).*%}/)) {
        if (stack > 0) stack -= 1;
        return "\t".repeat(stack) + line;
      }

      if (line.match(/{%.*(case|for|if|unless|comment|raw).*%}/)) {
        stack += 1;
        return "\t".repeat(stack - 1) + line;
      }

      return "\t".repeat(stack) + line;
    })
    .join("\n");

  return newField;
};

function LiquidEditorModal({ content, open, setOpen, onSave, error = null }) {
  const editorRef = useRef(null);

  const compress = useCallback(() => editorRef.current.setValue(compressLiquid(editorRef.current.getValue())), []);
  const beautify = useCallback(() => editorRef.current.setValue(beautifyLiquid(editorRef.current.getValue())), []);

  useEffect(() => {
    editorRef.current = content;
  }, [content]);

  return (
    <Modal
      large
      open={open}
      onClose={() => setOpen(false)}
      title="Edit Code"
      primaryAction={{
        content: "OK",
        onAction: () => {
          if (editorRef.current) {
            onSave(editorRef.current.getValue());
          }
          setOpen(false);
        },
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: () => setOpen(false),
        },
      ]}
    >
      {error !== null && (
        <Modal.Section>
          <Banner status="warning">
            {}
            <p>{`${error.error} on line ${error.line} starting at character ${error.column}`}</p>
            {/* <pre>{JSON.stringify(liquidError, null, 2)}</pre> */}
          </Banner>
        </Modal.Section>
      )}
      <Modal.Section>
        <Stack vertical>
          <Stack>
            <ButtonGroup segmented>
              <Button size="slim" onClick={compress}>
                Compress
              </Button>
              <Button size="slim" onClick={beautify}>
                Beautify
              </Button>
            </ButtonGroup>
          </Stack>
          <div
            style={{
              marginBottom: "2rem",
            }}
          >
            <Editor
              height="40rem"
              language="liquid"
              defaultValue={content}
              onMount={(editor) => {
                editorRef.current = editor;
              }}
              editorWillMount={addLiquidLanguage}
            />
          </div>
        </Stack>
      </Modal.Section>
    </Modal>
  );
}

export default LiquidEditorModal;
