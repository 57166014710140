import React, { useMemo } from "react";
import { FormLayout, ChoiceList, TextStyle } from "@shopify/polaris";
import LiquidField from "../LiquidField";

function LiquidRowOptions({ liquidRow, setLiquidRow, objectFields = [], liquidRowContext }) {
  const liquidRowTitle = useMemo(() => {
    const [, id] = /^(.*?)-liquid_row_/.exec(liquidRow.id) || [];
    return objectFields.find((field) => field.id === id)?.name || id || liquidRow.id;
  }, [objectFields, liquidRow.id]);

  return (
    <FormLayout>
      <TextStyle>Context: {liquidRowContext}</TextStyle>
      <FormLayout.Group>
        <FormLayout.Group>
          <ChoiceList
            title="Placement"
            choices={[
              { label: `Above ${liquidRowTitle} Rows`, value: "above" },
              { label: `Below ${liquidRowTitle} Rows`, value: "below" },
            ]}
            selected={liquidRow.position}
            onChange={(value) => {
              setLiquidRow({ ...liquidRow, position: value });
            }}
          />
        </FormLayout.Group>
        <LiquidField
          label="Repeat"
          value={liquidRow.repeat}
          onChange={(value) => {
            setLiquidRow({ ...liquidRow, repeat: value });
          }}
        />
        <LiquidField
          label="Condition"
          value={liquidRow.condition}
          onChange={(value) => {
            setLiquidRow({ ...liquidRow, condition: value });
          }}
        />
      </FormLayout.Group>
    </FormLayout>
  );
}

export default LiquidRowOptions;
