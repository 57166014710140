import React from "react";
import { FormLayout, TextField, Select, Label, Stack, TextStyle, Collapsible, Subheading } from "@shopify/polaris";
import { UpgradeBadge, FeatureGate } from "../utils/adminFrontend";

const ENCRYPTION_TYPES = [
  { value: "no-encryption", label: "No Encryption" },
  { value: "gnupgp", label: "PGP Encryption" },
];

function Encryption({ value, onChange }) {
  const {
    encryptionType,
    encryptionRecipientId = null,
    encryptionFilename = null,
    encryptionPublicKey = null,
  } = value || {
    encryptionType: "no-encryption",
  };

  const update = (data) => {
    onChange({ ...value, ...data });
  };

  return (
    <FeatureGate
      featureName="encryption"
      featureValue
      render={(state) => (
        <Stack vertical>
          <Subheading>Encryption</Subheading>
          <FormLayout>
            <Select
              label={
                <Stack spacing="extraLoose">
                  <Label>
                    <TextStyle variation={state?.show ? "subdued" : null}>Encryption</TextStyle>
                  </Label>
                  <UpgradeBadge asPopover />
                </Stack>
              }
              disabled={state?.show}
              options={ENCRYPTION_TYPES}
              onChange={(data) => {
                if (data === "no-encryption") {
                  onChange(null);
                } else {
                  onChange({ ...value, ...{ encryptionType: data } });
                }
              }}
              value={encryptionType}
            />
            <Collapsible open={encryptionType !== "no-encryption"} transition={{ duration: "500ms", timingFunction: "ease-in-out" }}>
              <TextField
                label="Recipient ID"
                monospaced
                value={encryptionRecipientId}
                onChange={(data) => {
                  update({ encryptionRecipientId: data });
                }}
                error={encryptionRecipientId?.trim().length === 0 ? "Can't be empty" : null}
                disabled={encryptionType === "no-encryption"}
              />
              <TextField
                label="Recipient Public Key"
                multiline
                monospaced
                value={encryptionPublicKey}
                onChange={(data) => {
                  update({ encryptionPublicKey: data });
                }}
                error={encryptionPublicKey?.trim().length === 0 ? "Can't be empty" : null}
                disabled={encryptionType === "no-encryption"}
              />
              <TextField
                label="Filename (optional)"
                value={encryptionFilename}
                onChange={(data) => {
                  update({ encryptionFilename: data });
                }}
                disabled={encryptionType === "no-encryption"}
              />
            </Collapsible>
          </FormLayout>
        </Stack>
      )}
    />
  );
}

export default Encryption;
