import React, { createContext, useContext, useCallback, useReducer, useEffect } from "react";
import { Toast } from "@shopify/polaris";

const DEBUG = Symbol("DEBUG");
const CLICK = Symbol("CLICK");
const DISMISS_TOAST = Symbol("DISMISS_TOAST");

const INITIAL_STATE = {
  debugMode: false,
  clicksLeft: 5,
  toastVisible: false,
};

const debugReducer = (last, { action }) => {
  const next = { ...last };

  switch (action) {
    case DEBUG:
      next.clicksLeft = 0;
      next.debugMode = true;
      break;
    case CLICK:
      next.clicksLeft = Math.max(0, last.clicksLeft - 1 || 0);
      next.debugMode = next.clicksLeft === 0;
      next.toastVisible = next.clicksLeft <= 1 && next.clicksLeft > 0;
      break;
    case DISMISS_TOAST:
      next.toastVisible = false;
      break;
    default:
    // nothing
  }

  return next;
};

const DebugContext = createContext(null);

function DebugMode({ children }) {
  const [{ debugMode, clicksLeft, toastVisible }, dispatch] = useReducer(debugReducer, INITIAL_STATE);
  const debugModeClick = useCallback(() => dispatch({ action: CLICK }), []);
  const dismissToast = useCallback(() => dispatch({ action: DISMISS_TOAST }), []);

  const contextPayload = {
    debugMode,
    clicksLeft,
    debugModeClick,
  };

  useEffect(() => {
    window.xporterDebug = () => dispatch({ action: DEBUG });

    return () => {
      delete window.xporterDebug;
    };
  }, []);

  return (
    <DebugContext.Provider value={contextPayload}>
      {children}
      {toastVisible ? (
        <Toast
          content={`${clicksLeft} ${clicksLeft === 1 ? "click" : "clicks"} left until you are in debug mode`}
          onDismiss={dismissToast}
        />
      ) : null}
    </DebugContext.Provider>
  );
}

export default DebugMode;
export const useIsDebugMode = () => useContext(DebugContext).debugMode;
export const useDebugClicksLeft = () => useContext(DebugContext).clicksLeft;
export const useDebugModeClick = () => useContext(DebugContext).debugModeClick;
