/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { Modal, Stack, Link } from "@shopify/polaris";
import ReportFormat from "./ReportFormat";
import ReportTweaks from "./ReportTweaks";
import Encryption from "./Encryption";
import ReportKeeps from "./ReportKeeps";
import { useReportTemplate } from "../../TemplateContext";

function TemplateSettingsModal({ open, toggleOpen }) {
  const [{ keeps, reportFormat, reportTweaks, reportEval }, dispatch, { objectFields, objectsIncluded, reportFieldManyObjects }] =
    useReportTemplate();

  // object states
  const setKeeps = (value) => dispatch({ type: "merge", value: { keeps: value } });
  const setReportFormat = (value) => dispatch({ type: "merge", value: { reportFormat: value } });
  const setReportTweaks = (value) => dispatch({ type: "merge", value: { reportTweaks: value } });

  return (
    <>
      <Modal
        sectioned
        open={open}
        onClose={toggleOpen}
        title={
          <>
            <span style={{ display: "inline-flex" }}>Formatting Options</span>
            <span style={{ fontSize: "0.5em", float: "right" }}>
              <Link
                external
                href="https://support.moddapps.com/hc/en-us/articles/4415607428627"
                url="https://support.moddapps.com/hc/en-us/articles/4415607428627"
              >
                Learn More
              </Link>
            </span>
          </>
        }
        primaryAction={{
          content: "Close",
          onAction: toggleOpen,
        }}
      >
        <Stack vertical>
          <ReportFormat
            value={reportFormat}
            onChange={setReportFormat}
            objectList={reportFieldManyObjects}
            objectFields={objectFields}
          />
          {!reportEval && (
            <>
              <ReportKeeps objectFields={objectFields} objectsIncluded={objectsIncluded} keeps={keeps} setKeeps={setKeeps} />
              <ReportTweaks value={reportTweaks} topLevelItem={reportFormat.top_level_item} onChange={setReportTweaks} />
            </>
          )}
          <Encryption value={reportFormat.encryption} onChange={(data) => setReportFormat({ ...reportFormat, encryption: data })} />
        </Stack>
      </Modal>
    </>
  );
}

export default TemplateSettingsModal;
