import React, { useEffect } from "react";
import { RouteManager, ContentForRoute } from "./RouteManager";
import AppFrame from "./AppFrame";
import { AuthProvider, useProfile, useFetch, useRedirect } from "./utils";

const shouldRedirectOAuth = (profile, profileError, shopOrigin) => shopOrigin && !profile?.user?.watchtower && (profile?.shop?.scope_dirty || profileError?.error === "user_session_expected" || profileError?.error === "missing_or_invalid_jwt" || profileError?.error === "current_shopify_session_blank");

const shouldRedirectSubscription = (profile, profileError, shopOrigin) => shopOrigin && profile && profile.shop.active_subscription == null;

function AuthCheck({ shopOrigin, onProfileIsKnown }) {
  const [profile, profileError] = useProfile();
  const authFetch = useFetch();
  const redirect = useRedirect();

  useEffect(() => {
    if (shouldRedirectOAuth(profile, profileError, shopOrigin)) {
      const oauthLoginUrl = "/api/login";
      authFetch(`${oauthLoginUrl}?shop=${shopOrigin}`)
        .then((r) => r.json())
        .then(({ url }) => {
          redirect(url);
        });
    }

    const accountUrl = `/account`;
    if (shouldRedirectSubscription(profile, profileError, shopOrigin) && !window.location.pathname.match(accountUrl)) {
      redirect(`${accountUrl}`);
    }

    if (typeof onProfileIsKnown === "function") {
      onProfileIsKnown(profile, authFetch); // pass back authFetch
    }
  }, [authFetch, onProfileIsKnown, profile, profileError, redirect, shopOrigin]);
  return null;
}

export function App({ urlToken, routes, globalContextComponent: GlobalContextComponent, onProfileIsKnown, shopOrigin, children }) {
  const embedded = !!window.shopify;

  return (
    <AuthProvider embedded={embedded} urlToken={urlToken}>
      <RouteManager routes={routes}>
        <GlobalContextComponent>
          <AppFrame embedded={embedded}>
            <ContentForRoute />
            {children}
          </AppFrame>
        </GlobalContextComponent>
        <AuthCheck onProfileIsKnown={onProfileIsKnown} shopOrigin={shopOrigin} />
      </RouteManager>
    </AuthProvider>
  );
}

export default null;
