import { useEffect, useMemo, useRef } from "react";

export { App } from "./adminFrontend/App";
export { default as Subscriptions } from "./adminFrontend/components/Subscriptions";
export { default as InstallPage } from "./adminFrontend/components/InstallPage";
export { default as useUtilityFetch } from "./adminFrontend/useUtilityFetch";
export { default as useRedirectFetch, redirectIfNecessary } from "./adminFrontend/useRedirectFetch";

export { useRedirect, Link, Redirect } from "./adminFrontend/utils";
// contexts
export { default as Auth, useFetch, useProfile } from "./adminFrontend/utils";
export { useCreateModal, useCreateToast } from "./adminFrontend/contexts/OverlayManager";
export { FeatureGate, UpgradeBadge, UpgradeButton, UpgradeBanner, UpgradePopover, UpgradeLink, FeatureGateChoiceList, useFeatureContext } from "./adminFrontend/contexts/FeatureContext";
export { SubscriptionContext } from "./adminFrontend/contexts/SubscriptionContext";

// components
export { default as AsyncButton } from "./adminFrontend/components/AsyncButton";

export function ResourcePicker({ open, resourceType, initialSelectionIds, onCancel, onSelection }) {
  const isOpenRef = useRef(false);

  const [type, filter] = useMemo(() => {
    if (resourceType === "Product") return ["product", { showVariants: false }];
    if (resourceType === "ProductVariant") return ["variant", {}];
    if (resourceType === "Collection") return ["collection", {}];
    return [];
  }, [resourceType]);

  useEffect(() => {
    if (open && type && !isOpenRef.current) {
      isOpenRef.current = true;
      window.shopify
        .resourcePicker({
          type,
          action: "select",
          filter,
          multiple: false,
          selectionIds: initialSelectionIds,
        })
        .then((selection) => onSelection({ selection }), onCancel)
        .finally(() => {
          isOpenRef.current = false;
        });
    }
  }, [open, initialSelectionIds, onSelection, onCancel, type, filter]);

  return null;
}
