export const AGGREGATE_VALUES = [
  { value: "", label: "None" },
  { value: "anchor", label: "Group By" },
  { value: "first", label: "First" },
  { value: "last", label: "Last" },
  { value: "sum", label: "Sum" },
  { value: "count", label: "Count" },
  { value: "average", label: "Average" },
  { value: "join", label: "Join", hasOperand: true },
];

export const FILE_FORMATS = [
  { value: "csv", label: "CSV Spreadsheet" },
  { value: "excel", label: "Excel Spreadsheet" },
  { value: "text", label: "Text File" },
  { value: "xml", label: "XML File" },
  { value: "pdf", label: "PDF File" },
  { value: "html", label: "HTML File" },
];

export const QUOTE_BEHAVIORS = [
  { value: "normal", label: "As required" },
  { value: "always", label: "All fields" },
];

export const ROW_FORMATS = [
  { value: "shopify", label: "Shopify Row Format" },
  { value: "flat", label: "Flat Row Format" },
];

export const ENCODINGS = [
  { value: "UTF-8", label: "UTF-8" },
  { value: "UTF-16", label: "UTF-16" },
  { value: "ASCII", label: "ASCII (ISO 646)" },
  { value: "shiftjis", label: "Shift JIS" },
  { value: "ISO8859-1", label: "ISO 8859-1" },
  { value: "ISO8859-2", label: "ISO 8859-2" },
  { value: "ISO8859-3", label: "ISO 8859-3" },
  { value: "ISO8859-4", label: "ISO 8859-4" },
  { value: "ISO8859-5", label: "ISO 8859-5" },
  { value: "ISO8859-6", label: "ISO 8859-6" },
  { value: "ISO8859-7", label: "ISO 8859-7" },
  { value: "ISO8859-8", label: "ISO 8859-8" },
  { value: "ISO8859-9", label: "ISO 8859-9" },
  { value: "ISO8859-10", label: "ISO 8859-10" },
  { value: "ISO8859-11", label: "ISO 8859-11" },
  { value: "ISO8859-12", label: "ISO 8859-12" },
  { value: "ISO8859-13", label: "ISO 8859-13" },
  { value: "ISO8859-14", label: "ISO 8859-14" },
  { value: "ISO8859-15", label: "ISO 8859-15" },
  { value: "ISO8859-16", label: "ISO 8859-16" },
  { value: "Windows-1251", label: "Windows-1251" },
  { value: "Windows-1252", label: "Windows-1252" },
  { value: "Windows-1253", label: "Windows-1253" },
  { value: "Windows-1254", label: "Windows-1254" },
  { value: "Windows-1255", label: "Windows-1255" },
  { value: "Windows-1256", label: "Windows-1256" },
  { value: "Windows-1257", label: "Windows-1257" },
  { value: "Windows-1258", label: "Windows-1258" },
  { value: "CP437", label: "Code Page 437" },
];

export const DATE_FORMATS = [
  { value: "%Y-%m-%dT%H:%M:%S", label: "YYYY-MM-DDTHH:MM:SS (IS08601 Compliant)" },
  { value: "%Y-%m-%d %H:%M:%S", label: "YYYY-MM-DD HH:MM:SS (ISO8601 Compliant)" },
  { value: "%Y-%m-%dT%H:%M:%S%z", label: "YYYY-MM-DDTHH:MM:SS+TZTZ (IS08601 Compliant)" },
  { value: "%y-%m-%d %H:%M:%S", label: "YY-MM-DD HH:MM:SS" },
  { value: "%B %e, %Y %H:%M", label: "Month Day, Year HH:MM" },
  { value: "%B %e, %Y", label: "Month Day, Year" },
  { value: "%m/%d/%Y", label: "MM/DD/YYYY" },
  { value: "%H:%M:%S", label: "HH:MM:SS" },
  { value: "Custom", label: "Custom" },
];

export const CURRENCY_FORMATS = [
  { value: "{{ amount }}", label: "No Currency Format" },
  { value: "$ {{ amount }}", label: "$" },
  { value: "€ {{ amount }}", label: "€" },
  { value: "£ {{ amount }}", label: "£" },
  { value: "¥ {{ amount }}", label: "¥" },
  { value: "Custom", label: "Custom" },
];

export const FIELD_QUERY_TYPES = [
  {
    type: "qualifier",
    operators: [
      { value: "is", label: "is" },
      { value: "is not", label: "is not" },
      { value: "contains", label: "contains" },
      { value: "does not contain", label: "excludes" },
      { value: "regex", label: "regex" },
      { value: "not regex", label: "not regex" },
      { value: "is null", label: "is null" },
      { value: "is not null", label: "is not null" },
    ],
    operandType: "text",
  },
  {
    type: "quantifier",
    operators: [
      { value: ">", label: ">" },
      { value: "<", label: "<" },
      { value: ">=", label: ">=" },
      { value: "<=", label: "<=" },
      { value: "==", label: "==" },
      { value: "!=", label: "!=" },
      { value: "is null", label: "is null" },
      { value: "is not null", label: "is not null" },
    ],
    operandType: "number",
  },
  {
    type: "datetime",
    operators: [
      { value: "Within Date Range", label: "Within Date Range" },
      { value: "After Date", label: "After Start Date" },
      { value: "Before Date", label: "Before End Date" },
      { value: ">=", label: ">=" },
      { value: "<=", label: "<=" },
      { value: "is null", label: "is null" },
      { value: "is not null", label: "is not null" },
    ],
    operandType: "datetime-local",
  },
  {
    type: "select",
    operators: [
      { value: "is", label: "is" },
      { value: "is not", label: "is not" },
      { value: "is null", label: "is null" },
      { value: "is not null", label: "is not null" },
    ],
    operandType: "select",
  },
  {
    type: "boolean",
    operators: [
      { value: "is", label: "is" },
      { value: "is null", label: "is null" },
      { value: "is not null", label: "is not null" },
    ],
    operandType: "select",
    operandValues: ["true", "false"],
  },
  {
    type: "liquid",
    operators: [{ value: "evaluates the following to be true", label: "evaluates the following to be true" }],
    operandType: "text",
  },
  {
    type: "exact",
    operators: [
      { value: "is", label: "is" },
      { value: "is null", label: "is null" },
      { value: "is not null", label: "is not null" },
    ],
    operandType: "text",
  },
  {
    type: "collection",
    operandType: "text",
    operators: [{ value: "in", label: "in" }],
  },
  {
    type: "product_id",
    operators: [
      { value: ">", label: ">" },
      { value: "<", label: "<" },
      { value: ">=", label: ">=" },
      { value: "<=", label: "<=" },
      { value: "==", label: "==" },
      { value: "!=", label: "!=" },
      { value: "is null", label: "is null" },
      { value: "is not null", label: "is not null" },
    ],
    operandType: "number",
  },
  {
    type: "variant_id",
    operators: [
      { value: ">", label: ">" },
      { value: "<", label: "<" },
      { value: ">=", label: ">=" },
      { value: "<=", label: "<=" },
      { value: "==", label: "==" },
      { value: "!=", label: "!=" },
      { value: "is null", label: "is null" },
      { value: "is not null", label: "is not null" },
    ],
    operandType: "number",
  },
];

export const SORT_DIRECTIONS = [
  { value: "asc", label: "Ascending (A-Z)" },
  { value: "desc", label: "Descending (Z-A)" },
];

export const PROMOTED_OBJECTS = [
  {
    id: "order",
    name: "Order",
    description:
      "Includes orders, refunds, fulfillments, discounts, taxes, line items, line item properties, order notes, transactions, risks, customers and other order data",
  },
  {
    id: "product",
    name: "Product",
    description: "Report on product information including variants, tags, metafields, inventory, prices and images.",
  },
  { id: "customer", name: "Customer", description: "Customer information including tags, metafields, addresses, and sales data." },
  { id: "article", name: "Article", description: null },
  { id: "blog", name: "Blog", description: null },
  { id: "checkout", name: "Checkout", description: null },
  { id: "country", name: "Country", description: null },
  { id: "draft_order", name: "Draft Order", description: null },
  { id: "event", name: "Event", description: null },
  { id: "location", name: "Location", description: null },
  { id: "page", name: "Page", description: null },
  { id: "price_rule", name: "Price Rule", description: null },
  { id: "redirect", name: "Redirect", description: null },
  { id: "shipping_zone", name: "Shipping Zone", description: null },
  { id: "shop", name: "Shop", description: null },
];

export const LIQUID_FILTER_OPERATORS = ["evaluates the following to be true", "evaluates the following to true"];
