import React, { useState, useMemo } from "react";
import { Modal, DisplayText, Select, Stack, Banner, Subheading, ChoiceList, Link } from "@shopify/polaris";
import Editor from "@monaco-editor/react";

import { useReportTemplate } from "../../TemplateContext";
import { addLiquidLanguage } from "../LiquidEditorModal";
import { useXporterApi } from "../../ApiProvider";

function LiquidRowModal({ liquidRow = {}, ...modalProps }) {
  const [{ reportLiquidRows, mainItem }, dispatch, { objectFields, reportFieldManyObjects }] = useReportTemplate();

  const setReportLiquidRows = (value) => dispatch({ type: "merge", value: { reportLiquidRows: value } });

  const xporterAPI = useXporterApi();
  const [error, setError] = useState(null);
  const [timeoutId, setTimeoutId] = useState(null);
  const [savingSpinner, setSavingSpinner] = useState(false);

  const [liquidPosition, setLiquidPosition] = useState(liquidRow.position ?? "below");
  const [liquidRepeat, setLiquidRepeat] = useState(liquidRow.repeat ?? "1");
  const [liquidCondition, setLiquidCondition] = useState(liquidRow.condition ?? "");
  const [liquidId, setLiquidId] = useState(liquidRow.id ?? mainItem);

  const liquidRowTitle = useMemo(() => objectFields.find((field) => field.id === liquidId)?.name, [objectFields, liquidId]);

  const liquidRowContexts = useMemo(
    () =>
      objectFields
        .filter(({ mainObject, relation }) => reportFieldManyObjects.find((x) => x === mainObject) && relation)
        .map(({ id, name, displayPath }) => ({ id, value: id, label: displayPath ? [...displayPath, name].join(" > ") : name })),
    [objectFields, reportFieldManyObjects]
  );

  const waitAndValidate = (newValue) => {
    setSavingSpinner(true);
    if (timeoutId !== null) {
      clearTimeout(timeoutId);
    }
    setTimeoutId(
      setTimeout(async () => {
        const liquidError = await xporterAPI.validateLiquid(newValue);
        setError(liquidError);
        setTimeoutId(null);
        setSavingSpinner(false);
      }, 1000)
    );
  };

  const save = async () => {
    setSavingSpinner(true);

    setReportLiquidRows([
      ...reportLiquidRows,
      {
        id: `${liquidId}-liquid_row_${+new Date()}`,
        condition: liquidCondition,
        contents: [],
        position: liquidPosition,
        repeat: liquidRepeat,
      },
    ]);

    modalProps.onClose();
    setSavingSpinner(false);
  };

  return (
    <Modal
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...modalProps}
      large
      primaryAction={{ content: "Save", onAction: save, loading: savingSpinner, disabled: error }}
      secondaryActions={[
        {
          content: "Discard",
          destructive: true,
          onAction: () => {
            setLiquidPosition(liquidRow.position ?? "below");
            setLiquidRepeat(liquidRow.repeat ?? "1");
            setLiquidCondition(liquidRow.condition ?? "");
            setLiquidId(liquidRow.id ?? mainItem);
            modalProps.onClose();
          },
        },
      ]}
    >
      <Modal.Section>
        <DisplayText>Custom Field</DisplayText>
        <Stack>
          {!liquidRow.id && (
            <Select label="Context for Liquid Row" options={liquidRowContexts} value={liquidId} onChange={setLiquidId} />
          )}

          <Stack.Item>
            <ChoiceList
              title="Placement"
              choices={[
                { label: `Above ${liquidRowTitle} Rows`, value: "above" },
                { label: `Below ${liquidRowTitle} Rows`, value: "below" },
              ]}
              selected={liquidPosition}
              onChange={(value) => {
                setLiquidPosition(value);
              }}
            />
          </Stack.Item>
          <Stack.Item>
            <Link
              external
              href="https://support.moddapps.com/hc/en-us/articles/4407290170131"
              url="https://support.moddapps.com/hc/en-us/articles/4407290170131"
            >
              Learn more
            </Link>
          </Stack.Item>
        </Stack>
      </Modal.Section>
      {error !== null && (
        <Modal.Section>
          <Banner status="warning">
            <p>In order to save this field, please correct the above errors</p>
            <hr />
            <p>{`${error.error} on line ${error.line} starting at character ${error.column}`}</p>
            {/* <pre>{JSON.stringify(liquidError, null, 2)}</pre> */}
          </Banner>
          <br />
        </Modal.Section>
      )}
      <Modal.Section>
        <Subheading>Repeat</Subheading>
        <Editor
          language="liquid"
          value={liquidRepeat}
          onChange={(newValue) => {
            waitAndValidate(newValue);
            setLiquidRepeat(liquidRepeat);
          }}
          height="6rem"
          // onMount={(editor) => {
          //   editorRef.current = editor;
          // }}
          editorWillMount={addLiquidLanguage}
        />
      </Modal.Section>
      <Modal.Section>
        <Subheading>Conditions</Subheading>
        <Editor
          language="liquid"
          value={liquidCondition}
          onChange={(newValue) => {
            waitAndValidate(newValue);
            setLiquidCondition(newValue);
          }}
          height="30rem"
          // onMount={(editor) => {
          //   editorRef.current = editor;
          // }}
          editorWillMount={addLiquidLanguage}
        />
      </Modal.Section>
    </Modal>
  );
}

export default LiquidRowModal;
