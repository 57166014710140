import React, { useState, useCallback, useMemo } from "react";
import { Card, Select, Stack, Button, Popover, ActionList, Subheading, Tooltip } from "@shopify/polaris";
import { DeleteMajor } from "@shopify/polaris-icons";

import { SORT_DIRECTIONS } from "./values";

import { useReportTemplate } from "../../TemplateContext";

function ReportPreSortingItem({ category, reportPreSortingCategory, objectFields, onChange }) {
  const name = useMemo(() => objectFields.find((objectField) => objectField.id === category)?.name, [category, objectFields]);
  const fields = useMemo(
    () =>
      objectFields.filter(
        (objectField) =>
          !objectField.relation &&
          objectField.id.startsWith(`${category}-`) &&
          objectField.id.replace(`${category}-`, "").split("-").length === 1
      ),
    [category, objectFields]
  );
  return (
    <Stack vertical>
      <Subheading>{name}</Subheading>

      <Select
        label="Sort By"
        options={fields.map((innerField) => ({ label: innerField.name, value: innerField.id }))}
        onChange={(value) => onChange({ ...reportPreSortingCategory, field: value })}
        value={reportPreSortingCategory?.field || ""}
      />

      <Stack wrap={false} alignment="trailing" spacing="extraTight">
        <Stack.Item fill>
          <Select
            label="Direction"
            options={SORT_DIRECTIONS}
            onChange={(value) => onChange({ ...reportPreSortingCategory, direction: value })}
            value={reportPreSortingCategory?.direction || ""}
          />
        </Stack.Item>

        <Stack.Item>
          <Tooltip content="Remove this Field">
            <Button icon={DeleteMajor} onClick={() => onChange(null)} />
          </Tooltip>
        </Stack.Item>
      </Stack>
    </Stack>
  );
}

function TemplatePreSorting() {
  const [active, setActive] = useState(false);
  const toggleActive = useCallback(() => setActive(!active), [active]);

  const [{ reportFields, reportPreSorting = {} }, dispatch, { objectFields = [] }] = useReportTemplate();
  const setReportPreSorting = (value) => dispatch({ type: "merge", value: { reportPreSorting: value } });

  const reportPreSortingExcludedItems = useMemo(
    () =>
      [...new Set(reportFields.map((field) => field.id.replace(/-[^-]*$/, "")))]
        .filter((category) => !reportPreSorting[category])
        .map((fieldId) => objectFields.find((field) => field.id === fieldId))
        .filter((excludedItem) => excludedItem),
    [reportFields, reportPreSorting, objectFields]
  );

  return (
    <Card sectioned title="Input Sorting">
      <Stack vertical>
        {Object.entries(reportPreSorting).map(([category, reportPreSortingCategory]) => (
          <ReportPreSortingItem
            key={category}
            category={category}
            reportPreSortingCategory={reportPreSortingCategory}
            objectFields={objectFields}
            onChange={(value) => {
              const { [category]: _, ...rest } = reportPreSorting;
              if (value) {
                setReportPreSorting({ ...rest, [category]: value });
              } else {
                setReportPreSorting(rest);
              }
            }}
          />
        ))}
        <Stack distribution="trailing">
          <Popover
            active={active}
            onClose={toggleActive}
            activator={
              <Button primary disclosure disabled={reportPreSortingExcludedItems.length === 0} onClick={toggleActive}>
                Add Sort Key
              </Button>
            }
          >
            <ActionList
              items={reportPreSortingExcludedItems.map((excludedItem) => ({
                content: excludedItem.name,
                onAction() {
                  const firstFieldInCategory = objectFields.find(
                    (objectField) => objectField.id.replace(/-[^-]*$/, "") === excludedItem.id
                  );
                  setReportPreSorting({
                    ...reportPreSorting,
                    [excludedItem.id]: { active: true, field: firstFieldInCategory, direction: SORT_DIRECTIONS[0].value },
                  });
                },
              }))}
            />
          </Popover>
        </Stack>
      </Stack>
    </Card>
  );
}

export default TemplatePreSorting;
