import React from "react";

function FormatIcon({ format }) {
  switch (format) {
    case "html":
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 48 48">
          <path d="M48,21.94a.88.88,0,0,0-.06-.27l0-.09a1.07,1.07,0,0,0-.19-.28h0L42,15.59V10s0,0,0-.06a1.09,1.09,0,0,0-.05-.26s0-.06,0-.09a1.17,1.17,0,0,0-.19-.3l-9-9A1.32,1.32,0,0,0,32.42.1l-.1,0-.25,0H7A1,1,0,0,0,6,1V15.59L.29,21.29h0a1.07,1.07,0,0,0-.19.28l0,.09a1.31,1.31,0,0,0-.06.27S0,22,0,22V41a1,1,0,0,0,1,1H6v5a1,1,0,0,0,1,1H41a1,1,0,0,0,1-1V42h5a1,1,0,0,0,1-1V22S48,22,48,21.94ZM44.59,21H42V18.41Zm-6-12H33V3.41ZM8,2H31v8a1,1,0,0,0,1,1h8V21H8V2ZM6,18.41V21H3.41ZM40,46H8V42H40Zm6-6H2V23H46Z" />
          <path d="M7.21,26.45V31h5.22V26.45h1.41V37.23H12.43v-5H7.21v5H5.82V26.45ZM18.3,27.63H15V26.45h8v1.18H19.71v9.6H18.3Zm15,4.87c-.08-1.51-.17-3.32-.16-4.66h-.05c-.36,1.26-.81,2.61-1.36,4.1l-1.9,5.23H28.76L27,32c-.51-1.52-.95-2.91-1.25-4.19h0c0,1.34-.11,3.15-.21,4.77l-.29,4.62H23.91l.76-10.78h1.77l1.84,5.21c.45,1.33.82,2.52,1.09,3.64h.05c.27-1.09.66-2.28,1.14-3.64l1.92-5.21h1.77l.68,10.78H33.56Zm3.77-6.05h1.4v9.61h4.61v1.17h-6Z" />
        </svg>
      );
    case "pdf":
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 48 48">
          <path d="M48,21.94a.88.88,0,0,0-.06-.27l0-.09a1.07,1.07,0,0,0-.19-.28h0L42,15.59V10s0,0,0-.06a1.09,1.09,0,0,0-.05-.26s0-.06,0-.09a1.17,1.17,0,0,0-.19-.3l-9-9A1.32,1.32,0,0,0,32.42.1l-.1,0-.25,0H7A1,1,0,0,0,6,1V15.59L.29,21.29h0a1.07,1.07,0,0,0-.19.28l0,.09a1.31,1.31,0,0,0-.06.27S0,22,0,22V41a1,1,0,0,0,1,1H6v5a1,1,0,0,0,1,1H41a1,1,0,0,0,1-1V42h5a1,1,0,0,0,1-1V22S48,22,48,21.94ZM44.59,21H42V18.41Zm-6-12H33V3.41ZM8,2H31v8a1,1,0,0,0,1,1h8V21H8V2ZM6,18.41V21H3.41ZM40,46H8V42H40Zm6-6H2V23H46Z" />
          <path d="M11.74,26.57a16.45,16.45,0,0,1,2.67-.2,4.44,4.44,0,0,1,3,.89,2.88,2.88,0,0,1,.95,2.26,3.17,3.17,0,0,1-.83,2.3A4.46,4.46,0,0,1,14.25,33a4.76,4.76,0,0,1-1.12-.1v4.32H11.74Zm1.39,5.21a4.66,4.66,0,0,0,1.15.11c1.68,0,2.71-.82,2.71-2.31s-1-2.11-2.55-2.11a6.09,6.09,0,0,0-1.31.11Zm7.12-5.19a19.77,19.77,0,0,1,3-.22,6.17,6.17,0,0,1,4.37,1.34,5,5,0,0,1,1.52,3.87,5.73,5.73,0,0,1-1.55,4.2,6.65,6.65,0,0,1-4.77,1.55,22.43,22.43,0,0,1-2.53-.13Zm1.39,9.54a9.33,9.33,0,0,0,1.41.08c3,0,4.59-1.67,4.59-4.58,0-2.54-1.42-4.16-4.37-4.16a8,8,0,0,0-1.63.14Zm9.26-9.68h5.81v1.16H32.3V31.2h4.08v1.15H32.3v4.88H30.9Z" />
        </svg>
      );
    case "xml":
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 48 48">
          <path d="M48,21.94a.88.88,0,0,0-.06-.27l0-.09a1.07,1.07,0,0,0-.19-.28h0L42,15.59V10s0,0,0-.06a1.09,1.09,0,0,0-.05-.26s0-.06,0-.09a1.17,1.17,0,0,0-.19-.3l-9-9A1.32,1.32,0,0,0,32.42.1l-.1,0-.25,0H7A1,1,0,0,0,6,1V15.59L.29,21.29h0a1.07,1.07,0,0,0-.19.28l0,.09a1.31,1.31,0,0,0-.06.27S0,22,0,22V41a1,1,0,0,0,1,1H6v5a1,1,0,0,0,1,1H41a1,1,0,0,0,1-1V42h5a1,1,0,0,0,1-1V22S48,22,48,21.94ZM44.59,21H42V18.41Zm-6-12H33V3.41ZM8,2H31v8a1,1,0,0,0,1,1h8V21H8V2ZM6,18.41V21H3.41ZM40,46H8V42H40Zm6-6H2V23H46Z" />
          <path d="M16.35,37.23,15,34.85c-.56-.91-.91-1.51-1.25-2.13h0c-.3.62-.61,1.2-1.17,2.14l-1.29,2.37H9.62l3.3-5.45L9.75,26.45h1.62L12.79,29c.4.71.71,1.25,1,1.83h0c.31-.64.58-1.14,1-1.83l1.47-2.52h1.6L14.6,31.7,18,37.23ZM28.67,32.5c-.08-1.51-.18-3.32-.16-4.66h-.05c-.37,1.26-.82,2.61-1.36,4.1l-1.9,5.23H24.14L22.39,32c-.51-1.52-.94-2.91-1.24-4.19h0c0,1.34-.11,3.15-.2,4.77l-.29,4.62H19.29L20,26.45h1.78l1.84,5.21c.45,1.33.81,2.52,1.09,3.64h0c.28-1.09.66-2.28,1.14-3.64l1.92-5.21h1.78l.67,10.78H28.94Zm3.77-6.05h1.39v9.61h4.61v1.17h-6Z" />
        </svg>
      );
    case "text":
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 48 48">
          <path d="M48,21.94a.88.88,0,0,0-.06-.27l0-.09a1.07,1.07,0,0,0-.19-.28h0L42,15.59V10s0,0,0-.06a1.09,1.09,0,0,0-.05-.26s0-.06,0-.09a1.17,1.17,0,0,0-.19-.3l-9-9A1.32,1.32,0,0,0,32.42.1l-.1,0-.25,0H7A1,1,0,0,0,6,1V15.59L.29,21.29h0a1.07,1.07,0,0,0-.19.28l0,.09a1.31,1.31,0,0,0-.06.27S0,22,0,22V41a1,1,0,0,0,1,1H6v5a1,1,0,0,0,1,1H41a1,1,0,0,0,1-1V42h5a1,1,0,0,0,1-1V22S48,22,48,21.94ZM44.59,21H42V18.41Zm-6-12H33V3.41ZM8,2H31v8a1,1,0,0,0,1,1h8V21H8V2ZM6,18.41V21H3.41ZM40,46H8V42H40Zm6-6H2V23H46Z" />
          <path d="M14.51,27.63H11.22V26.45h8v1.18h-3.3v9.6h-1.4Zm12.16,9.6-1.38-2.38c-.56-.91-.91-1.51-1.25-2.13h0c-.3.62-.61,1.2-1.17,2.14l-1.3,2.37h-1.6l3.3-5.45-3.17-5.33h1.62L23.11,29c.4.71.71,1.25,1,1.83h0c.31-.64.58-1.14,1-1.83l1.47-2.52h1.6L24.92,31.7l3.36,5.53Zm5.4-9.6H28.79V26.45h8v1.18h-3.3v9.6H32.07Z" />
        </svg>
      );
    case "excel":
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 48 48">
          <path d="M48,21.94a.88.88,0,0,0-.06-.27l0-.09a1.07,1.07,0,0,0-.19-.28h0L42,15.59V10s0,0,0-.06a1.09,1.09,0,0,0-.05-.26s0-.06,0-.09a1.17,1.17,0,0,0-.19-.3l-9-9A1.32,1.32,0,0,0,32.42.1l-.1,0-.25,0H7A1,1,0,0,0,6,1V15.59L.29,21.29h0a1.07,1.07,0,0,0-.19.28l0,.09a1.31,1.31,0,0,0-.06.27S0,22,0,22V41a1,1,0,0,0,1,1H6v5a1,1,0,0,0,1,1H41a1,1,0,0,0,1-1V42h5a1,1,0,0,0,1-1V22S48,22,48,21.94ZM44.59,21H42V18.41Zm-6-12H33V3.41ZM8,2H31v8a1,1,0,0,0,1,1h8V21H8V2ZM6,18.41V21H3.41ZM40,46H8V42H40Zm6-6H2V23H46Z" />
          <path d="M13.85,32.18H9.66v3.88h4.67v1.17H8.26V26.45h5.83v1.16H9.66V31h4.19Zm2.89-2.69,1.11,1.66c.29.43.53.83.78,1.27h.05c.26-.47.51-.87.77-1.29l1.09-1.64h1.52l-2.64,3.74,2.72,4h-1.6L19.4,35.49c-.3-.45-.56-.88-.83-1.35h0c-.26.47-.53.88-.82,1.35L16.6,37.23H15.05l2.75-3.95-2.62-3.79Zm12,7.46a5.28,5.28,0,0,1-2.23.44,3.68,3.68,0,0,1-3.85-4,3.93,3.93,0,0,1,4.16-4.11,4.56,4.56,0,0,1,1.95.4l-.32,1.08a3.3,3.3,0,0,0-1.63-.36,2.68,2.68,0,0,0-2.74,2.93,2.65,2.65,0,0,0,2.69,2.89,4.11,4.11,0,0,0,1.73-.38Zm2.33-3.33a2.48,2.48,0,0,0,2.66,2.68,5.21,5.21,0,0,0,2.15-.39l.24,1a6.22,6.22,0,0,1-2.58.48,3.59,3.59,0,0,1-3.81-3.9c0-2.34,1.38-4.18,3.63-4.18,2.53,0,3.2,2.23,3.2,3.65,0,.29,0,.51,0,.66Zm4.13-1a2,2,0,0,0-2-2.29,2.33,2.33,0,0,0-2.16,2.29Zm3.1-6.74h1.41V37.23H38.34Z" />
        </svg>
      );
    case "csv":
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 48 48">
          <path d="M48,21.94a.88.88,0,0,0-.06-.27l0-.09a1.07,1.07,0,0,0-.19-.28h0L42,15.59V10s0,0,0-.06a1.09,1.09,0,0,0-.05-.26s0-.06,0-.09a1.17,1.17,0,0,0-.19-.3l-9-9A1.32,1.32,0,0,0,32.42.1l-.1,0-.25,0H7A1,1,0,0,0,6,1V15.59L.29,21.29h0a1.07,1.07,0,0,0-.19.28l0,.09a1.31,1.31,0,0,0-.06.27S0,22,0,22V41a1,1,0,0,0,1,1H6v5a1,1,0,0,0,1,1H41a1,1,0,0,0,1-1V42h5a1,1,0,0,0,1-1V22S48,22,48,21.94ZM44.59,21H42V18.41Zm-6-12H33V3.41ZM8,2H31v8a1,1,0,0,0,1,1h8V21H8V2ZM6,18.41V21H3.41ZM40,46H8V42H40Zm6-6H2V23H46Z" />
          <path d="M19.71,36.88a6.86,6.86,0,0,1-2.85.51,5,5,0,0,1-5.33-5.45,5.37,5.37,0,0,1,5.63-5.67,5.76,5.76,0,0,1,2.56.48l-.33,1.14a5,5,0,0,0-2.18-.45c-2.53,0-4.21,1.61-4.21,4.45,0,2.64,1.52,4.33,4.15,4.33a5.59,5.59,0,0,0,2.27-.44Zm1.55-1.34a4.94,4.94,0,0,0,2.49.7c1.43,0,2.26-.75,2.26-1.84s-.58-1.58-2-2.14c-1.76-.63-2.85-1.54-2.85-3.06,0-1.68,1.39-2.93,3.49-2.93A4.91,4.91,0,0,1,27,26.8l-.38,1.13a4.31,4.31,0,0,0-2.05-.51c-1.47,0-2,.88-2,1.62,0,1,.65,1.5,2.14,2.08,1.83.7,2.76,1.58,2.76,3.17s-1.24,3.1-3.78,3.1a5.66,5.66,0,0,1-2.76-.68Zm10.45,1.69L28.18,26.45h1.51l1.68,5.31c.46,1.46.86,2.77,1.15,4h0c.31-1.25.76-2.61,1.24-4l1.82-5.33H37.1L33.24,37.23Z" />
        </svg>
      );
    default:
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 48 48">
          <path d="M48,21.94a.88.88,0,0,0-.06-.27l0-.09a1.07,1.07,0,0,0-.19-.28h0L42,15.59V10s0,0,0-.06a1.09,1.09,0,0,0-.05-.26s0-.06,0-.09a1.17,1.17,0,0,0-.19-.3l-9-9A1.32,1.32,0,0,0,32.42.1l-.1,0-.25,0H7A1,1,0,0,0,6,1V15.59L.29,21.29h0a1.07,1.07,0,0,0-.19.28l0,.09a1.31,1.31,0,0,0-.06.27S0,22,0,22V41a1,1,0,0,0,1,1H6v5a1,1,0,0,0,1,1H41a1,1,0,0,0,1-1V42h5a1,1,0,0,0,1-1V22S48,22,48,21.94ZM44.59,21H42V18.41Zm-6-12H33V3.41ZM8,2H31v8a1,1,0,0,0,1,1h8V21H8V2ZM6,18.41V21H3.41ZM40,46H8V42H40Zm6-6H2V23H46Z" />
        </svg>
      );
  }
}

export default FormatIcon;
