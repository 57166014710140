/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { FormLayout, Checkbox, Stack, Subheading } from "@shopify/polaris";

function ReportKeeps({ objectsIncluded, objectFields, setKeeps, keeps }) {
  return objectsIncluded?.length > 0 ? (
    <Stack vertical>
      <Subheading>Keeps</Subheading>
      <FormLayout>
        <p>Keep these fields after filtering even if there are no subfields under them.</p>
        {objectsIncluded.map((objectId) => {
          const field = objectFields.find(({ id }) => id === objectId);
          return (
            <Checkbox
              key={objectId}
              label={[...field.displayPath, field.name].join(" > ")}
              checked={keeps.includes(objectId)}
              onChange={(checked) => setKeeps(checked ? [...keeps, objectId].sort() : keeps.filter((keep) => keep !== objectId))}
            />
          );
        })}
      </FormLayout>
    </Stack>
  ) : null;
}

export default ReportKeeps;
