import React, { useState, useCallback } from "react";
import { Button, Icon, Select, Stack, TextContainer, Popover, ActionList, Tooltip, Subheading, TextStyle } from "@shopify/polaris";
import { DragHandleMinor, DeleteMajor } from "@shopify/polaris-icons";

import { FeatureGate, UpgradeButton } from "../utils/adminFrontend";

import { SORT_DIRECTIONS } from "./values";
import DraggableResourceList from "../DraggableResourceList";
import { useReportTemplate } from "../../TemplateContext";

function TemplateSortingInContext({ lockPosition }) {
  const [{ reportFields, reportPostSorting = [] }, dispatch] = useReportTemplate();
  const setReportPostSorting = (value) => dispatch({ type: "merge", value: { reportPostSorting: value } });

  const [active, setActive] = useState(false);
  const toggleActive = useCallback(() => setActive(!active), [active]);
  const reportPostSortingExcludedItems = reportFields.filter(
    (field) => !reportPostSorting.some((sortOrder) => sortOrder.id === field.id)
  );

  return (
    <Stack spacing="loose" vertical>
      {reportPostSorting.length > 0 ? (
        <div style={{ marginTop: "-2rem" }}>
          <DraggableResourceList
            items={reportPostSorting.map((field, index) => ({
              id: field.id,
              item: (
                <div style={{ position: "relative", padding: "2rem 0 0" }}>
                  <Stack vertical spacing="extraTight">
                    <Stack.Item>
                      <Subheading>
                        {reportFields.find((innerField) => innerField.id === field.id)?.name || field.id} Direction
                      </Subheading>
                    </Stack.Item>
                    <Stack wrap={false} spacing="extraTight" alignment="center">
                      <Stack.Item>
                        <TextStyle variation="code">{index + 1}</TextStyle>
                      </Stack.Item>
                      <Stack.Item>
                        <Icon source={DragHandleMinor} color="subdued" />
                      </Stack.Item>
                      <Stack.Item fill>
                        <Select
                          label={`${reportFields.find((innerField) => innerField.id === field.id)?.name || field.id} Direction`}
                          labelHidden
                          options={SORT_DIRECTIONS}
                          onChange={(newDirection) =>
                            setReportPostSorting(
                              reportPostSorting.map((innerField, innerIndex) =>
                                innerIndex === index ? { ...innerField, direction: newDirection } : innerField
                              )
                            )
                          }
                          value={field.direction}
                        />
                      </Stack.Item>
                      <Stack.Item>
                        <Tooltip content="Remove this Field">
                          <Button
                            icon={DeleteMajor}
                            onClick={() =>
                              setReportPostSorting(reportPostSorting.filter((innerField, innerIndex) => innerIndex !== index))
                            }
                          />
                        </Tooltip>
                      </Stack.Item>
                    </Stack>
                  </Stack>
                </div>
              ),
            }))}
            onReorder={({ from, to }) => {
              const result = Array.from(reportPostSorting);
              const removed = result.splice(from, 1)[0];
              result.splice(to, 0, removed);
              setReportPostSorting(result);
            }}
            onBeforeDrag={lockPosition}
          />
        </div>
      ) : (
        <TextContainer>
          <p>Sort the columns in your report.</p>
        </TextContainer>
      )}
      <Stack distribution="trailing">
        <Popover
          active={active}
          onClose={toggleActive}
          activator={
            <UpgradeButton disclosure asPopover disabled={reportPostSortingExcludedItems.length === 0} onClick={toggleActive}>
              Add Sort Key
            </UpgradeButton>
          }
        >
          <ActionList
            items={reportPostSortingExcludedItems.map((excludedItem) => ({
              content: excludedItem.name,
              onAction() {
                setReportPostSorting([...reportPostSorting, { id: excludedItem.id, direction: "asc" }]);
              },
            }))}
          />
        </Popover>
      </Stack>
    </Stack>
  );
}

function TemplateSorting({ lockPosition }) {
  return (
    <FeatureGate featureName="sorting" featureValue>
      <TemplateSortingInContext lockPosition={lockPosition} />
    </FeatureGate>
  );
}

export default TemplateSorting;
