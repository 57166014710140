import deepEqual from "deep-equal";
import { fromPerlDate } from "./dateUtil";

export const DEFAULT_TEMPLATE = {
  name: "",
  description: "",
  reportFields: [],
  reportFilters: [],
  keeps: [],
  reportLiquidRows: [],
  reportGeneration: null,
  reportFormat: {
    row_format: "shopify",
    currency_format: "{{ amount }}",
    join_separators: {},
    type: "csv",
    quote_behavior: "normal",
    date_format: "%Y-%m-%dT%H:%M:%S",
    delimiter_line: "\\r\\n",
    join_parents: [],
    delimiter_cell: ",",
    top_level_item: null,
    encoding: "UTF-8",
    encryption: null,
  },
  mainItem: "",
  reportTweaks: {
    include_type: null,
    end_automatic: null,
    zero_order_totals: null,
    hide_column_names: null,
    include_shipping: null,
    line_item_quantity: null,
  },
  reportPostSorting: [],
  reportPreSorting: {},
  reportEval: null,
  reportSignature: null,
  xmlNamesSingular: {},
  xmlNamesPlural: {},
  xmlDoctype: null,
  trailingRowsSuperheader: [],
  trailingRowsHeader: [],
  trailingRowsFooter: [],
};

export const INITIAL_STATE = {
  lastLoaded: DEFAULT_TEMPLATE,
  current: DEFAULT_TEMPLATE,
  undoStack: [],

  objectsIncluded: [],
  reportFieldManyObjects: [],
  objectFields: [],
  queryFields: [],
  error: [],
  showError: false,
  topLevelItemTrigger: true,
  allObjects: [],
  unsavedChanges: false,
  shopifyData: null,
  selectedStickyCardTab: 0,
  modals: {
    settings: false,
  },
};

export const allTemplatesSorter = (
  templatesFromRequest = [],
  starterTemplatePrefix = "Starter Templates",
  rootStarterFolder = "Starter Templates - Customizing these templates will add them to My Templates",
  rootMerchantFolder = "My Custom Templates"
) =>
  templatesFromRequest.reduce(
    (accumulator, item) => {
      const [folder, name] = item.NAME.split("/", 2);
      let newHash = item.starter ? accumulator.default : accumulator.shop;
      let folderTitle = item.starter ? rootStarterFolder : rootMerchantFolder; /* defaults */

      /* if there's no folder then the template name is in folder variable */
      if (name) {
        folderTitle = item.starter ? `${starterTemplatePrefix} ${folder}`.trimStart() : folder;
      }

      const existingFolder = newHash[folderTitle] || [];

      newHash = {
        ...newHash,
        [folderTitle]: [...existingFolder, { ...item, NAME: name || folder }],
      };

      return item.starter
        ? { shop: accumulator.shop, default: newHash, shop_count: accumulator.shop_count }
        : { shop: newHash, default: accumulator.default, shop_count: accumulator.shop_count + 1 };
    },
    { shop: {}, default: {}, shop_count: 0 }
  );

const loadObject = (
  {
    name: reportName = null,
    description: newDescription,
    report_fields: newReportFields = [],
    queries: newQueries = [],
    computed_queries: newComputedQueries = [],
    keeps: newKeeps = [],
    report_liquid_rows: newReportLiquidRows = {},
    report_generation: newReportGeneration = null,
    format: newReportFormat = null,
    tweaks: newReportTweaks = {},
    report_sorting: newReportPostSorting = [],
    report_pre_sorting: newReportPreSorting = {},
    report_eval: newReportEval = null,
  },
  { profile }
) => {
  const newReportFilters = [...newQueries, ...newComputedQueries].map((query) => ({
    ...query,
    conditions: query.conditions.map((condition, index) => ({ ...condition, id: index })),
  }));
  const { footer = [], header = [], superheader = [] } = newReportTweaks?.trailing_rows || {};
  const convertedReportLiquidRows = Object.entries(newReportLiquidRows).map(([id, v]) => ({
    ...v,
    id,
    contents: v.contents.map((value, index) => ({ id: newReportFields[index].id, value })),
  }));
  const getCleanedName = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const isStarter = urlParams.get("starter") === "1";
    return isStarter ? reportName.split("/").slice(-1)[0] : reportName;
  };
  return {
    name: getCleanedName(),
    description: newDescription,
    reportFields: newReportFields,
    keeps: newKeeps,
    reportLiquidRows: convertedReportLiquidRows,
    reportFormat: newReportFormat,
    reportTweaks: newReportTweaks,
    reportGeneration: newReportGeneration
      ? { ...newReportGeneration, report_next_generation: fromPerlDate(newReportGeneration.report_next_generation, profile) }
      : null,

    trailingRowsFooter: footer.map((values, rowIndex) => ({
      id: `extrarow-f${rowIndex}-${+new Date()}`,
      contents: values.map((value, columnIndex) => ({ id: newReportFields[columnIndex].id, value })),
    })),
    trailingRowsHeader: header.map((values, rowIndex) => ({
      id: `extrarow-h${rowIndex}-${+new Date()}`,
      contents: values.map((value, columnIndex) => ({ id: newReportFields[columnIndex].id, value })),
    })),
    trailingRowsSuperheader: superheader.map((values, rowIndex) => ({
      id: `extrarow-s${rowIndex}-${+new Date()}`,
      contents: values.map((value, columnIndex) => ({ id: newReportFields[columnIndex].id, value })),
    })),

    mainItem: newReportFields[0]?.id.split("-")[0],
    reportFilters: newReportFilters,
    reportPostSorting: newReportPostSorting,
    reportPreSorting: newReportPreSorting,
    xmlNamesSingular: newReportFormat?.item_singular_names,
    xmlNamesPlural: newReportFormat?.item_plural_names,
    xmlDoctype: newReportFormat?.doctype,
    reportEval: newReportEval,
    reportSignature: null,
  };
};

function findLiquidFieldParent(fieldId) {
  if (!fieldId.includes("liquid")) {
    return null;
  }
  return `${fieldId.split("-").slice(0, -1).join("-")}-id`;
}

function findAllRelations(fieldIdentifier, objectFields, mainItem) {
  const fieldId = fieldIdentifier.includes("liquid") ? findLiquidFieldParent(fieldIdentifier) : fieldIdentifier;
  const field = objectFields.find((objectField) => fieldId === objectField.id);
  if (!field) {
    return [];
  }
  if (field.mainObject !== mainItem) {
    return [];
  }

  const relations = findAllRelations(field.parentRelation, objectFields, mainItem);
  return field.type !== "regular" && field.type !== "computed" ? [field, ...relations] : relations;
}

function findAllManyRelations(fieldIdentifier, objectFields, mainItem) {
  const fieldId = fieldIdentifier.includes("liquid") ? findLiquidFieldParent(fieldIdentifier) : fieldIdentifier;
  const field = objectFields.find((objectField) => fieldId === objectField.id);
  if (!field) {
    return [];
  }
  if (field.mainObject !== mainItem) {
    return [];
  }
  const manyRelations = findAllManyRelations(field.parentRelation, objectFields, mainItem);
  return field.type === "many" ? [fieldId, ...manyRelations] : manyRelations;
}

function columnIndexToExcel(index) {
  return (index < 26 ? "" : columnIndexToExcel(Math.floor(index / 26 - 1))) + String.fromCharCode(65 + (index % 26));
}

const snakeToCamel = (string) => string.replace(/_([a-z]?)/g, (_a, b) => b.toUpperCase());

const idToNodeName = (id, type) => {
  if (!id) return "";
  const idPath = id.split("-");

  let name = snakeToCamel(idPath.pop());
  let singular;
  let plural;

  name = name.replace(/([a-z])(?=[A-Z])/g, "$1 ");
  if (/address/i.test(name)) {
    singular = name;
    plural = `${name}es`;
  } else {
    singular = name.replace(/s$/, "").replace(/\s*/g, "");
    plural = `${singular}s`;
  }
  return type === "singular" ? singular : plural;
};

const loadRawFields = (rawObjectFields) => {
  const STOP_POINTS = ["order-customer", "order-billing_address", "order-shipping_address"];

  function displayParentOf(field, trailer = []) {
    const candidateParentField = rawObjectFields.find((subfield) => subfield.id === field.parentRelation);
    if (candidateParentField && candidateParentField.type === "one" && !STOP_POINTS.includes(candidateParentField.id)) {
      return displayParentOf(candidateParentField, [candidateParentField.name, ...trailer]);
    }
    return [candidateParentField, trailer];
  }

  return rawObjectFields.map((field) => {
    const [parentField, collapsedNames] = displayParentOf(field);
    return { ...field, displayParent: parentField ? parentField.id : field.parentRelation, collapsedNames };
  });
};

const sortFields = (object1, object2) => {
  let toReturn = 0;

  // Fields closer to the top are weighted higher
  toReturn += (object1.displayPath.length - object2.displayPath.length) * 1000;

  if (object1.id === "order") toReturn -= 100;
  if (object2.id === "order") toReturn += 100;

  if (object1.id === "order-line_items") toReturn -= 5;
  if (object2.id === "order-line_items") toReturn += 5;
  if (object1.id === "order-transactions") toReturn -= 4;
  if (object2.id === "order-transactions") toReturn += 4;
  if (object1.id === "order-fulfillments") toReturn -= 3;
  if (object2.id === "order-fulfillments") toReturn += 3;
  if (object1.id === "order-refunds") toReturn -= 2;
  if (object2.id === "order-refunds") toReturn += 2;

  return toReturn;
};

const getAllObjects = (objectFields, mainItem) => {
  const returnObjects = objectFields
    .filter((field) => field.type !== "regular" && field.type !== "computed" && field.mainObject === mainItem)
    .sort(sortFields)
    .map((field) => ({ label: [...field.displayPath, field.name].join(" > "), value: field.id }));

  return returnObjects || [];
};

const validateState = ({ name, mainItem, reportFields, reportFormat, reportFilters, reportEval }) => {
  const errors = [];

  if (name === undefined || (typeof name === "string" && name.length === 0)) {
    errors.push("Template Name is required.");
  }

  if (reportEval) {
    return [];
  }

  if (!mainItem) {
    errors.push("Primary object not selected");
  } else {
    if (reportFields.length === 0) {
      errors.push("No Fields were selected");
    }
    if (reportFormat?.encryption) {
      if (
        reportFormat.encryption.encryptionRecipientId === null ||
        reportFormat.encryption.encryptionRecipientId?.trim().length === 0
      ) {
        errors.push("In encrpytion, recipient id can't be empty");
      }
      if (
        reportFormat.encryption.encryptionPublicKey === null ||
        reportFormat.encryption.encryptionPublicKey?.trim().length === 0
      ) {
        errors.push("In encrpytion, public key can't be empty");
      }
    }
    reportFilters.forEach(({ id, conditions }) => {
      let haveError = false;
      conditions.forEach(({ operator, operand }) => {
        if (
          (!["Within Date Range", "After Date", "Before Date", "is null", "is not null"].includes(operator) &&
            !operand &&
            operand !== "") ||
          !operator
        ) {
          haveError = true;
        }
      });
      if ( haveError ) errors.push(`Error with Report Filter in ${id.split("-").join(" > ").toUpperCase()}`);
    });
  }
  return errors;
};

// given a field and operator this will set the date operator filter on that field, removing any others.
const setDateFilters = (fieldId, conditionOperator, nextState) => {
  const conditionOperators = ["Within Date Range", "After Date", "Before Date"];

  const reportFilters = [...nextState.reportFilters];

  if (!reportFilters.some((element) => element.id === fieldId)) {
    reportFilters.push({ id: fieldId, conditions: [] });
  }

  const targetFilters = reportFilters.find((element) => element.id === fieldId);
  if (!targetFilters.conditions.some((element) => element.operator === conditionOperator)) {
    targetFilters.conditions.push({ operator: conditionOperator, operand: "", id: targetFilters.conditions.length });
    targetFilters.conditions = targetFilters.conditions.filter(
      (element) => !conditionOperators.includes(element.operator) || element.operator === conditionOperator
    );
  }
  return { ...nextState, reportFilters };
};

const actions = {
  load(lastState, { template, id, profile }) {
    const loaded = loadObject(template, { profile });
    return { ...lastState, lastLoaded: loaded, current: loaded, id };
  },
  commit(lastState, { id }) {
    return { ...lastState, lastLoaded: lastState.current, id };
  },
  merge(lastState, value) {
    const newValue = { ...lastState.current, ...value };
    const error = validateState(newValue);
    const showError = lastState.showError && error.length > 0 ? lastState.showError : false;
    return { ...lastState, current: newValue, error, showError };
  },
  discard(lastState) {
    return { ...lastState, current: lastState.lastLoaded };
  },
  loadRawFields(lastState, value) {
    const objectFields = loadRawFields(value);
    return { ...lastState, objectFields };
  },
  loadRawQueries(lastState, value) {
    return { ...lastState, queryFields: loadRawFields(value) };
  },
  pushState(lastState) {
    return { ...lastState, undoStack: [lastState.current, ...lastState.undoStack] };
  },
  popState(lastState) {
    const [nextCurrent = lastState.current, ...nextUndoStack] = lastState.undoStack;
    return { ...lastState, current: nextCurrent, undoStack: nextUndoStack };
  },
  popDiscardState(lastState) {
    const [, ...nextUndoStack] = lastState.undoStack;
    return { ...lastState, undoStack: nextUndoStack };
  },
  showErrors(lastState, value) {
    const errors = validateState(lastState.current);
    return { ...lastState, error: errors, showError: value };
  },
  injectLiquidErrors(lastState, values) {
    let error = [];
    const reportFields = lastState.current.reportFields.map((field, index) => {
      if (Object.prototype.hasOwnProperty.call(values, field.id) && values[field.id].status !== "success") {
        error.push(`Error with liquid field on column ${columnIndexToExcel(index)}: ${values[field.id].error}`);
        return { ...field, error: values[field.id] };
      }
      return { ...field, error: undefined };
    });

    const newValue = { ...lastState.current, reportFields };
    error = validateState(newValue).concat(error);

    const showError = error.length > 0;
    return { ...lastState, current: newValue, error, showError };
  },
  appendErrors(lastState, values) {
    const error = [...lastState.error, ...values];
    return { ...lastState, error, showError: error.length > 0 };
  },
  updateReportFields(lastState, { id, value }) {
    let newReportFields;
    if (id && value) {
      newReportFields = lastState.current.reportFields.map((reportField) => (reportField.id === id ? value : reportField));
    } else if (id) {
      newReportFields = lastState.current.reportFields.filter(({ id: localId }) => localId !== id);
    } else if (value) {
      newReportFields = [...lastState.current.reportFields, value];
    }

    const newValue = { ...lastState.current, reportFields: newReportFields };
    const error = validateState(newValue);
    const showError = lastState.showError && error.length > 0 ? lastState.showError : false;
    const newSelectedStickyCardTab =
      lastState.current.reportFields.length !== newReportFields.length ? 0 : lastState.selectedStickyCardTab;
    return { ...lastState, current: newValue, error, showError, selectedStickyCardTab: newSelectedStickyCardTab };
  },
  setReportFieldGrouping(lastState, { fieldId, groupingType }) {
    const newGroupingType = groupingType || "anchor";
    let newReportFields = lastState.current.reportFields.map((reportField) =>
      reportField.id === fieldId ? { ...reportField, groupingType: newGroupingType } : reportField
    );

    // auto apply and remove 'anchor' grouping
    if (newReportFields.every((reportField) => reportField.groupingType == null || reportField.groupingType === "anchor")) {
      newReportFields = newReportFields.map((reportField) =>
        reportField.groupingType === "anchor" ? { ...reportField, groupingType: undefined } : reportField
      );
    } else {
      newReportFields = newReportFields.map((reportField) =>
        reportField.groupingType == null ? { ...reportField, groupingType: "anchor" } : reportField
      );
    }

    const newValue = { ...lastState.current, reportFields: newReportFields };
    const error = validateState(newValue);
    const showError = lastState.showError && error.length > 0 ? lastState.showError : false;
    return { ...lastState, current: newValue, error, showError };
  },
  updateReportFilters(lastState, { id, value }) {
    let newReportFilters;
    if (id && value) {
      newReportFilters = lastState.current.reportFilters.map((reportField) => (reportField.id === id ? value : reportField));
    } else if (id) {
      newReportFilters = lastState.current.reportFilters.filter(({ id: localId }) => localId !== id);
    } else if (value) {
      newReportFilters = [...lastState.current.reportFilters, value];
    }

    const newValue = { ...lastState.current, reportFilters: newReportFilters };
    const error = validateState(newValue);
    const showError = lastState.showError && error.length > 0 ? lastState.showError : false;
    const newSelectedStickyCardTab =
      lastState.current.reportFilters.length !== newReportFilters.length ? 1 : lastState.selectedStickyCardTab;

    return { ...lastState, current: newValue, error, showError, selectedStickyCardTab: newSelectedStickyCardTab };
  },
  addReportFilter(lastState, value) {
    return {
      ...lastState,
      current: { ...lastState.current, reportFilters: [...lastState.current.reportFilters, value] },
      selectedStickyCardTab: 1,
    };
  },
  addSmartFilters(lastState) {
    if (
      !lastState.current.reportFields.some(
        (element) =>
          element.id.includes("-transactions-") || element.id.includes("-refunds-") || element.id.includes("-fulfillments-")
      )
    ) {
      return lastState;
    }

    const specialField = lastState.current.reportFields.find(
      (element) =>
        element.id.includes("-fulfillments-") || element.id.includes("-transactions-") || element.id.includes("-refunds-")
    );

    let targetObject = "";
    if (specialField.id.includes("-transactions-")) {
      targetObject = "order-transactions-created_at";
    } else if (specialField.id.includes("-refunds-")) {
      targetObject = "order-refunds-created_at";
    } else if (specialField.id.includes("-fulfillments-")) {
      targetObject = "order-fulfillments-created_at";
    }

    const newValue = setDateFilters(
      "order-created_at",
      "Before Date",
      setDateFilters("order-updated_at", "After Date", setDateFilters(targetObject, "Within Date Range", lastState.current))
    );
    return { ...lastState, current: newValue };
  },
  addShopifyData(lastState, values) {
    return { ...lastState, shopifyData: values };
  },
  toggleModal(lastState, values) {
    const { modals } = lastState;
    return { ...lastState, modals: { ...modals, ...values } };
  },
  setSelectedStickyCardTab(lastState, value) {
    return { ...lastState, selectedStickyCardTab: value };
  },
};

export const templateReducer = (lastState, { type, value }) => {
  const action = actions[type];
  if (!action) {
    console.error(`reducer type ${type} not implemented, no-op`);
    return lastState;
  }
  const nextState = action(lastState, value);

  if (!nextState.current.reportEval) {
    // data cleanup checks

    // If we change report types we reset the fields.
    if (lastState.current.mainItem !== nextState.current.mainItem && type !== "load") {
      nextState.current.reportFormat.top_level_item = nextState.current.mainItem;
      nextState.current.reportFields = [];
    }

    // ensure that the top_level_item is valid, some old templates can have this
    if (nextState.current.reportFormat.top_level_item === "-1") {
      nextState.current.reportFormat.top_level_item = nextState.current.mainItem;
    }

    if (lastState.objectFields !== nextState.objectFields || lastState.current.mainItem !== nextState.current.mainItem) {
      nextState.allObjects = getAllObjects(nextState.objectFields, nextState.current.mainItem);
    }

    if (
      lastState.current.reportFields !== nextState.current.reportFields ||
      lastState.objectFields !== nextState.objectFields ||
      lastState.current.mainItem !== nextState.current.mainItem
    ) {
      nextState.reportFieldManyObjects = [
        ...new Set(
          nextState.current.reportFields.flatMap(({ id }) =>
            findAllManyRelations(id, nextState.objectFields, nextState.current.mainItem)
          )
        ),
        nextState.current.mainItem,
      ]
        .filter(Boolean)
        .sort();

      nextState.reportFieldAllObjects = [
        ...new Set(
          nextState.current.reportFields.flatMap(({ id }) =>
            findAllRelations(id, nextState.objectFields, nextState.current.mainItem)
          )
        ),
      ]
        .filter(Boolean)
        .sort();

      nextState.current.valueSingular = Object.fromEntries(
        nextState.reportFieldManyObjects.map((f) => [f, nextState.current.valueSingular?.[f] || idToNodeName(f, "singular")])
      );
      nextState.current.valuePlural = Object.fromEntries(
        nextState.reportFieldManyObjects.map((f) => [f, nextState.current.valuePlural?.[f] || idToNodeName(f, "plural")])
      );
    }

    // If we don't have any fields under our top_level_item we change the top_level_item
    if (!nextState.current.reportFields.some((element) => element.id.includes(nextState.current.reportFormat.top_level_item))) {
      nextState.current.reportFormat.top_level_item = nextState.current.mainItem;
    }

    // If a new template adds a line item field we set the top level to line_item.
    // eslint-disable-next-line no-restricted-globals
    if (
      nextState.topLevelItemTrigger &&
      !window.location.pathname.match(/templates\/\d+/) &&
      nextState.current.reportFields.some((element) => element.id.includes("order-line_items-"))
    ) {
      nextState.current.reportFormat.top_level_item = "order-line_items";
      nextState.topLevelItemTrigger = false;
    }

    // If someone makes a new order report we automatically add the order-created_at Within Date Range filter since most reports will want it.
    if (
      lastState.current.mainItem === "" &&
      nextState.current.mainItem === "order" &&
      nextState.current.reportFilters.length === 0
    ) {
      nextState.current.reportFilters = [
        { id: "order-created_at", conditions: [{ operand: "", operator: "Within Date Range", id: 0 }] },
      ];
    }

    if (lastState.current.reportFilters !== nextState.current.reportFilters) {
      let { reportFilters } = nextState.current;

      reportFilters = reportFilters.reduce((accumulator, { id }, index, array) => {
        if (accumulator.find((x) => x?.id === id)) {
          return accumulator;
        }
        let conditions = array.filter((f) => f.id === id).flatMap((x) => x.conditions);

        // remove operand if it's a special one
        conditions = conditions.map(({ operator, operand, ...rest }) => {
          if (["Within Date Range", "After Date", "Before Date", "is null", "is not null"].includes(operator)) {
            return { ...rest, operator, operand: "" };
          }
          return { operator, operand, ...rest };
        });

        return accumulator.concat({ id, conditions });
      }, []);

      nextState.current.reportFilters = reportFilters.filter(({ conditions }) => conditions.length !== 0);

      nextState.objectsIncluded = [
        ...new Set(
          lastState.current.reportFilters.flatMap(({ id }) =>
            findAllManyRelations(id, nextState.objectFields, nextState.current.mainItem)
          )
        ),
      ].sort();
    }

    // if we removed a field object, ensure we remove join separators associated with it.
    if (nextState.objectFields.length > 0 && !deepEqual(lastState.reportFieldManyObjects, nextState.reportFieldManyObjects)){
      const joinSeparatorEntries = Object.entries(nextState.current.reportFormat.join_separators);
      const newJoinSeparatorEntries = joinSeparatorEntries.filter(([key]) => nextState.reportFieldManyObjects.includes(key));
      nextState.current.reportFormat.join_separators = Object.fromEntries(newJoinSeparatorEntries);
    }  
  }

  nextState.unsavedChanges = !deepEqual(nextState.lastLoaded, nextState.current);
  return nextState;
};
