import React, { createContext, useContext, useEffect, useState } from "react";
import Tracker from "@openreplay/tracker";
import { useProfile } from "../utils";
import MockStore from "../MockStore";

const OpenReplayContext = createContext(null);

export const OpenReplayContextProvider = ({ children, projectKey = process.env.REACT_APP_OPEN_REPLAY_PROJECT_KEY || "sJXkciNbV0xfvUT3AbJQ", ingestPoint = process.env.REACT_APP_OPEN_REPLAY_INGEST || "https://or.moddsvc.app/ingest" }) => {
  const [profile] = useProfile();
  const [tracker, setTracker] = useState(null);
  // const [localStorage] = useState(new MockStore("localStorage"));
  // const [sessionStorage] = useState(new MockStore("sessionStorage"));

  useEffect(() => {
    if (projectKey && ingestPoint) {
      setTracker(
        new Tracker({
          projectKey,
          ingestPoint,
          localStorage: new MockStore("localStorage"),
          sessionStorage: new MockStore("sessionStorage"),
          __DISABLE_SECURE_MODE: process.env.NODE_ENV === "development",
        }),
      );
      console.log("Initalized OpenReplay");
    }
  }, [ingestPoint, projectKey]);

  useEffect(() => {
    if (profile && tracker) {
      if (!profile.user.watchtower) {
        tracker.start({
          userID: profile.user.email,
          metadata: {
            account_owner: profile.user.account_owner,
            shopify_user_id: profile.user.shopify_user_id,
            shopify_shop_id: profile.shop.shopify_shop_id,
            myshopify_domain: profile.shop.shop_origin,
            plan: profile.shop.active_subscription?.plan_name,
            days_installed: profile.shop.days_installed,
            name: profile.user.name,
          },
        });
        console.log("Started OpenReplay");
      } else {
        tracker.start({
          userID: "watchtower",
          metadata: {
            shopify_shop_id: profile.shop.shopify_shop_id,
            myshopify_domain: profile.shop.shop_origin,
            plan: profile.shop.active_subscription?.plan_name,
            days_installed: profile.shop.days_installed,
          },
        });
        console.log("Started OpenReplay");
      }
    }
  }, [profile, tracker]);

  return <OpenReplayContext.Provider value={tracker}>{children}</OpenReplayContext.Provider>;
};

export const useOpenReplay = () => useContext(OpenReplayContext);

export default useOpenReplay;
