/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useCallback, useMemo } from "react";
import { Card } from "@shopify/polaris";
import { useCreateModal } from "./utils/adminFrontend";

import { useXporterApi, useXporterQueue } from "../ApiProvider";
import FileListingTable, { normalizeQueueResponse, useCancelPendingReport } from "./FileListingTable";

function QueueTable() {
  const xporterApi = useXporterApi();
  const createModal = useCreateModal();
  const queue = useXporterQueue();
  const cancelPendingReport = useCancelPendingReport();

  const [selected, setSelected] = useState(new Set());

  const items = useMemo(
    () => normalizeQueueResponse(queue).map((queueItem) => ({ ...queueItem, delete: () => cancelPendingReport(queueItem) })),
    [cancelPendingReport, queue]
  );

  const handleSelectionChange = useCallback(
    (state, ids) => {
      let newSelected = new Set(selected);
      if (ids !== null) {
        if (state) {
          newSelected.add(ids);
        } else {
          newSelected.delete(ids);
        }
      }
      if (ids === null) {
        if (state && selected.size < items.length) {
          newSelected = new Set(items.map(({ key }) => key));
        } else {
          newSelected = new Set();
        }
      }
      setSelected(newSelected);
    },
    [items, selected]
  );

  const bulkDelete = useCallback(
    (startLoading) => {
      const selectedItems = items.filter((item) => selected.has(item.key));

      createModal({
        title: "Delete Reports",
        content: `Are you sure you want to remove ${selected.size} queued reports? This action cannot be undone.`,
        primaryAction: {
          destructive: true,
          content: "Delete Queued Reports",
          onAction: () => {
            const stopLoading = startLoading();
            Promise.allSettled(selectedItems.map(({ id }) => xporterApi.removeQueueItem(id)))
              .catch((ex) => {
                createModal({ title: "Oops..." });
                console.error(ex);
              })
              .finally(() => stopLoading());
          },
        },
        secondaryActions: [
          {
            content: "Cancel",
            onAction: () => setSelected(new Set()),
          },
        ],
      });
    },
    [createModal, items, selected, xporterApi]
  );
  if (items.length > 0) {
    return (
      <Card>
        <Card.Section flush>
          <FileListingTable
            items={items}
            selected={selected}
            handleSelectionChange={handleSelectionChange}
            bulkDelete={bulkDelete}
          />
        </Card.Section>
      </Card>
    );
  }
  return null;
}

export default QueueTable;
