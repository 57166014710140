const insertScript = (zendeskKey) => {
  if (document.querySelector("#ze-snippet")) return;

  const script = document.createElement("script");
  script.defer = true;
  script.id = "ze-snippet";
  script.src = `https://static.zdassets.com/ekr/snippet.js?key=${zendeskKey}`;
  document.body.appendChild(script);

  const zEBackupInterval = setInterval(() => {
    if (document.querySelector("iframe#launcher") && document.querySelector("iframe#launcher").contentDocument.querySelector("button[data-testid=launcher]")) {
      const el = document.querySelector("#zendesk_backup_button");
      if (el) el.remove();
      if (zEBackupInterval) clearInterval(zEBackupInterval);
    }
  }, 500);
};

const createBackupButton = () => {
  const backupButton = document.querySelector("#zendesk_backup_button");
  if (backupButton) {
    // eslint-disable-next-line no-console
    console.log("Removing backup chat button.");
    backupButton.remove();
  }

  const button = document.createElement("button");
  button.id = "zendesk_backup_button";
  button.type = "button";
  button.textContent = "Need Help?";
  button.addEventListener("click", () => {
    let b64 = btoa(
      encodeURIComponent(
        JSON.stringify({
          webWidget: {
            chat: {},
            contactForm: {},
          },
        }),
      ),
    );
    if (window.zESettings) {
      b64 = btoa(encodeURIComponent(JSON.stringify(window.zESettings)));
    }
    window.open(`https://static.zdassets.com/web_widget/latest/liveChat.html?v=10#key=moddapps.zendesk.com&settings=${b64}&locale=en-ca&title=Web%20Widget%20Live%20Chat`, "ChatWindow", "width=400,height=675");
  });
  document.querySelector("body").append(button);
  // TODO: move this to a css file
  document.head.appendChild(document.createElement("style")).textContent = `#zendesk_backup_button {
  font-weight: 700;

  letter-spacing: 0.4;
  padding: 14px 14px;
  border-radius: 999rem;
  letter-spacing: 0.6;
  font-size: 15px;

  background-color: #9f1fb7 !important;
  color: #ffffff !important;
  fill: #ffffff !important;

  margin: 10px 20px;
  position: fixed;
  bottom: 0px;
  overflow: visible;
  opacity: 1;
  border: 0px none;
  z-index: 999998;
  transition-duration: 250ms;
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  transition-property: opacity, top, bottom;
  right: 0px;
  cursor: pointer;
}`;
};

const generateZESettings = (userProfile, appNameLong, authFetch) => {
  const processedAppNameLong = appNameLong.replace("+", " Plus").replace(/\s/g, "_");

  const zendeskTags = [];

  const zendeskProduct = processedAppNameLong;
  const zendeskAppPlan = `${processedAppNameLong}_${(userProfile.shop.active_subscription ? userProfile.shop.active_subscription.plan_name : "not_subscribed").replace(/\s/g, "_")}`;
  const zendeskInstalledDays = `days_installed_${userProfile.shop.days_installed}`;
  zendeskTags.push(zendeskProduct, zendeskAppPlan, zendeskInstalledDays, userProfile.shop.shop_origin, `shopify_shop_id_${userProfile.shop.shopify_shop_id}`);
  if (typeof userProfile.user === "object") {
    zendeskTags.push(`shopify_user_id_${userProfile.user.shopify_user_id}`);
    if (userProfile.user.account_owner) {
      zendeskTags.push("account_owner");
    }
    if (userProfile.user.collaborator) {
      zendeskTags.push("collaborator_account");
    }
  }

  const zESettings = {
    webWidget: {
      chat: {
        tags: zendeskTags,
        departments: {
          enabled: [],
        },
      },
      contactForm: {
        fields: [
          { id: 360053683873, prefill: { "*": zendeskProduct.toLowerCase() } },
          { id: 360053724933, prefill: { "*": userProfile.shop.shop_origin } },
        ],
      },
    },
  };

  if (userProfile)
    zESettings.authenticate = {
      chat: {
        jwtFn: (callback) => {
          authFetch("/api/zendesk_jwt")
            .then((r) => r.json())
            .then((response) => {
              callback(response.token);
            });
        },
      },
    };

  return zESettings;
};

export default ({ appNameLong, userProfile, authFetch }) => {
  window.zESettings = generateZESettings(userProfile, appNameLong, authFetch);

  try {
    window.localStorage.setItem("testStorage", "true");
    // eslint-disable-next-line no-console
    console.log("Successfully tested Local Storage");
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log("Failed Local Storage test. Polyfilling.");
    Object.defineProperty(window, "localStorage", {
      value: new Proxy(
        {},
        {
          set(obj, k, v) {
            // eslint-disable-next-line no-param-reassign
            obj[k] = String(v);
            return obj[k];
          },
        },
      ),
    });
    createBackupButton();
  }

  insertScript("00feeab5-5427-4dc2-803e-8b6184aa27cc");
};
