import React, { useState, useContext, useEffect } from "react";

import { FeatureGate, UpgradeBanner, SubscriptionContext } from "./utils/adminFrontend";
import { useXporterApi } from "../ApiProvider";

function TemplateLimitBanner({ customBlurb = null }) {
  const subscription = useContext(SubscriptionContext);
  const [templateCount, setTemplateCount] = useState(null);
  const xporterAPI = useXporterApi();

  useEffect(() => {
    xporterAPI
      .getTemplates()
      .then((response) => {
        if (Array.isArray(response)) setTemplateCount(response.length);
      })
      .catch((response) => {
        console.error(response);
      });
  }, [xporterAPI]);

  return (
    <FeatureGate featureName="max-templates" featureValue={templateCount + 1}>
      {templateCount - subscription.activePlan?.features["max-templates"].value > 0 && (
        <UpgradeBanner status="warning">
          <p>
            {customBlurb ||
              `You are over your max templates please delete ${
                templateCount - subscription.activePlan?.features["max-templates"].value
              } templates. Or upgrade to a higher level plan.`}
          </p>
        </UpgradeBanner>
      )}
    </FeatureGate>
  );
}

export default TemplateLimitBanner;
