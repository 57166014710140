import { DateTime } from "luxon";

export const PERL_DATE_FORMAT = "yyyy-MM-dd HH:mm:ss";

export const yesterday = (profile) =>
  DateTime.now()
    .setZone(profile?.shop?.iana_timezone || "UTC")
    .minus({ day: 1 })
    .startOf("day");

export const today = (profile) =>
  DateTime.now()
    .setZone(profile?.shop?.iana_timezone || "UTC")
    .startOf("day");

export const tomorrow = (profile) =>
  DateTime.now()
    .setZone(profile?.shop?.iana_timezone || "UTC")
    .plus({ day: 1 })
    .startOf("day");

export const fromPerlDate = (perlDate, profile) =>
  perlDate
    ? DateTime.fromFormat(perlDate, PERL_DATE_FORMAT).setZone(profile?.shop?.iana_timezone || "UTC", {
        keepLocalTime: true,
      })
    : null;

export const toPerlDate = (date, profile) =>
  date ? date.setZone(profile?.shop?.iana_timezone || "UTC").toFormat(PERL_DATE_FORMAT) : null;
