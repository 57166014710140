import React from "react";
import { Page, TextContainer, Heading } from "@shopify/polaris";

export default function PrivacyPage() {
  return (
    <Page fullWidth title="Xporter Data Export Tool Privacy Policy" breadcrumbs={[{ content: "Overview", url: "/" }]}>
      <TextContainer>
        <p>
          Xporter Data Export Tool &quot;the App&quot; provides data exporting services &quot;the Service&quot; to merchants who use
          Shopify to power their stores. This Privacy Policy describes how personal information is collected, used, and shared when
          you install or use the App in connection with your Shopify-supported store.
        </p>
      </TextContainer>
      <br />
      <TextContainer>
        <Heading>Personal Information the App Collects</Heading>
        <p>
          When you install the App, we are automatically able to access certain types of information from your Shopify account:{" "}
        </p>
        <ul>
          <li>Your order information.</li>
          <li>Your product information.</li>
          <li>Your customer information.</li>
          <li>Your blogs and articles.</li>
        </ul>
        <p>
          Additionally, we collect information about you and others who may access the App on behalf of your store, such as your
          name, address, email address, phone number, and billing information, for our records, as well as to have a means of
          contacting you should any problem arise.
        </p>
        <p>
          We collect personal information directly from the relevant individual, through your Shopify account, or using the
          following technologies:
        </p>
        <ul>
          <li>
            “Cookies” are data files that are placed on your device or computer and often include an anonymous unique identifier.
            For more information about cookies, visit <a href="http://www.allaboutcookies.org">http://www.allaboutcookies.org</a>.
          </li>
          <li>
            “Log files” track actions occurring on the Site, and collect data including your IP address, browser type, Internet
            service provider, referring/exit pages, and date/time stamps.
          </li>
        </ul>
      </TextContainer>
      <br />
      <TextContainer>
        <Heading>How Do We Use Your Personal Information?</Heading>
        <p>
          We use the personal information we collect from you in order to provide the Service and to operate the App. Additionally,
          we use this personal information to: Communicate with you; Optimize or improve the App; and Provide you with information
          or advertising relating to our products or services.
        </p>
      </TextContainer>
      <br />
      <TextContainer>
        <Heading>Sharing Your Personal Information</Heading>
        <p>
          We do not share your personal information, or information about your store or clients with any third parties outside our
          company, without your explicit demand to do so, as part of fulfilling a business need you may have. (i.e. you have set up
          a file transfer of your clients’ information to your warehouse for shipping purposes, or similar arrangement)
        </p>
        <p>
          We may also share your Personal Information to comply with applicable laws and regulations, to respond to a subpoena,
          search warrant or other lawful request for information we receive, or to otherwise protect our rights.
        </p>
      </TextContainer>
      <br />
      <TextContainer>
        <Heading>Your Rights</Heading>
        <p>
          If you are a European resident, you have the right to access personal information we hold about you and to ask that your
          personal information be corrected, updated, or deleted. If you would like to exercise this right, please contact us
          through the contact information below. Additionally, if you are a European resident please note that we are processing
          your information in order to fulfill contracts we might have with you (for example if you would like an export of your
          customer listing, we will have to download your customer listing from Shopify in order to present it to you), or otherwise
          to pursue our legitimate business interests listed above. Additionally, please note that your information will be
          transferred outside of Europe, including to Canada and the United States.
        </p>
      </TextContainer>
      <br />
      <TextContainer>
        <Heading>Data Retention</Heading>
        <p>
          When you use the service, we maintain no information about the data that we export to you, other than small amounts of
          metadata related to the export (what it was called, when it was started, when it was completed, etc..). We may maintain
          the actual resulting data on our servers when you specifically ask us to do so in an automatic export, or if you generate
          your report manually. You may delete this data at any time. From time to time, older reports saved in this manner on the
          system may be deleted, with due notice served to the applicable stores that are affected by such deletions.
        </p>
        <p>
          Any such retained data is never accessed, unless it is to either resolve an issue, such as a bug, or with your explicit
          consent.
        </p>
      </TextContainer>
      <br />
      <TextContainer>
        <Heading>Changes</Heading>
        <p>
          We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for
          other operational, legal or regulatory reasons.
        </p>
      </TextContainer>
      <br />
      <TextContainer>
        <Heading>Contact Us</Heading>
        <p>
          For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please
          contact us by e-mail at privacy@moddapps.com or by mail using the details provided below:
        </p>
        <p>Data Protection Officer</p>
        <p>
          Modd Apps
          <br />
          1435 Rue Saint-Alexandre, Suite 220
          <br />
          Montreal, QC
          <br />
          Canada H3A 2G4
        </p>
      </TextContainer>
    </Page>
  );
}
