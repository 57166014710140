import React, { useCallback, useState } from "react";
import { Page, Card, TextField, Button, Stack, InlineError } from "@shopify/polaris";
import { useApiKey, useRedirect } from "../utils";

function isValueValid(content) {
  return content && content.length > 2;
}

export default () => {
  const apiKey = useApiKey();
  const [url, setUrl] = useState("");
  const [isValid, setIsValid] = useState(true);
  const redirect = useRedirect();
  const params = new URLSearchParams(window.location.search);
  const referral = params.get("referral");

  const handleSubmit = useCallback(() => {
    setIsValid(isValueValid(url));
    if (isValueValid(url)) {
      fetch(`/api/login?shop=${url}${referral ? `&referral=${encodeURIComponent(referral)}` : ""}`)
        .then((r) => r.json())
        .then(({ url: redirectUrl }) => {
          redirect(redirectUrl);
        });
    }
  }, [redirect, url, referral]);

  const handleUrlChange = useCallback((value) => setUrl(value), []);

  const errorMessage = !isValid ? "Store name is required" : "";

  return (
    <Page>
      <Card>
        <Card.Section>
          <Button url={`https://www.shopify.com/admin/apps/${apiKey}`} primary>
            Login with Shopify
          </Button>
        </Card.Section>
        <Card.Section>
          <Stack vertical>
            <Stack>
              <TextField id="shop_url" labelHidden value={url} onChange={handleUrlChange} label="Shopify URL" type="text" suffix=".myshopify.com" prefix="https://" error={!isValid} />
              <Button onClick={handleSubmit}>Install</Button>
            </Stack>
            {errorMessage && <InlineError message={errorMessage} fieldID="shop_url" />}
          </Stack>
        </Card.Section>
      </Card>
    </Page>
  );
};
