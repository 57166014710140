import React from "react";
import { Card, Page } from "@shopify/polaris";
import ReportTable from "./components/ReportTable";
import QueueTable from "./components/QueueTable";

function FileManager() {
  return (
    <Page fullWidth title="File Manager" breadcrumbs={[{ content: "Overview", url: "/" }]}>
      <QueueTable />
      <Card>
        <ReportTable />
      </Card>
    </Page>
  );
}

export default FileManager;
