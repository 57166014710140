import React, { useState, useCallback, useEffect } from "react";
import { Modal, DisplayText, Select, Stack, Banner, Link, Icon, Tooltip, Button, Label } from "@shopify/polaris";
import { InfoMinor } from "@shopify/polaris-icons";
import Editor from "@monaco-editor/react";

import { FeatureGateChoiceList } from "../utils/adminFrontend";

import { useReportTemplate } from "../../TemplateContext";
import { addLiquidLanguage, beautifyLiquid, compressLiquid } from "../LiquidEditorModal";
import { useXporterApi } from "../../ApiProvider";

const getTextOrLiquid = (reportField) => (reportField && "liquid" in reportField ? "liquid" : "text");

function ColumnModal({ reportField, ...modalProps }) {
  const [{ reportFields }, dispatch, { allObjects }] = useReportTemplate();

  const setReportFields = (value) => dispatch({ type: "merge", value: { reportFields: value } });

  const [textOrLiquid, setTextOrLiquid] = useState([getTextOrLiquid(reportField)]);
  const [fieldValue, setFieldValue] = useState(reportField?.[textOrLiquid] ?? "");

  const xporterAPI = useXporterApi();
  const [error, setError] = useState(null);
  const [timeoutId, setTimeoutId] = useState(null);
  const [savingSpinner, setSavingSpinner] = useState(false);

  const [fieldContext, setFieldContext] = useState(null);

  useEffect(() => {
    if (!fieldContext) {
      setFieldContext((allObjects.find(({ value }) => value === reportField?.id.match(/(.*)-/)[1]) ?? allObjects[0])?.value);
    }
  }, [allObjects, fieldContext, reportField?.id]);

  const handleSetContext = useCallback((data) => setFieldContext(data), []);

  const waitAndValidate = (newValue) => {
    setSavingSpinner(true);
    if (timeoutId !== null) {
      clearTimeout(timeoutId);
    }
    setTimeoutId(
      setTimeout(async () => {
        const liquidError = await xporterAPI.validateLiquid(newValue);
        setError(liquidError);
        setTimeoutId(null);
        setSavingSpinner(false);
      }, 1000)
    );
  };

  const save = async () => {
    setSavingSpinner(true);
    const rowObject = fieldContext ?? allObjects[0].value;
    const fieldType = textOrLiquid[0];

    const data = { ...reportField }; // preserve grouping

    const typeSwitched =
      (reportField.id.match("liquid_field_") && fieldType !== "liquid") ||
      (reportField.id.match("static_field_") && fieldType !== "text");
    const contextSwitched = !reportField.id.match(new RegExp(`^${fieldContext}`));

    if (typeSwitched || contextSwitched || data.id === undefined) {
      data.id = `${rowObject}-${fieldType === "text" ? "static_field" : "liquid_field"}_${+new Date()}`;
    }

    switch (fieldType) {
      case "text":
        data.name = reportField?.name ?? "Static Field";
        data.text = fieldValue;
        delete data.liquid;
        break;
      case "liquid":
        data.name = reportField?.name ?? "Liquid Field";
        data.liquid = compressLiquid(fieldValue);
        delete data.text;
        break;
      default:
        break;
    }

    // preserve placement of index in reportField
    const index = reportFields.findIndex(({ id }) => id === reportField?.id);

    if (index > -1) {
      const newReportFields = [...reportFields];
      newReportFields.splice(index, 1, data);
      setReportFields(newReportFields);
    } else {
      setReportFields([...reportFields, data]);
    }

    modalProps.onClose();
    setSavingSpinner(false);
  };

  const compressFieldValue = useCallback(() => setFieldValue(compressLiquid(fieldValue)), [fieldValue]);

  const beautifyFieldValue = useCallback(() => setFieldValue(beautifyLiquid(fieldValue)), [fieldValue]);

  const discardModal = () => {
    modalProps.onClose();
    const ogField = getTextOrLiquid(reportField);
    setTextOrLiquid([ogField]);
    setFieldValue(reportField?.[ogField] ?? "");
  };

  return (
    <Modal
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...modalProps}
      onClose={discardModal}
      large
      key="liquid-modal"
      primaryAction={
        textOrLiquid[0] === "text"
          ? { content: "Save", onAction: save, loading: savingSpinner, disabled: error }
          : {
              content: "Compress and Save",
              onAction: () => {
                save();
                modalProps.onClose();
              },
              loading: savingSpinner,
              disabled: error,
              connectedDisclosure: {
                actions: [
                  {
                    content: "Save With Whitespace",
                    onAction: save,
                  },
                ],
              },
            }
      }
      secondaryActions={[
        {
          content: "Discard",
          destructive: true,
          onAction: discardModal,
        },
      ]}
    >
      <Modal.Section>
        <Stack vertical>
          <DisplayText>Custom Field</DisplayText>
          <Stack>
            <Stack.Item>
              <Select
                label={
                  <Tooltip content="This field will appear for every item in it's context">
                    <span style={{ display: "inline-flex", alignItems: "center", justifyContent: "center" }}>
                      Context for Custom Field <Icon source={InfoMinor} />
                    </span>
                  </Tooltip>
                }
                options={allObjects}
                // options={[{ label: " ", value: null, disabled: true }, ...allObjects]}
                value={fieldContext}
                onChange={handleSetContext}
              />
            </Stack.Item>
          </Stack>
          <FeatureGateChoiceList
            selected={textOrLiquid}
            onChange={(value) => {
              setTextOrLiquid(value);
              if (value[0] === "liquid") {
                waitAndValidate(fieldValue);
              }
              setError(null);
            }}
            choices={[
              {
                label: "Static value",
                value: "text",
                helpText: "This is a text value that does not change.",
                featureName: "static-fields",
                featureValue: true,
              },
              {
                label: (
                  <Stack alignment="center">
                    <Label>Liquid expression</Label>
                    <Button outline size="slim" disabled={textOrLiquid[0] !== "liquid"} onClick={beautifyFieldValue}>
                      Beautify
                    </Button>
                    <Button outline size="slim" disabled={textOrLiquid[0] !== "liquid"} onClick={compressFieldValue}>
                      Compress
                    </Button>
                  </Stack>
                ),
                value: "liquid",
                helpText: (
                  <span>
                    This allows you to use liquid to make a formula that will be evaluated when the report is generated.{" "}
                    <Link
                      external
                      href="https://support.moddapps.com/hc/en-us/articles/360058169953-Liquid-Fields"
                      url="https://support.moddapps.com/hc/en-us/articles/360058169953-Liquid-Fields"
                    >
                      Learn more
                    </Link>
                  </span>
                ),
                featureName: "liquid-fields",
                featureValue: true,
              },
            ]}
          />
        </Stack>
      </Modal.Section>
      <Modal.Section>
        <>
          {error && error !== null && textOrLiquid[0] === "liquid" && (
            <>
              <Banner status="warning">
                <p>In order to save this field, please correct the above errors </p>
                <hr />
                <p>{`${error.error} on line ${error.line} starting at character ${error.column}`}</p>
              </Banner>
              <br />
            </>
          )}
          <Editor
            language={textOrLiquid[0]}
            value={fieldValue}
            onChange={(newValue) => {
              if (textOrLiquid[0] === "liquid") {
                waitAndValidate(newValue);
              }
              setFieldValue(newValue);
            }}
            height="35rem"
            editorWillMount={addLiquidLanguage}
          />
          <br /> {/* this removes the scrollbar in the editor modal */}
        </>
      </Modal.Section>
    </Modal>
  );
}

export default ColumnModal;
