/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext } from "react";

import { Banner, Link } from "@shopify/polaris";
import { DateTime } from "luxon";
import { useProfile, useRedirect } from "../utils";
import { SubscriptionContext } from "../contexts/SubscriptionContext";

export default () => {
  const { activeSubscriptions } = useContext(SubscriptionContext);

  const context = useProfile()[0];
  const activeSubscription = activeSubscriptions.filter((s) => s.status === "active");
  const redirect = useRedirect();

  const banner = [];

  if (DateTime.fromISO(activeSubscription.trial_end_date) > DateTime.now()) banner.push(<Banner title={`Trial Expires in ${activeSubscription[0].trial_days_left} days`} status="info" />);

  const pendingcharge = activeSubscriptions.find((s) => s.status === "pending");
  if (pendingcharge) {
    const otherSubscription = activeSubscriptions.filter((s) => s.status === "active");
    const action = context.user.account_owner
      ? {
          content: "Approve Charge",
          onAction: () => {
            redirect(pendingcharge.confirmation_url);
          },
        }
      : null;

    banner.push(
      <Banner title="Changes to your subscription" status={otherSubscription ? "warning" : "error"} action={action}>
        There is a pending charge on your account that must be accepted to activate your new subscription. Please click{" "}
        <Link external url={pendingcharge.confirmation_url}>
          here
        </Link>{" "}
        to be redirected to Shopify to accept the charge. Note, you will need appropriate permissions, if you encounter an error please ask the Shopify account owner to perform with action.
      </Banner>,
    );
  }

  const pendingUsageCharge = activeSubscriptions.find((s) => (s.update_capped_amount_url ? s.update_capped_amount_url !== "" && s.update_capped_amount_url !== null : false));
  if (pendingUsageCharge) {
    const otherSubscription = activeSubscriptions.filter((s) => s.status === "active");
    const action = context.user.account_owner
      ? {
          content: "Approve Update",
          onAction: () => {
            redirect(pendingUsageCharge.update_capped_amount_url);
          },
        }
      : null;

    banner.push(
      <Banner title="Changes to your usage charge limit" status={otherSubscription ? "warning" : "error"} action={action}>
        There is a pending usage charge cap update on your account that must be accepted. Please click{" "}
        <Link external url={pendingUsageCharge.update_capped_amount_url}>
          here
        </Link>{" "}
        to be redirected to Shopify to accept the charge. <b>You will not be charged at this time.</b> This will only update your potential usage charge ceiling. Note, you will need appropriate permissions, if you encounter an error please ask the Shopify account owner to perform with action.
      </Banner>,
    );
  }
  return <>{banner}</>;
};
