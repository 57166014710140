import React, { useState } from "react";
import { TextField } from "@shopify/polaris";

import LiquidEditorModal from "./LiquidEditorModal";

function LiquidField({ ...textFieldProps }) {
  const [editorOpen, setEditorOpen] = useState(false);

  return (
    <>
      <TextField
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...textFieldProps}
        onFocus={(...onFocusData) => {
          setEditorOpen(true);
          textFieldProps?.onFocus?.call(...onFocusData);
        }}
      />
      <LiquidEditorModal
        content={textFieldProps?.value}
        open={editorOpen}
        error={textFieldProps?.error}
        setOpen={(open) => setEditorOpen(open)}
        onSave={(data) => {
          textFieldProps?.onChange(data);
        }}
      />
    </>
  );
}

export default LiquidField;
