/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useCallback, useEffect, useMemo } from "react";
import { Button, Card, Link, Stack, Pagination, SkeletonBodyText, Select, Heading } from "@shopify/polaris";
import { RefreshMajor } from "@shopify/polaris-icons";
import { useCreateModal, useRedirect, AsyncButton } from "./utils/adminFrontend";
import {
  useXporterApi,
  useXporterQueue,
  useXporterFileListing,
  useXporterFileListingCount,
  useFileListingRefresh,
  useQueueRefresh,
} from "../ApiProvider";
import useRunTemplate from "./useRunTemplate";
import FileListingTable, {
  normalizeQueueResponse,
  normalizeFileListingResponse,
  useCancelPendingReport,
  useDeleteFile,
} from "./FileListingTable";

function TableBlank() {
  return (
    <div style={{ width: "20rem", position: "relative" }}>
      &nbsp;
      <div style={{ position: "absolute", left: 0, right: 0, bottom: "0.3rem" }}>
        <SkeletonBodyText lines={1} />
      </div>
    </div>
  );
}

function RecentFiles() {
  const xporterApi = useXporterApi();
  const createModal = useCreateModal();
  const runTemplate = useRunTemplate();
  const redirect = useRedirect();

  const fileListingRefresh = useFileListingRefresh();
  const queueRefresh = useQueueRefresh();

  const queueFromApi = useXporterQueue();
  const queue = normalizeQueueResponse(queueFromApi);

  const totalFileCount = useXporterFileListingCount();

  const itemCount = queue.length + totalFileCount;
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState("10");
  const maxPages = Math.ceil((queue.length + itemCount) / +pageSize);

  const calculatedOffset = (page - 1) * +pageSize - queue.length;
  const fileLimit = Math.min(Math.max(+pageSize + calculatedOffset, 0), +pageSize);
  const fileOffset = Math.max(calculatedOffset, 0);

  const fileListingFromApi = useXporterFileListing(fileOffset, fileLimit);

  const [selected, setSelected] = useState(new Set());

  const [templates, setTemplates] = useState(null);

  const [quickRunPopoverOpen, setQuickRunPopoverOpen] = useState(false);
  const toggleQuickRunPopover = useCallback(() => setQuickRunPopoverOpen(!quickRunPopoverOpen), [quickRunPopoverOpen]);

  const [refreshCooldownInProgress, setRefreshCooldownInProgress] = useState(false);

  const fileListing = useMemo(
    () =>
      normalizeFileListingResponse(fileListingFromApi).map(
        (entry, index) =>
          entry || {
            id: `l${index}`,
            key: `l${index}`,
            name: <TableBlank />,
            templateName: <TableBlank />,
            templateId: "",
            start: <TableBlank />,
            end: <TableBlank />,
            created: <TableBlank />,
            isBeingRun: false,
            downloadUrl: "about:blank",
            type: "dummy",
          }
      ),
    [fileListingFromApi]
  );

  const cancelPendingReport = useCancelPendingReport();
  const deleteFile = useDeleteFile();

  const items = useMemo(
    () => [
      ...queue
        .slice(+pageSize * (page - 1), +pageSize)
        .map((queueItem) => ({ ...queueItem, delete: () => cancelPendingReport(queueItem) })),
      ...fileListing.map((file) => ({ ...file, delete: () => deleteFile(file) })),
    ],
    [cancelPendingReport, deleteFile, fileListing, page, pageSize, queue]
  );

  const hasFiles = itemCount > 0 || queue.length > 0;

  const bulkDelete = useCallback(
    (startLoading) => {
      const selectedItems = items.filter((item) => selected.has(item.key));
      const selectedFiles = selectedItems.filter((item) => item.type === "file");
      const selectedQueueItem = selectedItems.filter((item) => item.type === "queue");
      const selectedFileIds = selectedFiles.map((selectedFile) => selectedFile.id);

      createModal({
        title: "Delete Reports",
        content: `Are you sure you want to delete ${selectedItems.length} reports? This action cannot be undone.`,
        primaryAction: {
          destructive: true,
          content: "Delete Reports",
          onAction: () => {
            const stopLoading = startLoading();
            Promise.allSettled([
              ...selectedQueueItem.map(({ id }) => xporterApi.removeQueueItem(id)),
              xporterApi.deleteReports(selectedFileIds),
            ])
              .catch((ex) => {
                createModal({ title: "Oops..." });
                console.error(ex);
              })
              .finally(() => stopLoading());
          },
        },
        secondaryActions: [
          {
            content: "Cancel",
            onAction: () => {
              setSelected(new Set());
            },
          },
        ],
      });
    },
    [createModal, items, selected, xporterApi]
  );

  const handleSelectionChange = useCallback(
    (state, ids) => {
      let newSelected = new Set(selected);
      if (ids !== null) {
        if (state) {
          newSelected.add(ids);
        } else {
          newSelected.delete(ids);
        }
      }
      if (ids === null) {
        if (state && selected.size < items.length) {
          newSelected = new Set(items.map(({ key }) => key));
        } else {
          newSelected = new Set();
        }
      }
      setSelected(newSelected);
    },
    [items, selected]
  );

  useEffect(() => {
    xporterApi.getTemplates().then(setTemplates);
  }, [xporterApi]);

  const noFilesNoCustomMarkup = (
    <Card sectioned>
      <div style={{ padding: "5rem 2rem", textAlign: "center" }}>
        <Stack vertical>
          <Heading>Get started by running your first report! </Heading>
          <p>
            You can run a report from our library of starters
            <br /> and then modify it to meet your needs or
            <br /> <Link url="/templates">start by building your first custom report</Link>
          </p>
          <Button primary url="/run_template">
            Run a Starter Report
          </Button>
        </Stack>
      </div>
    </Card>
  );

  const noFilesHasCustomMarkup = (
    <Card sectioned>
      <div style={{ padding: "5rem 2rem", textAlign: "center" }}>
        <Stack vertical>
          <Heading>You don&apos;t have any saved files.</Heading>
          <p>Running reports will generate files that will appear in this list.</p>
          <Button primary url="/run_template">
            Run a Report
          </Button>
        </Stack>
      </div>
    </Card>
  );

  const hasFilesMarkup = (
    <Card>
      <Card.Section>
        <Stack wrap={false}>
          <Stack.Item fill>
            <Stack vertical spacing="extraTight">
              <Link url="/files">
                <Heading>Recent Files</Heading>
              </Link>
              <div>Click below to download or delete recent report files</div>
            </Stack>
          </Stack.Item>
          <Stack.Item>
            <Stack>
              <AsyncButton
                disabled={refreshCooldownInProgress}
                icon={RefreshMajor}
                onClick={() => {
                  setRefreshCooldownInProgress(true);
                  setTimeout(() => setRefreshCooldownInProgress(false), 10000);
                  return Promise.all([fileListingRefresh(), queueRefresh()]);
                }}
              />
              <Button
                onClick={() => redirect("/run_template")}
                connectedDisclosure={
                  templates?.length
                    ? {
                        accessibilityLabel: "Quick Run",
                        actions: templates.map((template) => ({
                          content: template.NAME,
                          onAction: () => {
                            runTemplate({ template });
                            toggleQuickRunPopover();
                          },
                        })),
                      }
                    : null
                }
              >
                Run a Report
              </Button>
            </Stack>
          </Stack.Item>
        </Stack>
      </Card.Section>

      <Card.Section flush>
        <FileListingTable items={items} selected={selected} handleSelectionChange={handleSelectionChange} bulkDelete={bulkDelete} />
      </Card.Section>
      <Card.Section>
        <Stack distribution="center">
          <Select
            options={[
              { value: "5", label: "Showing 5 per page" },
              { value: "10", label: "Showing 10 per page" },
              { value: "15", label: "Showing 15 per page" },
              { value: "25", label: "Showing 25 per page" },
              { value: "50", label: "Showing 50 per page" },
              { value: "100", label: "Showing 100 per page" },
              { value: "200", label: "Showing 200 per page" },
              { value: "500", label: "Showing 500 per page" },
            ]}
            value={pageSize}
            onChange={(size) => {
              setPageSize(size);
              setPage(1);
              setSelected(new Set());
            }}
          />
          {(maxPages > 1 || page > 1) && (
            <Pagination
              hasNext={page < maxPages}
              hasPrevious={page > 1}
              onNext={() => {
                setPage(page + 1);
                setSelected(new Set());
              }}
              onPrevious={() => {
                setPage(page - 1);
                setSelected(new Set());
              }}
              label={`${page} of ${maxPages}`}
            />
          )}
        </Stack>
      </Card.Section>
    </Card>
  );

  return (
    <>
      {!hasFiles && templates?.length === 0 && noFilesNoCustomMarkup}
      {!hasFiles && templates?.length > 0 && noFilesHasCustomMarkup}
      {hasFiles && hasFilesMarkup}
    </>
  );
}

export default RecentFiles;
